.isClickable{
    cursor: pointer;
}

.header_white {
    color: #ffffff;
    background-color: #2F8641;
}

.navlogo{
/*    width: 200px; */
    padding: 4px;
}

img.navlogo{
    max-width: inherit;
}


@media (min-width: 1200px){
    .header_white .sf-menu>li>a {
        color: #ffffff;
    }
/*
    .header_white .sf-menu>li.active>a, .header_white .sf-menu>li.sfHover>a, .header_white .sf-menu .current-menu-item>a, .header_white .sf-menu .current-menu-ancestor>a {
        color: #F15931;
    }*/


    .sf-menu>li .mega-menu, .sf-menu li>ul{
        min-width: 20em;
    }
}

/* mobile */
@media (max-width: 1199px){
    .ls .toggle_menu span, .ls .toggle_menu span:before, .ls .toggle_menu span:after, .header_white .toggle_menu span, .header_white .toggle_menu span:before, .header_white .toggle_menu span:after {
        background-color: #ffffff;
    }

    .sf-menu>li .mega-menu ul,
    .sf-menu>li .mega-menu,
    .sf-menu li>ul{
        background: transparent!important;
    }


.logo img {
	max-width: 60px; 
}
.navlogo{
    width: 200px; 

}

    .playhead{
        margin: 0;
    }

    .mobileplaylogoleft{
        float: left;
    }

    .mobileplaylogoright{
        float: right;
    }

    .intro_section.page_mainslider.showShortnews{
        margin-top: 0;
    }
}

article.berichtbox{
    height: 100%;
    min-height: 550px;
}

.newportal article.berichtbox{
    min-height: auto;
}

.newportal h3.entry-title.small {
    font-size: 15px;
}

.newportal .small-text {
    font-size: 10px;
}

.newportal .vertical-item.content-padding .item-content {
    padding: 20px;
}


#services .icomoon-icon{
    font-size: 50px;
    line-height: 83px;
}

.sf-menu>li .mega-menu ul,
.sf-menu>li .mega-menu,
.sf-menu li>ul{
    background: #2F8641!important;
}

.sf-menu a{
    color: #ffffff!important;
}

.sf-menu a:hover,
.sf-menu .active a{
    color: #F15931!important;
}

.transparent-background-fussball{
    background-color: rgba(47,134,65,.80);
}

.transparent-background-fussball .icomoon-icon{
    color: #2F8641;
}

.transparent-background-ski-alpin{
    background-color: rgba(44,126,237,.80);
}

.transparent-background-ski-alpin .icomoon-icon{
    color: #2C7EED;
}

.transparent-background-langlauf{
    background-color: rgba(65,59,59,.80);
}

.transparent-background-langlauf .icomoon-icon{
    color: #413B3B;
}

.transparent-background-fitness{
    background-color: rgba(23,243,252,.80);
}

.transparent-background-fitness .icomoon-icon{
    color: #17F3FC;
}

.transparent-background-tennis{
    background-color: rgba(241,89,49,.80);
}

.transparent-background-tennis .icomoon-icon{
    color: #F15931;
}


.teaser-fussball.bottom_color_border:after {
    background-color: #2F8641!important;
}

.ls .teaser-fussball h1 a:hover,
.ls .teaser-fussball h2 a:hover,
.ls .teaser-fussball h3 a:hover,
.ls .teaser-fussball h4 a:hover,
.ls .teaser-fussball h5 a:hover,
.ls .teaser-fussball h6 a:hover {
    color: #2F8641!important;
}

.teaser-ski-alpin.bottom_color_border:after {
    background-color: #2C7EED!important;
}

.ls .teaser-ski-alpin h1 a:hover,
.ls .teaser-ski-alpin h2 a:hover,
.ls .teaser-ski-alpin h3 a:hover,
.ls .teaser-ski-alpin h4 a:hover,
.ls .teaser-ski-alpin h5 a:hover,
.ls .teaser-ski-alpin h6 a:hover {
    color: #2C7EED!important;
}

.teaser-langlauf.bottom_color_border:after {
    background-color: #413B3B!important;
}

.ls .teaser-langlauf h1 a:hover,
.ls .teaser-langlauf h2 a:hover,
.ls .teaser-langlauf h3 a:hover,
.ls .teaser-langlauf h4 a:hover,
.ls .teaser-langlauf h5 a:hover,
.ls .teaser-langlauf h6 a:hover {
    color: #413B3B!important;
}

.teaser-fitness.bottom_color_border:after {
    background-color: #17F3FC!important;
}

.ls .teaser-fitness h1 a:hover,
.ls .teaser-fitness h2 a:hover,
.ls .teaser-fitness h3 a:hover,
.ls .teaser-fitness h4 a:hover,
.ls .teaser-fitness h5 a:hover,
.ls .teaser-fitness h6 a:hover {
    color: #17F3FC!important;
}

.teaser-tennis.bottom_color_border:after {
    background-color: #F15931!important;
}

.ls .teaser-tennis h1 a:hover,
.ls .teaser-tennis h2 a:hover,
.ls .teaser-tennis h3 a:hover,
.ls .teaser-tennis h4 a:hover,
.ls .teaser-tennis h5 a:hover,
.ls .teaser-tennis h6 a:hover {
    color: #F15931!important;
}

.teaser-allgemein.bottom_color_border:after {
    background-color: #787878!important;
}

.ls .teaser-allgemein h1 a:hover,
.ls .teaser-allgemein h2 a:hover,
.ls .teaser-allgemein h3 a:hover,
.ls .teaser-allgemein h4 a:hover,
.ls .teaser-allgemein h5 a:hover,
.ls .teaser-allgemein h6 a:hover {
    color: #787878!important;
}

h2.section_header.allgemein {
    border-left: 2px solid #787878!important;
}

.pp_content_container .pp_details{
    display: none!important;
}

.page_breadcrumbs{
    margin-bottom: 110px;
}


h2.section_header.color_allgemein {
    border-left: 2px solid #787878;
}


.color-fussball{
    color: #2F8641!important;
}

.color-ski-alpin{
    color: #2C7EED!important;
}

.color-langlauf{
    color: #413B3B!important;
}

.color-fitness{
    color: #17F3FC!important;
}

.color-tennis{
    color: #F15931!important;
}

.color-allgemein{
    color: #787878!important;
}


.contact-form label.form-check-label {
    display: inline-block;
    margin: 0 0 0 5px;
}


.sf-menu .active ul li a{
    color: #ffffff!important;
}

.sf-menu .active ul li.active a{
    color: #F15931!important;
}


.sf-menu .active ul li.active ul li a{
    color: #ffffff!important;
}

.sf-menu .active ul li.active ul li.active a{
    color: #F15931!important;
}



.sf-menu ul li.sfHover>a,
.sf-menu ul li ul li:hover>a,
.sf-menu ul li.active ul li:hover>a{
    color: #F15931!important;
}



.playhead{
    margin-bottom: 30px;
    border-bottom: 1px solid #2F8641;
}

.playhead img.playlogoleft{
    position: absolute;
    top: -90px;
    left: 15px;
}

.playhead img.playlogoright{
    position: absolute;
    top: -90px;
    right: 15px;
}


.spielplan-row{
    border-bottom: 2px solid #2F8641;
    padding-bottom: 18px;
}

.leftlogo{
    float: left;
    margin-right: 10px;
}

.leftteam{
    float: left;
    padding-top: 10px;
}

.rightlogo{
    float: right;
    margin-left: 10px;
}

.rightteam{
    float: right;
    padding-top: 10px;
}

.middleerg{
    margin-top: 10px;
    display: inline-block;
}


.mb-30{
    margin-bottom: 30px;
}

.radiolist label{
    margin-right: 20px;
    color: #787878!important;
}

.short-news-container{
    z-index: 300;
}

.short-news-container article{
    background: #e4e4e4;
}

.intro_section.page_mainslider.withnews{
    margin-top: -190px;
}

.short-news-container article p{
    line-height: 26px;
}

.intro_section.page_mainslider.showShortnews{
    margin-top: -196px;
}

.portalShortnews{
    background: #e4e4e4;
}