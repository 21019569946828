/* ubuntu-300 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: url('/static/fonts/ubuntu-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/static/fonts/ubuntu-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/static/fonts/ubuntu-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/static/fonts/ubuntu-v20-latin-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-regular - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/ubuntu-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/static/fonts/ubuntu-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/static/fonts/ubuntu-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/static/fonts/ubuntu-v20-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-300italic - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 300;
    src: url('/static/fonts/ubuntu-v20-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/static/fonts/ubuntu-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/static/fonts/ubuntu-v20-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/static/fonts/ubuntu-v20-latin-300italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-italic - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 400;
    src: url('/static/fonts/ubuntu-v20-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/static/fonts/ubuntu-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/static/fonts/ubuntu-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/static/fonts/ubuntu-v20-latin-italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-500 - latin */
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: url('/static/fonts/ubuntu-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/static/fonts/ubuntu-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/static/fonts/ubuntu-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-500.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/ubuntu-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/static/fonts/ubuntu-v20-latin-500.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/static/fonts/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/static/fonts/lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/static/fonts/lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/static/fonts/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('/static/fonts/lato-v23-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/static/fonts/lato-v23-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/static/fonts/lato-v23-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/static/fonts/lato-v23-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/lato-v23-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/static/fonts/lato-v23-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/**
*** Table Of Contents
*
* Bootstrap Overrides
  - columns
  - container
  - breadcrumbs
  - pagination
  - forms
  - carousel
  - accordion
  - progressbar
  - navs and tabs
  - tables
  - etc.
* General Styles for HTML tags
* Styled Lists
* Buttons
* JQuery Plugins
  - Owl Carousel
  - FlexSlider
  - prettyPhoto
  - Easy pieChart
  - Time Table
  - toTop
  - Countdown
* Vertical Margins and Paddings
* Common Sections Styles
  - parallax
  - page preloader
  - animated elements
  - boxed layout
* Helper Utilites
  - layout
  - borders, colors and backgrouns
  - dividers, margins and paddings
  - alignment
  - media items
* Header
* Side Header
  - side header layout
  - side menu styles
  - sidebar scroll plugin
* Light Section - .ls
* Grey Section - .ls.ms
* Dark Sections - .ds
* Color Section - .cs
* Template Specific Styles
  - topline styles
  - toplogo styles
  - title section (breadcrumbs) styles
  - template parallax sections
  - footer styles
  - copyrights styles
  - google map 
  - intro slider inside content
* Intro Section Styles
* Main Menu 
  - Desktop menu
  - Mobile menu
  - Mobile menu toggler
  - Mega Menu
* Subpages
  - 404
  - about
  - blog
  - single post
  - gallery
  - post formats
* Widgets
  - common widgets styles
  - search_mailchimp
  - nav_recent_categories
  - tags
  - popular
  - recent_posts
  - calendar
  - banner
  - slider
  - flickr
  - instagram
  - twitter
  - post_tabs
  - rss
  - access_press
* Shortcodes
  - teasers
  - testimonials
  - thumbnail
  - price tables 
  - contact forms
  - partners_carousel
* Social Icons
  - layout
  - corporate colors
*/
/*
** Template colors
*

Accent Colors:
1: #F15931
2: #f3702b

Light section (.ls) colors:
Text color in light section: #787878
Background color for light section and font color in dark section: #ffffff
Color for headings in light section and for background in dark section (.ds): #3a3d44
Background color for light muted section (grey - .ls.ms): #f7f7f7
Background color for dark muted section (.ds.ms): #1f232b
Border colors for elements in light section (and .with_border elements): #e4e4e4, rgba(120, 120, 120, 0.2)
Background color for elements in light section (.with_background): #f2f2f2

Dark section (.ds) colors: 
Text color in dark section: #e0e0e1
Border colors for elements in dark section: rgba(255, 255, 255, 0.2)
Background color for elements in dark section (.with_background): rgba(255, 255, 255, 0.05)

Color section (.cs) colors: 
Border colors for elements in color section: rgba(255, 255, 255, 0.23)

*/
/*
** Bootstrap Overrides
*/
/* columns with 20 and 40 percents width */
.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-20 {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-20 {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-20 {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-20 {
		width: 20%;
		float: left;
	}
}

.col-xs-40,
.col-sm-40,
.col-md-40,
.col-lg-40 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-40 {
	width: 40%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-40 {
		width: 40%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-40 {
		width: 40%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-40 {
		width: 40%;
		float: left;
	}
}

/* half on .container in .container-fluid */
.container-left-half,
.container-right-half {
	padding-right: 15px;
	padding-left: 15px;
}

.container-left-half {
	margin-right: 0;
	margin-left: auto;
}

.container-right-half {
	margin-right: auto;
	margin-left: 0;
}

@media (min-width: 768px) {
	.container-left-half,
  .container-right-half {
		width: 375px;
	}
}

@media (min-width: 992px) {
	.container-left-half,
  .container-right-half {
		width: 485px;
	}
}

@media (min-width: 1200px) {
	.container-left-half,
  .container-right-half {
		width: 585px;
	}
}

/* bootstrap breadcrumbs*/
.breadcrumb {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border-radius: 0;
	color: inherit;
	font-weight: 500;
	font-size: 14px;
	font-family: 'Ubuntu', sans-serif;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.breadcrumb > li:last-child {
	padding-right: 0;
}

.breadcrumb > .active,
.breadcrumb > .last-item {
	color: #F15931;
}

.breadcrumb > li + li:before {
	content: "/";
	padding: 0 13px;
}

@media (min-width: 1200px) {
	.breadcrumb > li + li:before {
		padding: 0 30px;
	}
}

/* pagination */
.pagination {
	display: inline-block;
	margin-top: 0;
	margin-bottom: -10px;
	line-height: 0;
	padding: 0;
	border-radius: 0;
}

.pagination > li {
	display: inline-block;
}

.pagination > li:last-child {
	margin-right: 0;
}

.pagination > li > a,
.pagination > li > span {
	text-align: center;
	padding: 0;
	border: none;
	color: #787878;
	border: 1px solid #e4e4e4;
	margin-left: 0;
	min-width: 40px;
	height: 40px;
	font-size: 14px;
	border-radius: 10px;
	line-height: 38px;
	font-weight: 700;
	text-transform: uppercase;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius: 10px;
}

@media (min-width: 1200px) {
	.pagination > li > a,
  .pagination > li > span {
		min-width: 60px;
		height: 60px;
		line-height: 58px;
	}

	.pagination > li {
		margin: 2px;
	}
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background: #F15931;
	border-color: #F15931;
	color: #ffffff;
}

.pagination > .disabled > a,
.pagination > .disabled > span,
.pagination > .disabled > a:hover,
.pagination > .disabled > span:hover,
.pagination > .disabled > a:focus,
.pagination > .disabled > span:focus {
	opacity: 0.5;
}

/* forms */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea,
select,
.form-control {
	height: 60px;
	background-color: transparent;
	box-shadow: none;
	color: #3a3d44;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 14px;
	padding-bottom: 15px;
	font-size: 16px;
	line-height: 30px;
	font-weight: 400;
	border-radius: 10px;
	border: 1px solid rgba(120, 120, 120, 0.5);
	font-family: 'Ubuntu', sans-serif;
}

input[type="text"] + .theme_button,
input[type="email"] + .theme_button,
input[type="url"] + .theme_button,
input[type="password"] + .theme_button,
input[type="search"] + .theme_button,
input[type="tel"] + .theme_button,
input[type="number"] + .theme_button,
input[type="date"] + .theme_button,
textarea + .theme_button,
select + .theme_button,
.form-control + .theme_button {
	position: absolute;
	padding: 0;
	top: 0;
	right: 0;
	width: 60px;
	height: 60px;
	text-align: center;
	line-height: 60px;
	color: #ffffff;
	box-shadow: none;
	border-radius: 0 10px 10px 0;
	border: none;
	cursor: pointer;
}

.input-text-center input[type="text"], .input-text-center
input[type="email"], .input-text-center
input[type="url"], .input-text-center
input[type="password"], .input-text-center
input[type="search"], .input-text-center
input[type="tel"], .input-text-center
input[type="number"], .input-text-center
input[type="date"], .input-text-center
textarea, .input-text-center
select, .input-text-center
.form-control {
	text-align: center;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea,
.select-group,
.form-control {
	border: 1px solid rgba(120, 120, 120, 0.3);
}

.form-group {
	position: relative;
	margin-bottom: 20px;
}

.form-group-wrap {
	position: relative;
}

.inline-form .form-group-wrap {
	display: inline-block;
}

/* select styling */
.form-control option {
	color: #3a3d44;
	background-color: #ffffff;
}

.form-control-unstyled {
	border: none;
	background-color: transparent;
}

.form-control.invalid {
	box-shadow: 0 0 0 2px #F15931;
}

.form-control:focus {
	border-color: #787878;
	box-shadow: none;
}

.form-control:-moz-placeholder {
	color: rgba(120, 120, 120, 0.7);
	font-style: italic;
	text-transform: uppercase;
	font-size: 14px;
}

.form-control::-moz-placeholder {
	color: rgba(120, 120, 120, 0.7);
	font-style: italic;
	text-transform: uppercase;
	font-size: 14px;
}

.form-control:-ms-input-placeholder {
	color: rgba(120, 120, 120, 0.7);
	font-style: italic;
	text-transform: uppercase;
	font-size: 14px;
}

.form-control::-webkit-input-placeholder {
	color: rgba(120, 120, 120, 0.7);
	font-style: italic;
	text-transform: uppercase;
	font-size: 14px;
}

.form-control:focus:-moz-placeholder {
	color: transparent;
}

.form-control:focus::-moz-placeholder {
	color: transparent;
}

.form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

/*icons for forms */
form label + i,
form label + i {
	position: absolute;
	font-size: 14px !important;
	left: 20px;
	top: 23px;
	width: 1em;
	line-height: 1;
	text-align: center;
}

form label + i + .form-control,
form label + i + .form-control {
	padding-left: 45px;
}

form .with_icon {
	padding-left: 25px;
}

form .with_icon + [class*="fa-"],
form .with_icon + [class*="icon2-"] {
	position: absolute;
	top: 17px;
	left: 2px;
	font-size: 12px;
}

form .with_icon:focus + [class*="fa-"],
form .with_icon:focus + [class*="icon2-"] {
	color: #F15931;
}

label {
	font-weight: inherit;
}

.checkbox label,
.radio label {
	font-weight: inherit;
}

.checkbox label[for],
.radio label[for] {
	font-weight: inherit;
}

.form-control + [class*="icon2-"] {
	position: absolute;
	font-size: 24px;
	left: 20px;
	top: 50%;
	color: #F15931;
}

.checkbox label[for] + input[type=checkbox],
.checkbox label[for] + input[type=radio],
.radio label[for] + input[type=checkbox],
.radio label[for] + input[type=radio] {
	left: 20px;
}

input[type=checkbox],
input[type=radio] {
	margin-top: 9px;
}

.form-wrapper.with_background, .form-wrapper.muted_background, .form-wrapper.cs, .form-wrapper.ds, .form-wrapper.ls {
	padding: 35px;
}

.has-error .form-control {
	border-color: #F15931;
}

.has-error label,
.has-error .help-block {
	color: #F15931;
}

/* carousel */
.carousel {
	overflow: hidden;
}

.carousel-indicators li {
	width: 10px;
	height: 10px;
	border-width: 0;
	background-color: #3a3d44;
	margin: 0 3px;
	transition: all 0.2s ease-in-out 0s;
}

.carousel-indicators li:hover {
	opacity: 0.8;
}

.carousel-indicators .active {
	width: 10px;
	height: 10px;
	margin: 0 3px;
	background-color: #F15931;
}

.carousel-control.left,
.carousel-control.right {
	border: 1px solid #ffffff;
	color: #ffffff;
	border-radius: 0px;
	width: 50px;
	height: 50px;
	left: -50px;
	top: 50%;
	margin-top: -25px;
	text-shadow: none;
	opacity: 1;
	background-image: none;
	filter: alpha(opacity=100);
	text-align: center;
}

.carousel-control.right {
	right: -50px;
}

.carousel:hover .carousel-control {
	left: 50px;
}

.carousel:hover .carousel-control.right {
	left: auto;
	right: 50px;
}

.carousel-control.left span,
.carousel-control.right span {
	font-family: 'rt-icons-2';
	width: 50px;
	height: 50px;
	margin: 0;
	font-size: 16px;
	left: 0;
	top: 0;
	line-height: 50px;
}

.carousel-control .icon-prev:before {
	content: "\e7c4";
}

.carousel-control .icon-next:before {
	content: "\e7c5";
}

/* collapse accordion */
.panel-heading .panel-title {
	font-size: inherit;
	letter-spacing: 0;
	padding: 0;
	position: relative;
	font-family: 'Ubuntu', sans-serif;
}

.panel-heading .panel-title > a {
	font-size: 18px;
	line-height: 1.3;
	display: block;
	padding: 18px 60px 19px 20px;
	background: #F15931;
	color: #ffffff;
	border-radius: 10px 10px 0 0;
	word-wrap: break-word;
}

@media (min-width: 1200px) {
	.panel-heading .panel-title > a {
		padding-left: 35px;
	}
}

.panel-heading .panel-title > a.collapsed:hover,
.panel-heading .panel-title > a:hover {
	color: #ffffff;
	background: #F15931;
}

.panel-heading .panel-title > a.collapsed:hover i, .panel-heading .panel-title > a.collapsed:hover:after,
.panel-heading .panel-title > a:hover i,
.panel-heading .panel-title > a:hover:after {
	color: #ffffff;
}

.panel-heading .panel-title > a.collapsed {
	color: #3a3d44;
	background: #f2f2f2;
	border-radius: 10px;
}

.panel-heading .panel-title > a.collapsed i {
	color: #F15931;
}

.panel-heading .panel-title > a:after {
	content: "-";
	position: absolute;
	text-align: center;
	right: 0;
	top: 0;
	width: 60px;
	height: 60px;
	line-height: 60px;
	font-size: 20px;
	font-weight: 100;
	transition: all 0.2s ease-in-out 0s;
	color: #fff;
}

.panel-heading .panel-title > a.collapsed:after {
	content: "+";
	color: #3a3d44;
}

.panel-heading .panel-title > a > i {
	position: relative;
	font-size: 16px;
	padding-right: 10px;
	text-align: center;
	display: inline-block;
	line-height: 0;
}

.color2 .panel-heading .panel-title > a {
	background: #F15931;
}

.color2 .panel-title > a.collapsed {
	background: #f2f2f2;
}

.color2 .panel-title > a.collapsed i {
	color: #F15931;
}

.color2 .panel-title > a.collapsed:after {
	color: #F15931;
}

.color2 .panel-title > a.collapsed:hover,
.color2 .panel-title > a:hover {
	background: #F15931;
}

.color2 .panel-title > a.collapsed:hover i, .color2 .panel-title > a.collapsed:hover:after,
.color2 .panel-title > a:hover i,
.color2 .panel-title > a:hover:after {
	color: #fff;
}

.color3 .panel-heading .panel-title > a {
	background: #fcb315;
}

.color3 .panel-title > a.collapsed {
	background: #f2f2f2;
}

.color3 .panel-title > a.collapsed i {
	color: #fcb315;
}

.color3 .panel-title > a.collapsed:after {
	color: #fcb315;
}

.color3 .panel-title > a.collapsed:hover,
.color3 .panel-title > a:hover {
	background: #fcb315;
}

.color3 .panel-title > a.collapsed:hover i, .color3 .panel-title > a.collapsed:hover:after,
.color3 .panel-title > a:hover i,
.color3 .panel-title > a:hover:after {
	color: #fff;
}

.color4 .panel-heading .panel-title > a {
	background: #03a8cb;
}

.color4 .panel-title > a.collapsed {
	background: #f2f2f2;
}

.color4 .panel-title > a.collapsed i {
	color: #03a8cb;
}

.color4 .panel-title > a.collapsed:after {
	color: #03a8cb;
}

.color4 .panel-title > a.collapsed:hover,
.color4 .panel-title > a:hover {
	background: #03a8cb;
}

.color4 .panel-title > a.collapsed:hover i, .color4 .panel-title > a.collapsed:hover:after,
.color4 .panel-title > a:hover i,
.color4 .panel-title > a:hover:after {
	color: #fff;
}

.panel {
	box-shadow: none;
}

.panel-group .panel {
	border-radius: 0;
	background-color: transparent;
}

.panel-default > .panel-heading {
	background-color: transparent;
	color: inherit;
	position: relative;
	border: none;
	border-radius: 0;
	padding: 0;
}

.panel-default {
	border: none;
}

.panel-group .panel + .panel {
	margin-top: 10px;
}

.panel-body {
	border: 1px solid #e4e4e4;
	border-top: none !important;
	background-color: transparent;
	border-radius: 0 0 5px 5px;
}

.panel-body .media-left {
	padding-right: 15px;
}

.panel-body .media-left .img-circle {
	max-width: 50px;
}

.panel-body .media-left img {
	max-width: 80px;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
	padding: 20px;
}

@media (min-width: 400px) {
	.panel-group .panel-heading + .panel-collapse .panel-body {
		padding: 30px 40px;
	}
}

.collapse-unstyled .panel {
	background-color: transparent;
	border: 1px solid #e4e4e4;
	border-width: 1px 0;
}

.collapse-unstyled .panel + .panel {
	margin-top: 0;
	border-top: none;
}

.collapse-unstyled .panel h4 {
	font-size: 18px;
	margin: 0;
}

.collapse-unstyled .panel h4 a {
	position: relative;
	display: block;
	padding: 18px 20px 10px 0;
}

.collapse-unstyled .panel h4 a:after {
	content: "-";
	position: absolute;
	top: 0;
	right: 0;
	font-weight: 100;
	line-height: 56px;
}

.collapse-unstyled .panel h4 a.collapsed {
	padding-bottom: 18px;
}

.collapse-unstyled .panel h4 a.collapsed:after {
	content: "+";
}

@media (min-width: 992px) {
	.collapse-unstyled .panel h4 {
		font-size: 20px;
	}
}

.collapse-unstyled .panel-content {
	padding: 0 0 20px;
}

/* progress bars */
.progress-bar-title {
	font-weight: 500;
	margin-bottom: 5px;
	font-size: 14px;
	font-family: 'Ubuntu', sans-serif;
	text-transform: uppercase;
	margin-right: 50px;
}

.progress {
	overflow: visible;
	height: 5px;
	margin-bottom: 25px;
	position: relative;
	border-radius: 0;
	box-shadow: none;
	background-color: #e4e4e4;
	border-radius: 20px;
}

.ls .with_background .progress {
	background-color: #ffffff;
}

.ls.ms .with_background .progress {
	background-color: #e4e4e4;
}

.progress-bar {
	background-color: #F15931;
	color: inherit;
	box-shadow: none;
	height: 5px;
	border-radius: 2px;
}

.progress-bar span {
	position: absolute;
	right: 0;
	top: -29px;
	font-size: 14px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
}

.progress-bar-success {
	background-color: #72b946;
}

.progress-bar-info {
	background-color: #03a8cb;
}

.progress-bar-warning {
	background-color: #F15931;
}

.progress-bar-danger {
	background-color: #F15931;
}

.progress-bar-color2 {
	background-color: #F15931;
}

.progress-bar-color3 {
	background-color: #fcb315;
}

.progress-bar-color4 {
	background-color: #03a8cb;
}

/* nav and tabs */
.nav > li > a:hover,
.nav > li > a:focus {
	background-color: transparent;
}

.nav-unstyled {
	list-style: none;
	padding: 0;
	margin: 0 -12px;
}

.nav-unstyled li {
	display: inline-block;
	margin-bottom: -1px;
}

.nav-unstyled li a {
	display: inline-block;
	padding: 0 12px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Ubuntu', sans-serif;
}

.nav-unstyled .active a {
	color: #F15931;
}

.nav-unstyled.color2 .active a {
	color: #F15931;
}

.nav-unstyled.color3 .active a {
	color: #fcb315;
}

.nav-unstyled.color4 .active a {
	color: #03a8cb;
}

/*tabs*/
.tab-content {
	border-radius: 0;
	padding: 30px 20px;
	border: none;
	border: 1px solid #e4e4e4;
	border-top: none;
}

.tab-content.no-border {
	padding: 30px 0 0;
	border: none;
	box-shadow: none;
	background-color: transparent;
}

.tab-content.no-border.top-color-border:before {
	left: 0;
	right: 0;
}

.tab-content.top-color-border {
	position: relative;
}

.tab-content.top-color-border:before {
	content: '';
	position: absolute;
	left: -1px;
	right: -1px;
	top: 0;
	height: 4px;
	background-color: #F15931;
}

.tab-content.top-color-border.color2:before {
	background-color: #F15931;
}

.tab-content.top-color-border.color3:before {
	background-color: #fcb315;
}

.tab-content.top-color-border.color4:before {
	background-color: #03a8cb;
}

.tab-content.tab-unstyled {
	padding: 10px 0 0;
	border: none;
	background-color: transparent !important;
	box-shadow: none;
}

.ls .tab-content.tab-unstyled,
.ds .tab-content.tab-unstyled,
.cs .tab-content.tab-unstyled {
	background-color: transparent;
}

.tab-content .featured-tab-image {
	margin: -30px -20px 30px;
}

@media (min-width: 400px) {
	.tab-content .featured-tab-image {
		margin: -40px -36px 30px;
	}
}

@media (min-width: 400px) {
	.tab-content {
		padding: 40px 35px;
	}
}

@media (min-width: 1200px) {
	.tab-content.big-padding {
		padding: 55px;
	}
}

@media (min-width: 1200px) {
	.tab-content.big-padding.no-border {
		padding: 55px 0 0;
	}
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	border-bottom: none;
}

.nav-tabs {
	position: relative;
	border-bottom: none;
	margin-top: 0px;
	z-index: 1;
}

.nav-tabs.half-width-tabs {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.nav-tabs.half-width-tabs > li {
	width: 50%;
	display: -ms-flexbox;
	display: flex;
}

.nav-tabs.half-width-tabs > li a {
	width: 100%;
}

.nav-tabs > li {
	margin-bottom: 0;
	margin-right: 2px;
}

.nav-tabs > li > a {
	position: relative;
	z-index: 1;
	font-size: 12px;
	font-family: 'Ubuntu', sans-serif;
	text-transform: uppercase;
	font-weight: 500;
	padding: 10px 20px;
	margin-right: 0;
	margin-top: 0px;
	border: 1px solid rgba(120, 120, 120, 0.3);
	border-bottom: 0;
	background-color: #ffffff;
	color: #787878;
	border-radius: 10px 10px 0 0;
}

.small-tabs .nav-tabs > li > a {
	padding: 10px;
}

.nav-tabs > li > a i {
	position: relative;
	top: 2px;
	padding-right: 10px;
	font-size: 1.6em;
	display: inline-block;
	line-height: 0;
}

@media (min-width: 992px) {
	.nav-tabs > li > a {
		padding: 12px 28px;
		font-size: 14px;
	}
}

@media (min-width: 1200px) {
	.nav-tabs > li > a {
		padding: 19px 45px;
	}
}

@media (max-width: 400px) {
	.small-tabs .nav-tabs > li > a {
		font-size: 10px;
		padding: 8px;
	}
}

.one-third-tabs.nav-tabs {
	display: -ms-flexbox;
	display: flex;
}

.one-third-tabs.nav-tabs:before {
	display: none;
}

.one-third-tabs.nav-tabs > * {
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
}

.one-third-tabs.nav-tabs > *:last-child {
	margin-right: 0;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	border-color: #F15931;
	background-color: #F15931;
	color: #ffffff;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs.nav-justified > .active > a {
	border-color: #F15931;
	background-color: #F15931;
	color: #ffffff;
}

.color2.nav-tabs > li.active > a,
.color2.nav-tabs > li.active > a:hover,
.color2.nav-tabs > li.active > a:focus {
	border-color: #F15931;
	background-color: #F15931;
	color: #ffffff;
}

.color2.nav-tabs > li > a:hover,
.color2.nav-tabs > li > a:focus,
.color2.nav-tabs.nav-justified > .active > a {
	border-color: #F15931;
	background-color: #F15931;
	color: #ffffff;
}

.color3.nav-tabs > li.active > a,
.color3.nav-tabs > li.active > a:hover,
.color3.nav-tabs > li.active > a:focus {
	border-color: #fcb315;
	background-color: #fcb315;
	color: #ffffff;
}

.color3.nav-tabs > li > a:hover,
.color3.nav-tabs > li > a:focus,
.color3.nav-tabs.nav-justified > .active > a {
	border-color: #fcb315;
	background-color: #fcb315;
	color: #ffffff;
}

.color4.nav-tabs > li.active > a,
.color4.nav-tabs > li.active > a:hover,
.color4.nav-tabs > li.active > a:focus {
	border-color: #03a8cb;
	background-color: #03a8cb;
	color: #ffffff;
}

.color4.nav-tabs > li > a:hover,
.color4.nav-tabs > li > a:focus,
.color4.nav-tabs.nav-justified > .active > a {
	border-color: #03a8cb;
	background-color: #03a8cb;
	color: #ffffff;
}

/*vertical tabs*/
.vertical-tabs .tab-content {
	border-top: 1px solid #e4e4e4;
}

.vertical-tabs .tab-content.no-border {
	padding-top: 0;
	border-top: none;
}

.vertical-tabs .nav > li > a {
	font-size: 18px;
	background-color: #f2f2f2;
	padding: 15px 40px 15px 30px;
	margin-bottom: 10px;
	border-radius: 10px;
	position: relative;
	color: #3a3d44;
}

.vertical-tabs .nav > li > a i {
	position: relative;
	top: -2px;
	padding-right: 8px;
	width: 2em;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	line-height: 0;
}

.vertical-tabs .nav > li > a:after {
	font-family: 'rt-icons-2';
	content: "\e7c5";
	position: absolute;
	font-size: 12px;
	line-height: 60px;
	text-align: center;
	right: 0;
	top: 0;
	bottom: 0;
	width: 60px;
	letter-spacing: 0;
}

.vertical-tabs.no-arrows .nav > li > a:after {
	display: none;
}

.vertical-tabs.no-arrows .nav > li > a {
	padding: 24px 0;
}

.vertical-tabs .nav > li.active a,
.vertical-tabs .nav > li > a:hover {
	background: #F15931;
	color: #ffffff;
}

.vertical-tabs.color2 .nav > li.active a,
.vertical-tabs.color2 .nav > li > a:hover {
	background: #F15931;
}

.vertical-tabs.color3 .nav > li.active a,
.vertical-tabs.color3 .nav > li > a:hover {
	background: #fcb315;
}

.vertical-tabs.color4 .nav > li.active a,
.vertical-tabs.color4 .nav > li > a:hover {
	background: #03a8cb;
}

@media (min-width: 768px) {
	.vertical-tabs .nav > li.active {
		position: relative;
		z-index: 2;
	}
}

/* modal */
.modal-backdrop.in {
	filter: alpha(opacity=80);
	opacity: .8;
}

.modal-content {
	border-radius: 1px;
}

.modal-content [class*="col-"]:last-child {
	margin-bottom: 0;
}

/* bootstrap tables */
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	padding: 15px 15px;
	border-color: #e4e4e4;
}

.table {
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
}

.table td + th,
.table th + td,
.table th + th,
.table td + td {
	border-left: 1px solid #e4e4e4;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
	border-bottom-width: 1px;
}

.table > tbody > tr > td.media-middle {
	vertical-align: middle;
}

.table-responsive {
	border: none;
}

/* bootstrap buttons */
.btn {
	padding: 22px 30px 22px;
	margin: 0 0 4px 0;
	font-weight: 700;
	border: none;
	border-radius: 0;
	outline: none;
	transition: all 0.3s ease-in-out 0s;
}

.btn:hover, .btn:focus {
	outline: none;
}

/* media elements */
.media {
	margin-top: 17px;
}

.media .media-object {
	max-width: 100px;
}

li.media:before,
li.media:after {
	display: block;
	content: '';
	clear: both;
}

.media-right,
.media > .pull-right {
	padding-left: 20px;
}

.media-left,
.media > .pull-left {
	padding-right: 20px;
}

.media-left img,
.media-right img {
	max-width: 85px;
}

@media (min-width: 768px) {
	.big-left-media .media-left img, .big-left-media
  .media-right img {
		max-width: 100px;
	}
}

.small-media {
	margin-top: 10px;
}

.small-media .media-left {
	padding-right: 7px;
}

.small-media .media-right {
	padding-left: 7px;
}

.small-media .media-left img,
.small-media .media-right img {
	max-width: 40px;
}

@media (max-width: 499px) {
	.xxs-media-left {
		text-align: center;
	}

	.xxs-media-left .media-left {
		display: block;
		margin: 0 auto 20px;
		padding-right: 0;
	}
}

td .media-body {
	width: auto;
}

/* bootstrap dropdown menu */
.dropdown-menu {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 0;
	border: none;
	z-index: 1001;
	font-size: 14px;
	margin-top: 0;
	padding-left: 0;
	padding-right: 0;
}

[class*="right"] .dropdown-menu {
	right: 0;
	left: auto;
}

.dropdown-menu > li > a {
	font-weight: 700;
	padding: 7px 20px;
}

.nav .open > .header-button,
.nav .open > .header-button:hover,
.nav .open > .header-button:focus {
	background-color: transparent;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
	color: #3a3d44;
	background-color: transparent;
}

.dropdwon-menu-title {
	padding: 10px 30px;
}

.well {
	box-shadow: none;
	border: none;
	border-radius: 0;
}

.embed-responsive-3by2 {
	padding-bottom: 66.666%;
}

.embed-responsive-1by1 {
	padding-bottom: 100%;
}

.embed-responsive iframe {
	background-color: #000;
}

.embed-placeholder:after {
	content: "\f04b";
	font-family: 'FontAwesome';
	position: absolute;
	color: #ffffff;
	font-size: 24px;
	line-height: 70px;
	width: 70px;
	height: 70px;
	text-align: center;
	padding-left: 5px;
	background-color: rgba(232, 82, 66, 0.9);
	border-radius: 50%;
	left: 50%;
	top: 50%;
	margin-left: -35px;
	margin-top: -35px;
	transition: all 0.2s ease 0s;
}

.embed-placeholder:hover:after {
	color: #3a3d44;
}

.alert {
	border-radius: 10px;
	border-width: 0;
	padding: 15px 30px;
	margin-bottom: 10px;
	font-size: 18px;
	font-family: 'Ubuntu', sans-serif;
}

.alert strong {
	font-weight: 500;
}

.alert i {
	position: relative;
	padding-right: 10px;
	font-size: 14px;
}

.alert:before {
	font-family: FontAwesome;
	font-size: 14px;
	margin-right: 10px;
}

.alert-success:before {
	content: "\f058";
}

.alert-info:before {
	content: "\f06a";
}

.alert-warning:before {
	content: "\f071";
}

.alert-danger:before {
	content: "\f057";
}

.alert-success,
.alert-info,
.alert-warning,
.alert-danger {
	color: #ffffff;
}

.alert-success {
	background-color: #72b946;
}

.alert-info {
	background-color: #03a8cb;
}

.alert-warning {
	background-color: #F15931;
}

.alert-danger {
	background-color: #F15931;
}

button.close {
	margin-top: 3px;
}

/* small in headings */
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
	color: inherit;
	font-size: 80%;
	font-weight: inherit;
}

.media h1 small, .media h2 small, .media h3 small, .media h4 small, .media h5 small, .media h6 small {
	display: block;
}

/* labels */
.label-success {
	color: #ffffff;
	background-color: #72b946;
}

.label-info {
	color: #ffffff;
	background-color: #03a8cb;
}

.label-warning {
	color: #ffffff;
	background-color: #F15931;
}

.label-danger {
	color: #ffffff;
	background-color: #F15931;
}

/*caret*/
.caret {
	border: none;
	line-height: 20px;
	width: 20px;
	height: 20px;
	border: 1px solid #e4e4e4;
	text-align: center;
	margin-left: 5px;
	vertical-align: baseline;
}

.caret:before {
	content: "\f107";
	font-family: FontAwesome;
}

/* helpers */
@media (max-width: 499px) {
	.hidden-xxs {
		display: none !important;
	}
}

/*
** General Styles for HTML tags
*/
html {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: visible;
	font-size: 16px;
}

::-moz-selection {
	color: #ffffff;
	background-color: #F15931;
}

::selection {
	color: #ffffff;
	background-color: #F15931;
}

::-moz-selection {
	color: #ffffff;
	background-color: #F15931;
}

body {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	line-height: 1.875rem;
	font-size: 16px;
	overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
	line-height: 1.1;
	margin-top: 1.1em;
	margin-bottom: 0.8em;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

h1 {
	font-size: 52px;
}

h2 {
	font-size: 40px;
}

h3 {
	font-size: 32px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 16px;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

p {
	margin-bottom: 15px;
}

p:last-child {
	margin-bottom: 0;
}

blockquote {
	position: relative;
	margin: 40px 0;
	padding: 0;
	border: none;
	font-size: 20px;
	font-weight: 300;
	font-style: italic;
}

blockquote .item-meta {
	font-style: normal;
	margin-top: 20px;
}

blockquote h4 + .small-text,
blockquote h5 + .small-text {
	display: block;
	margin-top: -5px;
}

blockquote h5 {
	font-size: 24px;
	margin-bottom: 0;
}

.owl-carousel blockquote {
	margin-top: 0;
	margin-bottom: 0;
}

img, figure {
	max-width: 100%;
	height: auto;
}

figcaption, .entry-caption {
	padding: 5px 0 0;
}

figcaption p, .entry-caption p {
	margin-bottom: 0;
}

a {
	text-decoration: none;
	transition: all 0.2s ease-in-out 0s;
}

a:hover {
	color: #F15931;
	text-decoration: none;
	transition: all 0.2s linear 0s;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus {
	outline: medium none;
	text-decoration: none;
}

a > img {
	transition: all 0.2s ease-in-out 0s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

a:hover > img {
	opacity: 0.8;
}

hr {
	border: none;
	height: 1px;
	margin-left: 0;
	margin-right: 0;
	background-color: #e4e4e4;
}

iframe {
	border: none;
	max-width: 100%;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

table {
	width: 100%;
	max-width: 100%;
	margin: 10px 0;
}

table td, table th {
	padding: 8px 10px;
	line-height: 1.42857143;
	vertical-align: top;
	border: 1px solid #e4e4e4;
}

table th {
	color: #3a3d44;
	font-weight: normal;
	vertical-align: middle;
	line-height: 1;
}

.table_template thead {
	background-color: #F15931;
	color: #3a3d44;
}

.table_template thead.light {
	background-color: #ffffff;
}

.table_template th {
	font-weight: 700;
}

.table_template td, .table_template th {
	padding: 18px 30px;
}

.table_template tbody tr:nth-of-type(odd) {
	background-color: rgba(238, 238, 238, 0.1);
}

.table_template tbody tr:nth-of-type(even) {
	background-color: rgba(17, 17, 17, 0.05);
}

canvas {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

p .fa {
	vertical-align: middle;
}

/*
** Styled Lists
*/
ul, ol {
	padding-left: 30px;
	margin-bottom: 20px;
}

.list1 ul,
ul.list1 {
	list-style: none;
	padding: 0;
}

.padding_30 .list1 ul, .padding_30
ul.list1 {
	margin: 0;
}

.list1 li {
	position: relative;
	padding: 9px 0 10px 20px;
	border-bottom: 1px solid rgba(120, 120, 120, 0.2);
}

.muted_background .list1 li {
	border-color: rgba(255, 255, 255, 0.4);
}

.list1 li > .media {
	margin: 3px 0;
}

@media (min-width: 992px) {
	.list1 li {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.list1.big-padding li {
	padding-top: 22px;
	padding-bottom: 21px;
}

.list1.huge-padding li {
	padding-top: 37px;
	padding-bottom: 36px;
}

.list1.no-bullets li {
	padding-left: 0;
}

.list1.no-bullets li:before {
	display: none;
}

.list1 li:first-child {
	border-top: 1px solid rgba(120, 120, 120, 0.2);
}

.list1.no-top-border li:first-child {
	border-top: none;
	margin-top: 0;
	padding-top: 0;
}

.list1.no-bottom-border li:last-child {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.list1 li:before {
	content: "\f0da";
	font-family: FontAwesome;
	font-size: 12px;
	color: #F15931;
	position: absolute;
	left: 0;
	top: 10px;
	width: 7px;
	height: 7px;
}

.list2 ul,
ul.list2 {
	list-style: none;
	padding-left: 20px;
}

.list2 li {
	position: relative;
	padding: 5px 0 5px 0;
}

.big-v-spacing.list2 li {
	padding: 7px 0;
}

.list2 li:before {
	content: "\f0da";
	font-family: FontAwesome;
	position: absolute;
	font-size: 14px;
	line-height: 1em;
	left: -20px;
	top: 14px;
	color: #F15931;
}

.list2.color2 li:before {
	color: #F15931;
}

.list2.no-bullets {
	padding-left: 0 !important;
}

.list2.no-bullets li:before {
	display: none;
}

.list2.checklist {
	padding-left: 25px;
}

.list2.checklist li:before {
	content: "\f00c";
	font-family: FontAwesome;
	font-size: 14px;
	line-height: 1em;
	left: -25px;
	color: #F15931;
	width: auto;
	height: auto;
	background-color: transparent;
}

.list2.checklist.color2 li:before {
	color: #F15931;
}

.list3 ol,
ol.list3 {
	counter-reset: li;
	list-style: none outside none;
	padding: 0;
}

.list3 li {
	position: relative;
	padding: 6px 0 6px 25px;
}

.list3 li.active:after {
	color: #F15931;
}

.list3 li:after {
	content: counter(li) ".";
	counter-increment: li;
	position: absolute;
	left: 2px;
	top: 50%;
	transform: translateY(-50%);
	font-weight: 600;
	color: #F15931;
}

.list3.color li:after {
	color: #F15931;
}

.list3.color2 li:after {
	color: #F15931;
}

.list-unstyled .media {
	margin-top: 0;
	margin-bottom: 10px;
}

.list-unstyled .media:last-child {
	margin-bottom: 0;
}

.list-unstyled li:last-child > .media {
	margin-bottom: 0;
}

.list-unstyled .media-left {
	padding-right: 10px;
}

.list-unstyled .media-left img {
	border-radius: 50%;
}

.list-unstyled .media-right {
	padding-left: 10px;
}

.list-unstyled .media-right img {
	border-radius: 50%;
}

.list-unstyled .media-body .pull-right {
	margin: 0 20px;
}

.inline-list {
	list-style: none;
	margin: 0 -13px;
	padding: 0;
}

.inline-list > li {
	display: inline-block;
	padding: 0 13px;
}

.inline-list.small-padding {
	margin: 0 -8px;
}

.inline-list.small-padding > li {
	padding: 8px;
}

.four-cols-text ul {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.four-cols-text {
		column-count: 2;
	}
}

@media (min-width: 992px) {
	.four-cols-text {
		column-count: 3;
	}
}

@media (min-width: 1200px) {
	.four-cols-text {
		column-count: 4;
	}
}

.three-cols-text ul {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.three-cols-text {
		column-count: 2;
	}
}

@media (min-width: 992px) {
	.three-cols-text {
		column-count: 3;
	}
}

@media (min-width: 1200px) {
	.three-cols-text {
		column-count: 3;
	}
}

/* 
** Buttons 
*/
.theme_buttons a,
.theme_button,
.wc-proceed-to-checkout .checkout-button,
.buttons a,
button,
input[type="submit"] {
	position: relative;
	font-family: 'Ubuntu', sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	padding: 18px 30px;
	line-height: 1;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	color: #ffffff;
	background-color: #3a3d44;
	border-radius: 10px;
	border: none;
	transition: all 0.4s linear 0s;
	z-index: 1;
}

@media (min-width: 1600px) {
	.theme_buttons a,
  .theme_button,
  .wc-proceed-to-checkout .checkout-button,
  .buttons a,
  button,
  input[type="submit"] {
		padding: 23px 40px;
	}
}

.theme_button,
.theme_buttons a {
	margin-bottom: 10px;
	margin-right: 6px;
}

.theme_button:last-child,
.theme_buttons a:last-child {
	margin-right: 0;
}

.theme_buttons a:active,
.theme_buttons a:hover,
.theme_buttons a:focus,
.theme_button:active,
.theme_button:hover,
.theme_button:focus,
input[type="submit"]:hover,
input[type="submit"]:active,
input[type="submit"]:focus,
.wc-proceed-to-checkout .checkout-button:hover {
	color: #F15931;
	opacity: 1;
	text-decoration: none;
	outline: none;
}

button:hover, button:active, button:focus,
input[type="submit"]:hover,
input[type="submit"]:active,
input[type="submit"]:focus,
.theme_buttons a:hover,
.theme_buttons a:active,
.theme_buttons a:focus,
.theme_button:hover,
.theme_button:active,
.theme_button:focus,
a.button:hover,
a.button:active,
a.button:focus {
	transition: all 0.05s linear 0s;
}

.theme_button[type="reset"] {
	display: none;
}

.theme_buttons.inverse a,
.theme_button.inverse {
	color: #3a3d44;
	background-color: transparent !important;
	border: 2px solid #3a3d44;
	padding: 16px 28px;
}

.theme_buttons.inverse a:hover, .theme_buttons.inverse a:focus, .theme_buttons.inverse a:active,
.theme_button.inverse:hover,
.theme_button.inverse:focus,
.theme_button.inverse:active {
	color: #F15931;
}

@media (min-width: 1600px) {
	.theme_buttons.inverse a,
  .theme_button.inverse {
		padding: 21px 38px;
	}
}

.theme_buttons.color1 a,
.theme_button.color1,
.button.alt,
.btn-accent,
.wc-proceed-to-checkout .checkout-button,
.form-submit [type="submit"] {
	color: #ffffff;
	background-color: #F15931;
}

.theme_buttons.color1 a:hover, .theme_buttons.color1 a:focus, .theme_buttons.color1 a:active,
.theme_button.color1:hover,
.theme_button.color1:focus,
.theme_button.color1:active,
.button.alt:hover,
.button.alt:focus,
.button.alt:active,
.btn-accent:hover,
.btn-accent:focus,
.btn-accent:active,
.wc-proceed-to-checkout .checkout-button:hover,
.wc-proceed-to-checkout .checkout-button:focus,
.wc-proceed-to-checkout .checkout-button:active,
.form-submit [type="submit"]:hover,
.form-submit [type="submit"]:focus,
.form-submit [type="submit"]:active {
	color: #3a3d44;
}

.theme_buttons[class*="color"],
.theme_button[class*="color"] {
	color: #ffffff;
}

.theme_buttons[class*="color"]:hover, .theme_buttons[class*="color"]:focus, .theme_buttons[class*="color"]:active,
.theme_button[class*="color"]:hover,
.theme_button[class*="color"]:focus,
.theme_button[class*="color"]:active {
	color: #3a3d44;
}

.theme_buttons.color2 a,
.theme_button.color2 {
	background-color: #F15931;
	border-color: #F15931;
}

.theme_buttons.color3 a,
.theme_button.color3 {
	background-color: #fcb315;
}

.theme_buttons.color4 a,
.theme_button.color4 {
	background-color: #03a8cb;
}

.theme_buttons.inverse[class*="color"]:hover, .theme_buttons.inverse[class*="color"]:active, .theme_buttons.inverse[class*="color"]:focus,
.theme_button.inverse[class*="color"]:hover,
.theme_button.inverse[class*="color"]:active,
.theme_button.inverse[class*="color"]:focus {
	color: #3a3d44;
}

.theme_buttons.color1.inverse a,
.theme_button.color1.inverse {
	color: #F15931;
	border-color: #F15931;
}

.theme_buttons.color2.inverse a,
.theme_button.color2.inverse {
	color: #F15931;
	border-color: #F15931;
}

.theme_buttons.color3.inverse a,
.theme_button.color3.inverse {
	color: #fcb315;
	border-color: #fcb315;
}

.theme_buttons.color4.inverse a,
.theme_button.color4.inverse {
	color: #03a8cb;
	border-color: #03a8cb;
}

.theme_buttons a:active,
.theme_button:active {
	top: 1px;
}

.muted_buttons a,
.muted_button {
	opacity: 0.2;
}

.small_buttons a,
.small_button {
	padding: 7px 10px;
	text-transform: uppercase;
	letter-spacing: 0;
}

.square_buttons a,
.square_button {
	padding: 0;
	width: 50px;
	line-height: 50px;
	margin: 0;
}

.round_buttons a,
.round_button {
	padding: 0;
	width: 60px;
	line-height: 60px;
	border-radius: 30px;
}

a.inactive {
	pointer-events: none;
}

.block_buttons a,
.block_button {
	display: block;
	width: 100%;
	text-align: center;
}

.min_width_button {
	min-width: 170px;
}

@media (min-width: 1600px) {
	.min_width_button {
		min-width: 200px;
	}
}

.no_bg_button {
	background-color: transparent !important;
	border-color: transparent;
}

.no_bg_button.color1 {
	color: #F15931;
}

.no_bg_button.color2 {
	color: #F15931;
}

.no_bg_button[class*="color"]:hover, .no_bg_button[class*="color"]:focus, .no_bg_button[class*="color"]:active {
	color: #3a3d44;
}

.two_lines_button {
	padding: 11px 35px;
}

/*icons in headings, paragraphs and buttons*/
h1 > [class*="rt-icon"],
h2 > [class*="rt-icon"],
h3 > [class*="rt-icon"],
h4 > [class*="rt-icon"],
h5 > [class*="rt-icon"],
h6 > [class*="rt-icon"] {
	position: relative;
	top: .08em;
	padding-right: 0.1em;
}

p > i {
	font-size: 1.1em;
	line-height: 0;
}

p > i + [class*="rt-icon"] {
	margin-left: -0.75em;
}

.theme_buttons a > [class*="rt-icon"],
.theme_buttons a > [class*="fa-"],
.theme_button > [class*="rt-icon"],
.theme_button > [class*="fa-"] {
	line-height: 0;
	font-size: 1.5em;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: -0.05em;
}

.form-inline [type="submit"],
.form-inline .btn,
.form-inline .theme_button {
	margin-bottom: 0;
}

.btn-group-lg > .btn, .btn-lg {
	padding: 16px 16px;
}

.btn-group-sm > .btn, .btn-sm {
	padding: 5px 10px;
}

.btn-group-xs > .btn, .btn-xs {
	padding: 1px 5px;
}

.more-link,
.view-more {
	font-weight: 500;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'Ubuntu', sans-serif;
}

.more-link:after,
.view-more:after {
	content: "\f149";
	font-family: FontAwesome;
	padding-left: 4px;
}

.view-more:after {
	content: "\f178";
	position: relative;
	left: 0;
	transition: left 0.2s ease 0s;
	margin-left: 5px;
}

.view-more:hover:after {
	left: 5px;
}

:target:before {
	content: "";
	display: block;
	height: 150px;
  /* fixed header height*/
	margin: -150px 0 0;
  /* negative fixed header height */
}

/*
**plugins
*/
/* Owl carousel v 2.1.6 styles */
/* Owl Carousel - Core */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	width: calc(100% + 1px);
	padding-bottom: 1px;
  /* fix for flashing background */
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
	-webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: all 0.2s ease-in-out 0s;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
	display: none;
}

.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

.owl-carousel.dots-center .owl-dots {
	text-align: center;
}

.owl-carousel .owl-dots {
	text-align: center;
}

.owl-dots {
	-webkit-tap-highlight-color: transparent;
	counter-reset: owl-dots;
}

.owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
	*display: inline;
	padding: 0 10px;
}

.owl-dots .owl-dot:first-child {
	padding-left: 0;
}

.owl-dots .owl-dot:last-child {
	padding-right: 0;
}

.owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
	color: transparent;
	border: 2px solid #3a3d44;
	cursor: pointer;
	text-indent: -9999px;
	transition: all 0.2s ease-in-out 0s;
}

.ds .owl-dots .owl-dot span,
.cs .owl-dots .owl-dot span {
	border-color: #ffffff;
}

.owl-dots .owl-dot.active span, .owl-dots .owl-dot:hover span {
	border-color: #F15931;
}

.ds .owl-dots .owl-dot.active span, .ds .owl-dots .owl-dot:hover span {
	background-color: #ffffff;
}

.owl-nav {
	transition: all 0.3s ease 0s;
}

.owl-nav > div {
	color: transparent;
	font-size: 0;
	display: inline-block;
	right: 0;
	text-align: center;
	width: 40px;
	height: 40px;
	border: 1px solid #e4e4e4;
	border-radius: 50%;
}

.owl-nav > div.disabled {
	opacity: 0.5;
	cursor: default;
}

.owl-nav > div:first-child {
	left: 0;
	right: auto;
}

.owl-nav > div:after {
	transition: all 0.3s ease 0s;
	font-family: FontAwesome;
	font-size: 16px;
	line-height: 38px;
	content: "\f105";
	color: #3a3d44;
}

.cs .owl-nav > div:after {
	color: #ffffff;
}

.owl-nav > div:first-child:after {
	content: "\f104";
}

.owl-nav > div:hover:after {
	color: #F15931;
}

@media (min-width: 768px) {
	.owl-nav > div {
		width: 60px;
		height: 60px;
	}

	.owl-nav > div:after {
		line-height: 58px;
	}
}

.top-right-nav .owl-nav {
	position: absolute;
	top: -70px;
	right: 0;
}

.top-right-nav .owl-nav > div {
	background: transparent;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* Owl Carousel - Animate Plugin */
.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* Owl Carousel - Auto Height Plugin */
.owl-height {
	transition: height 500ms ease-in-out;
}

/* Owl Carousel - Lazy Load Plugin */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
	transform-style: preserve-3d;
}

/* Owl Carousel - Video Plugin */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url("/static/img/owl.video.play.png") no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}

/* Owl Default Theme */
.owl-theme:hover .owl-nav {
	top: 50%;
	opacity: 1;
}

.owl-theme .owl-nav {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	text-align: center;
	-webkit-tap-highlight-color: transparent;
}

@media (min-width: 1024px) {
	.owl-theme .owl-nav {
		top: 0;
		opacity: 0;
	}
}

.owl-theme .owl-nav > div {
	font-size: 0;
	position: absolute;
	right: 0;
	text-align: center;
	margin-top: -20px;
}

@media (min-width: 768px) {
	.owl-theme .owl-nav > div {
		margin-top: -30px;
	}
}

.owl-theme .owl-nav .disabled {
	opacity: 0.5;
	cursor: default;
}

.container .col-sm-12 .owl-theme .owl-nav {
	top: 50%;
	opacity: 1;
}

@media (min-width: 1300px) {
	.container .col-sm-12 .owl-theme .owl-nav {
		left: -60px;
		right: -60px;
	}
}

@media (min-width: 1600px) {
	.container .col-sm-12 .owl-theme .owl-nav {
		left: -150px;
		right: -150px;
	}
}

.owl-theme.right-top-nav .owl-nav {
	opacity: 1;
	top: -50px;
	right: 0 !important;
	left: auto !important;
}

.owl-theme.right-top-nav .owl-nav > div {
	position: relative;
	margin-top: 0;
}

.owl-theme.right-top-nav .owl-nav > div.owl-prev {
	margin-right: 10px;
}

@media (min-width: 768px) {
	.owl-theme.right-top-nav .owl-nav {
		top: -70px;
	}
}

.owl-theme .owl-dots {
	margin-top: 25px;
}

/* .owl-center theme */
.owl-center .owl-nav {
	top: 0;
	bottom: 0;
	position: static;
	margin-top: 0;
	opacity: 1;
}

.owl-center .owl-nav > div {
	right: 0;
	width: 30px;
	top: 50%;
	bottom: 0;
	margin: 0;
	height: 80px;
	text-align: center;
	opacity: 1;
	background-color: rgba(58, 61, 68, 0.6);
	transform: translateY(-50%);
}

.owl-center .owl-nav > div:after {
	position: absolute;
	top: 50%;
	color: #ffffff;
	font-size: 20px;
	left: 50%;
	transform: translate(-50%, -50%);
}

.owl-center-scale.owl-carousel {
	margin-bottom: -30px;
}

.owl-center-scale.owl-carousel .owl-stage-outer {
	padding-top: 30px;
	padding-bottom: 30px;
}

.owl-center-scale.owl-carousel .owl-item > * > * {
	transition: all 0.2s ease 0s;
}

.owl-center-scale.owl-carousel .center {
	z-index: 2;
}

.owl-center-scale.owl-carousel .center > * > * {
	transform: scale(1.15);
	position: relative;
}

/* owl images fix */
.owl-carousel .owl-item {
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

.owl-carousel .owl-item.active {
	opacity: 1;
}

.owl-center .owl-item {
	opacity: 1;
}

.with_shadow_items {
	padding: 20px;
	margin: -20px;
	width: calc(100% + 40px);
	overflow: hidden;
}

.with_shadow_items .owl-stage-outer {
	overflow: visible;
}

/* Text nav */
.owl-carousel.text-nav {
	padding-top: 60px;
}

.owl-carousel.text-nav .owl-nav {
	top: 0;
}

.owl-carousel.text-nav .owl-nav:before {
	content: "";
	width: 2px;
	height: 11px;
	position: absolute;
	background-color: #787878;
	top: 9px;
	left: calc(50% - 2px);
}

.owl-carousel.text-nav .owl-nav > div {
	position: static;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	color: #787878;
	width: auto;
	padding: 5px 30px;
}

.owl-carousel.text-nav .owl-nav > div:hover {
	color: #F15931;
}

.owl-carousel.text-nav .owl-nav > div:after {
	display: none;
}

.owl-carousel.text-nav .owl-nav > div.disabled {
	pointer-events: none;
}

/*
** Flexslider
* http://www.woothemes.com/flexslider/
*
* Copyright 2012 WooThemes
* Free to use under the GPLv2 license.
* http://www.gnu.org/licenses/gpl-2.0.html
*
* Contributing author: Tyler Smith (@mbmufffin)
*/
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
	outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* FlexSlider Necessary Styles */
.flexslider {
	margin: 0;
	padding: 0;
}

.flexslider .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
	width: 100%;
	display: block;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slides {
	display: block;
}

* html .slides {
	height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
* include js that eliminates this class on page load */
.no-js .slides > li:first-child {
	display: block;
}

.flexslider .slides > li {
	position: relative;
}

/* FlexSlider Default Theme */
.flexslider {
	margin: 0 0;
	position: relative;
	zoom: 1;
}

.flex-viewport {
	max-height: 2000px;
	transition: all 1s ease;
}

.loading .flex-viewport {
	max-height: 300px;
}

.flexslider .slides {
	zoom: 1;
	z-index: 1;
}

/* Direction Nav */
.flex-direction-nav {
	*height: 0;
}

.flex-direction-nav a {
	text-decoration: none;
	display: block;
	width: 60px;
	height: 60px;
	margin: -30px 0 0;
	position: absolute;
	top: 50%;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	font-size: 0;
	color: #3a3d44;
	background-color: transparent;
	text-align: center;
}

.flex-direction-nav a:hover, .flex-direction-nav a:active, .flex-direction-nav a:focus {
	color: #F15931;
}

.ds .flex-direction-nav a:not(.flex-disabled) {
	color: #ffffff;
	opacity: 0.6;
}

.ds .flex-direction-nav a:not(.flex-disabled):hover {
	color: #ffffff;
	opacity: 1;
}

.flex-direction-nav .flex-prev {
	left: 0px;
}

.flex-direction-nav .flex-next {
	right: 0px;
}

@media (min-width: 1450px) {
	.flex-direction-nav .flex-prev {
		left: 60px;
	}

	.flex-direction-nav .flex-next {
		right: 60px;
	}
}

@media (min-width: 1800px) {
	.flex-direction-nav .flex-prev {
		left: 240px;
	}

	.flex-direction-nav .flex-next {
		right: 240px;
	}
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
	opacity: 1;
}

.flex-direction-nav .flex-disabled {
	opacity: 0;
	filter: alpha(opacity=0);
	cursor: default;
}

.flex-direction-nav a:before {
	font-family: "FontAwesome";
	font-size: 36px;
	line-height: 56px;
	content: '\f104';
}

.flex-direction-nav a.flex-next:before {
	content: '\f105';
}

/* Pause/Play */
.flex-pauseplay a {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	left: 10px;
	opacity: 0.8;
	z-index: 10;
	overflow: hidden;
	cursor: pointer;
	color: #000;
}

.flex-pauseplay a:before {
	font-family: 'rt-icons-2';
	font-size: 20px;
	display: inline-block;
	content: "\e6a2";
}

.flex-pauseplay a:hover {
	opacity: 1;
}

.flex-pauseplay a.flex-play:before {
	content: "\e711";
}

/* Control Nav */
.flex-control-nav {
	left: 0;
	right: 0;
	bottom: 25px;
	text-align: center;
	z-index: 10;
}

@media (min-width: 992px) {
	.flex-control-nav {
		bottom: 35px;
	}
}

.overlapping-nav .flex-control-nav {
	position: absolute;
}

.flex-control-nav li {
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	padding: 0 10px;
	zoom: 1;
	*display: inline;
}

.flex-control-nav li:first-child {
	padding-left: 0;
}

.flex-control-nav li:last-child {
	padding-right: 0;
}

.flex-control-paging li a {
	position: relative;
	width: 10px;
	height: 10px;
	display: block;
	border: 2px solid #787878;
	background-color: transparent;
	color: transparent;
	cursor: pointer;
	text-indent: -9999px;
	border-radius: 50%;
}

.ds .flex-control-paging li a,
.cs .flex-control-paging li a {
	border-color: #ffffff;
}

.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active {
	border-color: #F15931;
}

.flex-control-thumbs {
	margin: 5px 0 0;
	position: static;
	overflow: hidden;
}

.flex-control-thumbs li {
	width: 25%;
	float: left;
	margin: 0;
}

.flex-control-thumbs img {
	width: 100%;
	display: block;
	opacity: .7;
	cursor: pointer;
}

.flex-control-thumbs img:hover {
	opacity: 1;
}

.flex-control-thumbs .flex-active {
	opacity: 1;
	cursor: default;
}

/*vertical navigation*/
@media (min-width: 1300px) {
	.vertical-nav .flex-control-nav {
		position: absolute;
		margin: 0;
		bottom: auto;
		top: 50%;
		right: auto;
		width: auto;
		height: 10px;
		line-height: 10px;
		left: 0;
		transform: rotate(90deg) translateY(50%);
	}

	.vertical-nav .flex-control-nav:after {
		bottom: -70px;
		height: 50px;
	}
}

@media (min-width: 1600px) {
	.vertical-nav .flex-control-nav {
		left: 4.5%;
	}
}

/*
** prettyPhoto
*/
div.facebook .pp_description {
	margin: 0 37px 0 0;
}

div.pp_pic_holder a:focus {
	outline: none;
}

div.pp_overlay {
	background: #000;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9500;
}

div.pp_pic_holder {
	display: none;
	position: absolute;
	width: 100px;
	z-index: 10000;
}

.pp_content {
	height: 40px;
	min-width: 40px;
	overflow: hidden;
}

* html .pp_content {
	width: 40px;
}

.pp_content_container {
	position: relative;
	text-align: left;
	width: 100%;
}

.pp_content_container .pp_left {
	padding-left: 20px;
}

.pp_content_container .pp_right {
	padding-right: 20px;
}

.pp_content_container .pp_details {
	float: left;
	line-height: 1;
	background-color: #ffffff;
}

.pp_description {
	display: none;
	margin: 0;
}

.pp_social {
	float: left;
	margin: 0 0 0 30px;
}

.pp_social .facebook {
	float: left;
	margin-left: 5px;
	width: auto;
	overflow: hidden;
}

.pp_social .facebook iframe {
	max-width: 100px;
}

.pp_social .twitter {
	float: left;
}

.pp_nav p {
	float: left;
	white-space: nowrap;
	margin: 4px 6px;
	font-weight: bold;
	opacity: 0.7;
}

.pp_nav .pp_play,
.pp_nav .pp_pause,
a.pp_arrow_previous,
a.pp_arrow_next {
	float: left;
	text-align: center;
	font-size: 0;
	color: transparent;
}

.pp_nav .pp_play:before,
.pp_nav .pp_pause:before,
a.pp_arrow_previous:before,
a.pp_arrow_next:before {
	line-height: 20px;
	display: inline-block;
	width: 20px;
	font-family: 'FontAwesome';
	font-size: 12px;
	color: #3a3d44;
	opacity: 0.7;
	content: "\f04b";
}

.pp_nav .pp_play:hover:before,
.pp_nav .pp_pause:hover:before,
a.pp_arrow_previous:hover:before,
a.pp_arrow_next:hover:before {
	opacity: 1;
}

.pp_nav .pp_pause:before {
	content: "\f04c";
}

a.pp_arrow_previous:before {
	content: "\f04a";
}

a.pp_arrow_next:before {
	content: "\f04e";
}

a.pp_arrow_previous.disabled,
a.pp_arrow_next.disabled {
	visibility: hidden;
	cursor: default;
}

.pp_hoverContainer {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2000;
}

.pp_gallery {
	display: none;
	left: 50%;
	margin-top: -50px;
	position: absolute;
	z-index: 10000;
}

.pp_gallery div {
	float: left;
	overflow: hidden;
	position: relative;
}

.pp_gallery ul {
	float: left;
	height: 35px;
	position: relative;
	white-space: nowrap;
	margin: 0 0 0 5px;
	padding: 0;
}

.pp_gallery ul a {
	border: 1px rgba(0, 0, 0, 0.1) solid;
	display: block;
	float: left;
	height: 33px;
	overflow: hidden;
}

.pp_gallery ul a:hover,
.pp_gallery ul .selected a {
	border-color: #F15931;
}

.pp_gallery ul a img {
	border: 0;
}

.pp_gallery li {
	display: block;
	float: left;
	margin: 0 5px 0 0;
	padding: 0;
}

.pp_gallery li.default a {
	background: url(/static/img/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
	display: block;
	height: 33px;
	width: 50px;
}

div.facebook .pp_gallery .pp_arrow_previous, div.facebook .pp_gallery .pp_arrow_next {
	margin-top: 7px;
}

a.pp_next, a.pp_previous {
	position: relative;
	display: block;
	float: right;
	height: 100%;
	width: 49%;
	font-size: 0;
	color: transparent;
	opacity: 0;
}

a.pp_next:before, a.pp_previous:before {
	line-height: 1;
	background-color: rgba(58, 61, 68, 0.3);
	font-size: 18px;
	padding: 10px 15px 10px 18px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f105";
	position: absolute;
	top: 50%;
	margin-top: -3px;
	right: 30px;
}

a.pp_next:hover, a.pp_previous:hover {
	opacity: 1;
}

a.pp_previous {
	float: left;
}

a.pp_previous:before {
	content: "\f104";
	right: auto;
	padding: 10px 18px 10px 15px;
	left: 30px;
}

a.pp_expand, a.pp_contract {
	cursor: pointer;
	display: none;
	position: absolute;
	right: 30px;
	top: 19px;
	z-index: 20000;
	font-size: 0;
	color: transparent;
	text-align: center;
}

a.pp_expand:before, a.pp_contract:before {
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f0b2";
	background-color: rgba(58, 61, 68, 0.3);
	padding: 12px 13px;
}

a.pp_contract:before {
	content: "\f066";
}

a.pp_close {
	position: absolute;
	right: -7px;
	top: -25px;
	font-size: 0;
	color: transparent;
}

a.pp_close:before {
	background-color: #1f232b;
	padding: 6px 8px;
	font-size: 14px;
	color: #ffffff;
	font-family: 'FontAwesome';
	content: "\f00d";
}

.pp_loaderIcon {
	height: 50px;
	width: 50px;
	left: 50%;
	top: 50%;
	position: absolute;
	margin: -25px 0 0 -25px;
	text-align: center;
	animation: spin 2s infinite linear;
}

.pp_loaderIcon:before {
	display: block;
	font-family: 'FontAwesome';
	content: "\f1ce";
	color: #ffffff;
	font-size: 50px;
	line-height: 50px;
	opacity: 0.6;
}

#pp_full_res {
	line-height: 1;
}

#pp_full_res .pp_inline {
	text-align: left;
}

#pp_full_res .pp_inline p {
	margin: 0 0 15px;
}

div.ppt {
	color: #ffffff;
	display: none;
	font-size: 18px;
	z-index: 9999;
	margin: 0 0 5px 20px;
}

.pp_top, .pp_bottom {
	position: relative;
}

* html .pp_top, * html .pp_bottom {
	padding: 0 20px;
}

.pp_top .pp_left, .pp_bottom .pp_left {
	height: 20px;
	left: 0;
	position: absolute;
	width: 20px;
}

.pp_top .pp_middle, .pp_bottom .pp_middle {
	height: 20px;
	left: 20px;
	position: absolute;
	right: 20px;
}

* html .pp_top .pp_middle, * html .pp_bottom .pp_middle {
	left: 0;
	position: static;
}

.pp_top .pp_right, .pp_bottom .pp_right {
	height: 20px;
	left: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
}

.pp_fade, .pp_gallery li.default a img {
	display: none;
}

/*
** Easy pieChart
*/
.chart {
	position: relative;
	display: inline-block;
	margin-bottom: 10px;
	text-align: center;
	line-height: 0;
}

.chart .chart-meta {
	position: absolute;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
}

.chart .chart-meta h5 {
	font-size: 20px;
	margin: 0;
	font-weight: 400;
	color: #787878;
}

.percent {
	display: inline-block;
	font-size: 60px;
	font-weight: 500;
	line-height: 1;
	z-index: 2;
}

.percent:after {
	content: '%';
}

.angular {
	margin-top: 100px;
}

.angular .chart {
	margin-top: 0;
}

/* 
** Time Table 
*/
#timetable span {
	display: block;
}

#timetable th, #timetable td {
	vertical-align: middle;
	font-weight: normal;
	text-align: center;
	padding: 25px 5px;
}

#timetable thead {
	color: #ffffff;
}

#timetable thead th {
	border: 1px solid rgba(255, 255, 255, 0.23);
	border-width: 0 1px;
}

#timetable tbody th, #timetable tbody td {
	border: 1px solid #e4e4e4;
	transition: all 0.2s ease-in-out 0s;
}

#timetable tbody .current {
	background-color: #F15931;
	border-color: #F15931;
}

#timetable tbody .current a {
	color: #ffffff;
}

/* 
** toTop
*/
#toTop {
	bottom: 10px;
	right: 10px;
	display: none;
	width: 50px;
	height: 50px;
	overflow: hidden;
	position: fixed;
	text-decoration: none;
	z-index: 9999;
	font-size: 0;
	color: transparent;
	border-radius: 10px;
	color: #ffffff;
	background: #F15931;
}

@media (min-width: 768px) {
	#toTop {
		bottom: 30px;
		right: 30px;
	}
}

@media (min-width: 992px) {
	#toTop {
		width: 60px;
		height: 60px;
		bottom: 60px;
		right: 60px;
	}
}

#toTop:after {
	color: #ffffff;
	content: "\f106";
	font-size: 16px;
	line-height: 50px;
	font-family: FontAwesome;
	text-align: center;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: all 0.2s linear;
}

@media (min-width: 992px) {
	#toTop:after {
		line-height: 60px;
	}
}

#toTopHover, #toTop:hover {
	bottom: 15px;
	opacity: 1;
}

@media (min-width: 768px) {
	#toTopHover, #toTop:hover {
		bottom: 35px;
	}
}

@media (min-width: 992px) {
	#toTopHover, #toTop:hover {
		bottom: 65px;
	}
}

#toTop:hover:after {
	color: #ffffff;
}

#toTop:active, #toTop:focus {
	outline: medium none;
}

.modal-open #toTop {
	visibility: hidden;
}

/* Countdown */
#comingsoon-countdown {
	text-align: center;
}

#comingsoon-countdown:before,
#comingsoon-countdown:after {
	clear: both;
	display: table;
	content: ' ';
}

.countdown-rtl {
	direction: rtl;
}

.countdown-row {
	clear: both;
	display: table;
	margin: auto;
	text-align: center;
}

.countdown-section {
	display: table-cell;
	padding: 0 10px;
	text-align: center;
}

.countdown-section:first-child {
	border-left-width: 0;
}

.countdown-amount {
	display: block;
	font-family: 'Ubuntu', sans-serif;
	font-size: 28px;
	line-height: 1;
	font-weight: 400;
	color: #F15931;
	width: 2.142em;
	height: 2.142em;
	line-height: 2.142em;
	text-align: center;
	background-color: #f2f2f2;
	border-radius: 50%;
}

.countdown-section:nth-child(4n + 2) .countdown-amount {
	color: #F15931;
}

.countdown-section:nth-child(4n + 3) .countdown-amount {
	color: #fcb315;
}

.countdown-section:nth-child(4n + 4) .countdown-amount {
	color: #03a8cb;
}

.countdown-period {
	display: block;
	color: #3a3d44;
	font-size: 12px;
	text-transform: uppercase;
	font-family: 'Ubuntu', sans-serif;
}

.countdown-descr {
	display: block;
}

@media (min-width: 768px) {
	.countdown-amount {
		font-size: 60px;
	}

	.countdown-section {
		padding: 0 20px;
	}

	.countdown-period {
		font-size: 14px;
		font-weight: 500;
	}
}

.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
	visibility: visible !important;
	background: #f2f2f2 !important;
	background: rgba(0, 0, 0, 0.06) !important;
	border: 0 none !important;
	box-shadow: inset 0 0 12px 4px #ffffff;
}

.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
	content: '!';
	visibility: hidden;
}

.selectize-control.plugin-drag_drop .ui-sortable-helper {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.selectize-dropdown-header {
	position: relative;
	padding: 5px 8px;
	border-bottom: 1px solid #d0d0d0;
	background: #f8f8f8;
	border-radius: 3px 3px 0 0;
}

.selectize-dropdown-header-close {
	position: absolute;
	right: 8px;
	top: 50%;
	color: #303030;
	opacity: 0.4;
	margin-top: -12px;
	line-height: 20px;
	font-size: 20px !important;
}

.selectize-dropdown-header-close:hover {
	color: #000000;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup {
	border-right: 1px solid #f2f2f2;
	border-top: 0 none;
	float: left;
	box-sizing: border-box;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
	border-right: 0 none;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
	display: none;
}

.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
	border-top: 0 none;
}

.selectize-control {
	height: 60px;
	position: relative;
}

.selectize-control.plugin-remove_button [data-value] {
	position: relative;
	padding-right: 24px !important;
}

.selectize-control.plugin-remove_button [data-value] .remove {
	z-index: 1;
  /* fixes ie bug (see #392) */
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 17px;
	text-align: center;
	font-weight: bold;
	font-size: 12px;
	color: inherit;
	text-decoration: none;
	vertical-align: middle;
	display: inline-block;
	padding: 2px 0 0 0;
	border-left: 1px solid #d0d0d0;
	border-radius: 0 2px 2px 0;
	box-sizing: border-box;
}

.selectize-control.plugin-remove_button [data-value] .remove:hover {
	background: rgba(0, 0, 0, 0.05);
}

.selectize-control.plugin-remove_button [data-value].active .remove {
	border-left-color: #cacaca;
}

.selectize-control.plugin-remove_button .disabled [data-value] .remove {
	border-left-color: #ffffff;
}

.selectize-control.plugin-remove_button .disabled [data-value] .remove:hover {
	background: none;
}

.selectize-control.plugin-remove_button .remove-single {
	position: absolute;
	right: 28px;
	top: 6px;
	font-size: 23px;
}

.selectize-dropdown {
	color: #303030;
	font-family: inherit;
	line-height: 30px;
	-webkit-font-smoothing: inherit;
}

.selectize-input {
	color: #787878;
	font-family: inherit;
	-webkit-font-smoothing: inherit;
	cursor: text;
	display: inline-block;
}

.selectize-input input {
	color: #787878;
	font-family: inherit;
	-webkit-font-smoothing: inherit;
}

.selectize-control.single .selectize-input.input-active {
	background: #ffffff;
	cursor: text;
	display: inline-block;
}

.selectize-input {
	border: 1px solid #d0d0d0;
	padding: 14px 60px 14px 30px;
	display: block;
	width: 100%;
	overflow: hidden;
	position: relative;
	z-index: 1;
	box-sizing: border-box;
	border-radius: 10px;
}

.selectize-control.multi .selectize-input.has-items {
	padding: 6px 8px 3px;
}

.selectize-input {
	min-width: 200px;
	height: 60px;
}

.selectize-input.disabled {
	cursor: default !important;
}

.selectize-input.disabled * {
	cursor: default !important;
}

.selectize-input > * {
	vertical-align: top;
	display: inline-block;
	zoom: 1;
	line-height: 30px !important;
	height: 30px !important;
}

.selectize-control.multi .selectize-input > div {
	cursor: pointer;
	margin: 0 3px 3px 0;
	padding: 2px 6px;
	background: #f2f2f2;
	color: #303030;
	border: 0 solid #d0d0d0;
}

.selectize-control.multi .selectize-input > div.active {
	background: #e8e8e8;
	color: #303030;
	border: 0 solid #cacaca;
}

.selectize-control.multi .selectize-input.disabled > div {
	color: #7d7d7d;
	background: #ffffff;
	border: 0 solid #ffffff;
}

.selectize-control.multi .selectize-input.disabled > div.active {
	color: #7d7d7d;
	background: #ffffff;
	border: 0 solid #ffffff;
}

.selectize-input > input {
	display: inline-block !important;
	padding: 0 !important;
	min-height: 0 !important;
	max-height: none !important;
	max-width: 100% !important;
	margin: 0 2px 0 0 !important;
	text-indent: 0 !important;
	border: 0 none !important;
	background: none !important;
	-webkit-user-select: auto !important;
	box-shadow: none !important;
}

.selectize-input > input::-ms-clear {
	display: none;
}

.selectize-input > input:focus {
	outline: none !important;
}

.selectize-input::after {
	content: ' ';
	display: block;
	clear: left;
}

.selectize-dropdown {
	position: absolute;
	z-index: 10;
	border: 1px solid #d0d0d0;
	background: #ffffff;
	margin: -1px 0 0 0;
	box-sizing: border-box;
	border-radius: 10px;
	overflow: hidden;
}

.selectize-dropdown [data-selectable] {
	cursor: pointer;
	overflow: hidden;
	padding: 5px 8px;
}

.selectize-dropdown [data-selectable] .highlight {
	background: rgba(125, 168, 208, 0.2);
	border-radius: 1px;
}

.selectize-dropdown .optgroup-header {
	padding: 5px 8px;
}

.selectize-dropdown .optgroup:first-child .optgroup-header {
	border-top: 0 none;
}

.selectize-dropdown .optgroup-header {
	color: #303030;
	background: #ffffff;
	cursor: default;
}

.selectize-dropdown .active {
	background-color: #f5fafd;
	color: #495c68;
}

.selectize-dropdown .active.create {
	color: #495c68;
}

.selectize-dropdown .create {
	color: rgba(48, 48, 48, 0.5);
}

.selectize-dropdown-content {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 200px;
	-webkit-overflow-scrolling: touch;
}

.selectize-control.single .selectize-input {
	cursor: pointer;
}

.selectize-control.single .selectize-input input {
	cursor: pointer;
}

.selectize-control.single .selectize-input.input-active {
	cursor: text;
}

.selectize-control.single .selectize-input.input-active input {
	cursor: text;
}

.selectize-control.single .selectize-input:after {
	content: "\f107";
	font-family: FontAwesome;
	display: block;
	position: absolute;
	top: 50%;
	right: 25px;
	margin-top: -8px;
	font-size: 16px;
	line-height: 1em;
	color: #F15931;
}

.selectize-control.rtl.single .selectize-input:after {
	left: 15px;
	right: auto;
}

.selectize-control.rtl .selectize-input > input {
	margin: 0 4px 0 -2px !important;
}

.selectize-control .selectize-input.disabled {
	opacity: 0.5;
	background-color: #fafafa;
}

/*
** Vertical Margins and Paddings
*/
/*same top and bottom paddings for sections*/
.section_padding_0 > [class*="container"] {
	padding: 0;
}

.section_padding_15 > [class*="container"] {
	padding-top: 15px;
	padding-bottom: 15px;
}

.section_padding_25 > [class*="container"] {
	padding-top: 25px;
	padding-bottom: 25px;
}

.section_padding_50 > [class*="container"] {
	padding-top: 50px;
	padding-bottom: 50px;
}

.section_padding_75 > [class*="container"] {
	padding-top: 75px;
	padding-bottom: 75px;
}

.section_padding_100 > [class*="container"] {
	padding-top: 100px;
	padding-bottom: 100px;
}

/*top paddings for sections*/
.section_padding_top_0 > [class*="container"] {
	padding-top: 0;
}

.section_padding_top_5 > [class*="container"] {
	padding-top: 5px;
}

.section_padding_top_10 > [class*="container"] {
	padding-top: 10px;
}

.section_padding_top_15 > [class*="container"] {
	padding-top: 15px;
}

.section_padding_top_20 > [class*="container"] {
	padding-top: 20px;
}

.section_padding_top_25 > [class*="container"] {
	padding-top: 25px;
}

.section_padding_top_30 > [class*="container"] {
	padding-top: 30px;
}

.section_padding_top_40 > [class*="container"] {
	padding-top: 40px;
}

.section_padding_top_50 > [class*="container"] {
	padding-top: 50px;
}

.section_padding_top_65 > [class*="container"] {
	padding-top: 65px;
}

.section_padding_top_75 > [class*="container"] {
	padding-top: 75px;
}

.section_padding_top_90 > [class*="container"] {
	padding-top: 90px;
}

.section_padding_top_100 > [class*="container"] {
	padding-top: 100px;
}

.section_padding_top_110 > [class*="container"] {
	padding-top: 110px;
}

.section_padding_top_120 > [class*="container"] {
	padding-top: 120px;
}

.section_padding_top_130 > [class*="container"] {
	padding-top: 130px;
}

.section_padding_top_140 > [class*="container"] {
	padding-top: 140px;
}

.section_padding_top_150 > [class*="container"] {
	padding-top: 150px;
}

/*bottom paddings for sections*/
.section_padding_bottom_0 > [class*="container"] {
	padding-bottom: 0;
}

.section_padding_bottom_5 > [class*="container"] {
	padding-bottom: 5px;
}

.section_padding_bottom_10 > [class*="container"] {
	padding-bottom: 10px;
}

.section_padding_bottom_15 > [class*="container"] {
	padding-bottom: 15px;
}

.section_padding_bottom_20 > [class*="container"] {
	padding-bottom: 20px;
}

.section_padding_bottom_25 > [class*="container"] {
	padding-bottom: 25px;
}

.section_padding_bottom_30 > [class*="container"] {
	padding-bottom: 30px;
}

.section_padding_bottom_40 > [class*="container"] {
	padding-bottom: 40px;
}

.section_padding_bottom_50 > [class*="container"] {
	padding-bottom: 50px;
}

.section_padding_bottom_65 > [class*="container"] {
	padding-bottom: 65px;
}

.section_padding_bottom_75 > [class*="container"] {
	padding-bottom: 75px;
}

.section_padding_bottom_100 > [class*="container"] {
	padding-bottom: 100px;
}

.section_padding_bottom_110 > [class*="container"] {
	padding-bottom: 110px;
}

.section_padding_bottom_120 > [class*="container"] {
	padding-bottom: 120px;
}

.section_padding_bottom_130 > [class*="container"] {
	padding-bottom: 130px;
}

.section_padding_bottom_140 > [class*="container"] {
	padding-bottom: 140px;
}

.section_padding_bottom_150 > [class*="container"] {
	padding-bottom: 150px;
}

@media (max-width: 1199px) {
	.section_padding_90 > [class*="container"],
  .section_padding_100 > [class*="container"],
  .section_padding_top_90 > [class*="container"],
  .section_padding_top_100 > [class*="container"],
  .section_padding_top_110 > [class*="container"],
  .section_padding_top_130 > [class*="container"],
  .section_padding_top_150 > [class*="container"] {
		padding-top: 70px;
	}

	.section_padding_100 > [class*="container"],
  .section_padding_bottom_100 > [class*="container"],
  .section_padding_bottom_110 > [class*="container"],
  .section_padding_bottom_120 > [class*="container"],
  .section_padding_bottom_130 > [class*="container"],
  .section_padding_bottom_150 > [class*="container"] {
		padding-bottom: 70px;
	}
}

@media (max-width: 767px) {
	.section_padding_90 > [class*="container"],
  .section_padding_100 > [class*="container"],
  .section_padding_top_90 > [class*="container"],
  .section_padding_top_100 > [class*="container"],
  .section_padding_top_110 > [class*="container"],
  .section_padding_top_130 > [class*="container"],
  .section_padding_top_150 > [class*="container"] {
		padding-top: 60px;
	}

	.section_padding_100 > [class*="container"],
  .section_padding_bottom_100 > [class*="container"],
  .section_padding_bottom_110 > [class*="container"],
  .section_padding_bottom_120 > [class*="container"],
  .section_padding_bottom_130 > [class*="container"],
  .section_padding_bottom_150 > [class*="container"] {
		padding-bottom: 60px;
	}
}

[class*="col-"] {
	margin-top: 10px;
	margin-bottom: 10px;
}

header [class*="col-"] {
	margin-top: 0;
	margin-bottom: 0;
}

.columns_margin_0 [class*="col-"] {
	margin-top: 0;
	margin-bottom: 0;
}

.columns_padding_1 [class*="col-"] {
	margin-top: 0;
	margin-bottom: 1px;
}

.columns_padding_2 [class*="col-"] {
	margin-top: 1px;
	margin-bottom: 1px;
}

.columns_padding_5 [class*="col-"] {
	margin-top: 5px;
	margin-bottom: 5px;
}

.columns_padding_10 [class*="col-"] {
	margin-top: 10px;
	margin-bottom: 10px;
}

.columns_padding_15 [class*="col-"] {
	margin-top: 15px;
	margin-bottom: 15px;
}

.columns_padding_1 [class*="col-"] {
	padding-left: 0px;
	padding-right: 1px;
	margin-top: 0;
	margin-bottom: 1px;
	overflow: hidden;
}

.columns_padding_2 [class*="col-"] {
	padding-left: 1px;
	padding-right: 1px;
	margin-top: 1px;
	margin-bottom: 1px;
}

.columns_padding_5 [class*="col-"] {
	padding-left: 5px;
	padding-right: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.columns_padding_10 [class*="col-"] {
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.columns_padding_10 .col-xs-12:not([class*="sm"]):not([class*="md"]) {
	margin-top: 10px;
	margin-bottom: 5px;
}

.columns_padding_0 [class*="col-"] {
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
}

.columns_padding_0 > .container,
.columns_padding_1 > .container,
.columns_padding_2 > .container {
	padding-left: 30px;
	padding-right: 30px;
}

.columns_padding_5 > .container {
	padding-left: 25px;
	padding-right: 25px;
}

.columns_padding_10 > .container {
	padding-left: 20px;
	padding-right: 20px;
}

.columns_padding_10 > .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}

.container_padding_0 > .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.container_padding_0 > .container-fluid > .row {
	margin: 0;
}

.row.columns_padding_0,
.columns_padding_0 .row {
	margin-left: 0;
	margin-right: 0;
}

.row.columns_padding_0[class*="display_table"],
.columns_padding_0 .row[class*="display_table"] {
	margin-right: -15px;
	margin-left: -15px;
	min-width: calc(100% + 30px);
}

.row.columns_padding_1,
.columns_padding_1 .row {
	margin-left: -1px;
	margin-right: -1px;
}

.row.columns_padding_2,
.columns_padding_2 .row {
	margin-left: -1px;
	margin-right: -1px;
}

.row.columns_padding_5,
.columns_padding_5 .row {
	margin-left: -5px;
	margin-right: -5px;
}

.row.columns_padding_10,
.columns_padding_10 .row {
	margin-left: -10px;
	margin-right: -10px;
}

@media (min-width: 992px) {
	.columns_padding_25 > [class*="container"] > .row,
  .row.columns_padding_25,
  .columns_padding_50 > [class*="container"] > .row {
		margin-right: -25px;
		margin-left: -25px;
	}

	.columns_padding_25 > [class*="container"] > .row > [class*="col-"],
  .row.columns_padding_25 > [class*="col-"],
  .columns_padding_50 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 25px;
		padding-right: 25px;
	}

	.columns_padding_80 > [class*="container"] > .row {
		margin-right: -50px;
		margin-left: -50px;
	}

	.columns_padding_80 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 50px;
		padding-right: 50px;
	}
}

@media (min-width: 1200px) {
	.columns_padding_30 > [class*="container"] > .row,
  .row.columns_padding_30 {
		margin-right: -30px;
		margin-left: -30px;
	}

	.columns_padding_30 > [class*="container"] > .row > [class*="col-"],
  .row.columns_padding_30 > [class*="col-"] {
		padding-left: 30px;
		padding-right: 30px;
	}

	.columns_padding_50 > [class*="container"] > .row {
		margin-right: -50px;
		margin-left: -50px;
	}

	.columns_padding_50 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 50px;
		padding-right: 50px;
	}

	.columns_padding_80 > [class*="container"] > .row {
		margin-right: -80px;
		margin-left: -80px;
	}

	.columns_padding_80 > [class*="container"] > .row > [class*="col-"] {
		padding-left: 80px;
		padding-right: 80px;
	}
}

/*top and bottom margins for columns inside sections and rows */
.columns_margin_top_0 [class*="col-"],
.row.columns_margin_top_0 [class*="col-"] {
	margin-top: 0;
}

.columns_margin_top_5 [class*="col-"],
.row.columns_margin_top_5 [class*="col-"] {
	margin-top: 5px;
}

.columns_margin_top_10 [class*="col-"],
.row.columns_margin_top_10 [class*="col-"] {
	margin-top: 10px;
}

.columns_margin_top_15 [class*="col-"],
.row.columns_margin_top_15 [class*="col-"] {
	margin-top: 15px;
}

.columns_margin_top_20 [class*="col-"],
.row.columns_margin_top_20 [class*="col-"] {
	margin-top: 20px;
}

.columns_margin_top_30 [class*="col-"],
.row.columns_margin_top_30 [class*="col-"] {
	margin-top: 30px;
}

.columns_margin_top_60 [class*="col-"],
.row.columns_margin_top_60 [class*="col-"] {
	margin-top: 60px;
}

.columns_margin_bottom_5 [class*="col-"],
.row.columns_margin_bottom_5 [class*="col-"] {
	margin-bottom: 5px;
}

.columns_margin_bottom_10 [class*="col-"],
.row.columns_margin_bottom_10 [class*="col-"] {
	margin-bottom: 10px;
}

.columns_margin_bottom_15 [class*="col-"],
.row.columns_margin_bottom_15 [class*="col-"] {
	margin-bottom: 15px;
}

.columns_margin_bottom_20 [class*="col-"],
.row.columns_margin_bottom_20 [class*="col-"] {
	margin-bottom: 20px;
}

.columns_margin_bottom_30 [class*="col-"],
.row.columns_margin_bottom_30 [class*="col-"] {
	margin-bottom: 30px;
}

.columns_margin_bottom_40 [class*="col-"],
.row.columns_margin_bottom_40 [class*="col-"] {
	margin-bottom: 40px;
}

.columns_margin_bottom_0 [class*="col-"],
.row.columns_margin_bottom_0 [class*="col-"] {
	margin-bottom: 0;
}

/* vertical alignmetn in columns */
.table_section .row {
	min-width: 100%;
	height: 100%;
}

@media (max-width: 767px) {
	aside[class*="col-"] {
		margin-top: 60px !important;
	}
}

@media (min-width: 768px) {
	.table_section_sm .container {
		height: 100%;
	}

	.table_section.table_section_sm .row {
		display: table;
		width: calc(100% + 30px);
	}

	.table_section.table_section_sm > [class*="container"] > .row > [class*="col-"] {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}
}

@media (min-width: 992px) {
	.table_section_md .container {
		height: 100%;
	}

	.table_section.table_section_md .row {
		display: table;
		width: calc(100% + 30px);
	}

	.table_section.table_section_md > [class*="container"] > .row > [class*="col-"] {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}

	.offset-image {
		margin-top: -150px;
	}
}

@media screen and (min-width: 1200px) {
	.table_section_lg .container {
		height: 100%;
	}

	.table_section.table_section_lg .row {
		display: table;
		width: calc(100% + 30px);
	}

	.table_section.table_section_lg > [class*="container"] > .row > [class*="col-"] {
		display: table-cell;
		vertical-align: middle;
		float: none;
	}
}

@media screen and (min-width: 992px) {
  /* overlap featured person */
	.top-overlap {
		margin-top: -100px;
		max-width: 125%;
		margin-left: -25%;
	}

	.top-overlap-small {
		margin-top: -40px;
		max-width: 140%;
		margin-right: -20%;
		margin-left: -20%;
	}
}

@media (min-width: 1200px) {
	.top-overlap {
		margin-top: -180px;
	}
}

.section_full_height {
	min-height: 100vh;
}

.flex-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.flex-row > [class*='col-'] {
	min-width: 300px;
	-ms-flex: 1;
	flex: 1;
	display: -ms-flexbox;
	display: flex;
}

.flex-row > [class*='col-'] > * {
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

@media (min-width: 992px) and (max-width: 1199px) {
	.flex-row > [class*='col-'].col-md-6 {
		-ms-flex: auto;
		flex: auto;
		width: 50%;
	}
}

@media (max-width: 991px) {
	.flex-row > [class*='col-'].col-sm-12 {
		-ms-flex: auto;
		flex: auto;
		width: 100%;
	}
}

.flex-row.flex-row-vertical-centered > [class*='col-'] > * {
	-ms-flex-pack: center;
	justify-content: center;
}

@media (min-width: 992px) {
	.container-fluid {
		padding-right: 40px;
		padding-left: 40px;
	}
}

@media (min-width: 1200px) {
	.container-fluid {
		padding-right: 60px;
		padding-left: 60px;
	}
}

@media (min-width: 1600px) {
	.container-fluid {
		padding-right: 100px;
		padding-left: 100px;
	}
}

.fluid_padding_0 .container-fluid {
	padding-right: 15px;
	padding-left: 15px;
}

.columns_padding_0.fluid_padding_0 .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

/* float clear fixes */
@media (min-width: 768px) and (max-width: 991px) {
	.clear-sm {
		clear: both;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.clear-md {
		clear: both;
	}
}

@media (min-width: 1200px) {
	.clear-lg {
		clear: both;
	}
}

@media (max-width: 499px) {
	.col-xxs-12 {
		width: 100%;
	}
}

@media (max-width: 499px) {
	.text-xxs-center {
		text-align: center;
	}
}

/*
** Common Sections Styles
*/
/*parallax*/
.image_cover,
.background_cover,
.parallax {
	background-size: cover;
}

.background_cover {
	background-position: center;
}

.image_cover {
	display: block;
	position: absolute;
	width: 50%;
	z-index: 2;
	top: 0;
	bottom: 0;
	background-position: 50% 50%;
}

.image_cover > img {
	visibility: hidden;
}

.image_cover > a {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.image_cover.image_cover_right {
	right: 0;
}

.image_cover.small_width {
	width: 42%;
}

@media (max-width: 991px) {
	.image_cover {
		height: 0;
		padding-bottom: 66%;
		margin-bottom: -30px;
		width: 100%;
		position: relative;
	}

	.image_cover.small_width {
		width: 100%;
		padding-bottom: 100%;
	}
}

.image_cover + [class*='container'] [class*='col-'] > img,
.image_cover + [class*='container'] [class*='col-'] > a:after,
.image_cover + [class*='container'] [class*='col-'] > a > img {
	display: none;
}

@media (min-width: 992px) {
	.half_section.columns_padding_80 > [class*="container"] > .row {
		margin-left: 0;
		margin-right: 0;
	}
}

.half_section .image_cover_left + .container .col-md-6 {
	padding-right: 0;
}

.half_section .image_cover_right + .container .col-md-6 {
	padding-left: 0;
}

.parallax {
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative;
}

@media (max-width: 1199px) {
	.parallax {
		background-attachment: scroll !important;
		background-size: cover !important;
		background-position: center !important;
	}
}

.overlay_color.ls:after {
	background-color: #ffffff;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=92)";
	filter: alpha(opacity=90);
	opacity: 0.9;
}

.overlay_color.ls.ms:after {
	background-color: #f7f7f7;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=88)";
	filter: alpha(opacity=80);
	opacity: 0.8;
}

.overlay_color.cs:after {
	background-color: #F15931;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=90)";
	filter: alpha(opacity=90);
	opacity: 0.9;
}

.overlay_color.cs.main_color2:after {
	background-color: #F15931;
}

.overlay_color.cs.main_color3:after {
	background-color: #fcb315;
}

.overlay_color.cs.main_color4:after {
	background-color: #03a8cb;
}

.overlay_color.ds:after {
	background-color: #3a3d44;
	opacity: 0.85;
}

.overlay_color.ds:after {
	background-color: #3a3d44;
	opacity: 0.9;
}

.overlay_color.ds.ms:after {
	background-color: #1f232b;
	opacity: 0.9;
}

.overlay_color.ds.bs:after {
	background-color: #00202b;
	opacity: 0.67;
}

.overlay_color.ds.black:after {
	background-color: #000;
	opacity: 0.6;
}

.texture_bg {
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	position: relative;
}

.muted_section:before {
	background-color: rgba(58, 61, 68, 0.1);
	opacity: 1;
}

.muted_section > *,
.radial_gradient > *,
.gradient > *,
.vertical_gradient > *,
.darken_gradient > *,
.diagonal_section > *,
.overlay_color > * {
	z-index: 4;
	position: relative;
}

.radial_gradient {
	position: relative;
	overflow: hidden;
}

.gradient,
.muted_section,
.vertical_gradient,
.darken_gradient,
.overlay_color {
	position: relative;
}

.muted_section:before,
.muted_section:after,
.gradient:before,
.gradient:after,
.vertical_gradient:before,
.vertical_gradient:after,
.darken_gradient:before,
.overlay_color:after,
.darken_gradient:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
}

.muted_section:before,
.muted_section:after,
.overlay_color:after {
	opacity: 0.8;
}

.darken_gradient:before {
	background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
}

.overflow_hidden {
	overflow: hidden;
	position: relative;
}

.with_top_border,
.with_bottom_border,
.with_top_border_container .container,
.with_bottom_border_container .container {
	position: relative;
}

.with_top_border:before,
.with_bottom_border:after,
.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
	content: '';
	display: block;
	position: absolute;
	height: 1px;
	left: 0;
	right: 0;
	top: 0;
	background-color: rgba(120, 120, 120, 0.2);
	z-index: 1;
}

.ds .with_top_border:before, .ds
.with_bottom_border:after, .ds
.with_top_border_container .container:before, .ds
.with_bottom_border_container .container:after {
	background-color: rgba(255, 255, 255, 0.2);
}

.row.with_top_border:before, .row.width_bottom_border:after {
	left: 15px;
	right: 15px;
}

.ds.with_top_border:before, .ds.with_bottom_border:after,
.ds.with_top_border_container .container:before,
.ds.with_bottom_border_container .container:after {
	background-color: rgba(255, 255, 255, 0.2);
}

.with_top_border_container .container:before,
.with_bottom_border_container .container:after {
	right: 15px;
	left: 15px;
}

.with_bottom_border:after,
.with_bottom_border_container .container:after,
.with_bottom_border_two_colors:after {
	top: auto;
	bottom: 0;
}

.bg_image,
.bg_image:after {
	background-image: url(/static/img/background_section.png);
}

h2.section_header {
	margin-bottom: 0.5em;
	font-size: 32px;
	position: relative;
	word-wrap: break-word;
	line-height: 1;
	border-left: 2px solid #F15931;
	padding: 10px 0 10px 20px;
}

h2.section_header.color2 {
	border-left-color: #F15931;
}

h2.section_header.color3 {
	border-left-color: #fcb315;
}

h2.section_header.color4 {
	border-left-color: #03a8cb;
}

.text-center h2.section_header, h2.section_header.text-center {
	border-left: none;
	padding: 0;
}

@media (min-width: 1200px) {
	h2.section_header {
		font-size: 40px;
		padding: 15px 0 15px 30px;
		margin-bottom: 0.75em;
	}
}

h2.section_header + .section-excerpt {
	margin-bottom: 20px;
}

@media (min-width: 1200px) {
	h2.section_header + .section-excerpt {
		margin-top: 30px;
	}
}

h2.section_header:last-child {
	margin-bottom: 0;
}

.section-excerpt {
	font-size: 20px;
	font-style: italic;
}

/*page preloader*/
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.99);
	z-index: 13000;
	height: 100%;
}

.no-js .preloader,
.lt-ie9 .preloader,
.lt-ie10 .preloader {
	display: none;
}

.preloader_image {
	width: 100px;
	height: 100px;
	position: absolute;
	left: 50%;
	top: 50%;
	background: url(/static/img/preloader.gif) no-repeat 50% 50% transparent;
	margin: -50px 0 0 -50px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.preloader_image {
		background: url(/static/img/preloader.gif) no-repeat 50% 50% transparent;
	}
}

/* animated elements */
.to_animate {
	visibility: hidden;
}

.no-js .to_animate,
.lt-ie10 .to_animate,
.lt-ie9 .to_animate {
	visibility: visible;
}

.animated {
  /*IE7 fix*/
	visibility: visible !important;
}

/* boxed layout */
#box_wrapper, #canvas {
	overflow: hidden;
	position: relative;
}

#box_wrapper > section,
#box_wrapper > div > section {
	clear: both;
}

#canvas.boxed {
	padding: 1px 0;
}

body.boxed {
	position: static;
	width: auto;
	height: auto;
}

@media (min-width: 1200px) {
	#box_wrapper.top-bottom-margins {
		margin-top: 60px;
		margin-bottom: 60px;
	}
}

.pattern1 {
	background-image: url(/static/img/pattern1.png);
}

.pattern2 {
	background-image: url(/static/img/pattern2.png);
}

.pattern3 {
	background-image: url(/static/img/pattern3.png);
}

.pattern4 {
	background-image: url(/static/img/pattern4.png);
}

.pattern5 {
	background-image: url(/static/img/pattern5.png);
}

.pattern6 {
	background-image: url(/static/img/pattern6.png);
}

.pattern7 {
	background-image: url(/static/img/pattern7.png);
}

.pattern8 {
	background-image: url(/static/img/pattern8.png);
}

.pattern9 {
	background-image: url(/static/img/pattern9.png);
}

.pattern10 {
	background-image: url(/static/img/pattern10.png);
}

.pattern11 {
	background-image: url(/static/img/pattern11.png);
}

.boxed .container,
.boxed .container-fluid,
.boxed .container-left-half,
.boxed .container-right-half {
	max-width: 100%;
}

.boxed .container,
.boxed .container-left-half,
.boxed .container-right-half {
	padding-left: 25px;
	padding-right: 25px;
}

@media (max-width: 500px) {
	.boxed .container {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 1400px) {
	.boxed #box_wrapper.container {
		width: 1370px;
	}
}

@media (min-width: 1590px) {
	.boxed #box_wrapper.container {
		width: 1560px;
	}
}

.boxed #box_wrapper.container {
	padding: 0;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

@media (min-width: 1230px) {
	.container {
		width: 1200px;
	}
}

#box_wrapper > section {
	overflow: hidden;
}

/* full height for 404 page */
#box_wrapper, #canvas {
	min-height: 100vh;
}

#box_wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}

.section_404 {
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

/*
**utilites
*/
/* 
**Helper Utilites 
*/
/* layout */
.clear, .clearfix {
	clear: both;
}

.alignleft, .alignright {
	margin-bottom: 15px;
}

@media (min-width: 768px) {
	.alignleft {
		float: left;
		margin: 5px 30px 15px 0;
		max-width: 50%;
	}

	.alignright {
		float: right;
		margin: 5px 0 15px 30px;
		max-width: 50%;
	}

	.alignright.one-third,
  .alignleft.one-third {
		max-width: 50%;
	}
}

@media (min-width: 992px) {
	.alignright.one-third,
  .alignleft.one-third {
		max-width: 33.33333%;
	}
}

@media (min-width: 1200px) {
	.col-xs-12 .alignleft {
		margin: 5px 50px 30px 0;
	}

	.col-xs-12 .alignright {
		margin: 5px 0 30px 50px;
	}
}

.round {
	border-radius: 50%;
}

.rounded {
	border-radius: 10px;
}

.top_rounded {
	border-radius: 10px 10px 0 0;
}

.bottom_rounded {
	border-radius: 0 0 10px 10px;
}

.overflow_hidden {
	overflow: hidden;
	z-index: 1;
}

.inline-block {
	display: inline-block;
	max-width: 100%;
}

.display-block {
	display: block;
}

.divided-content > * {
	display: inline-block;
}

.divided-content > *:before,
.divided-content > *:last-child:after {
	content: "";
	width: 1px;
	height: 8px;
	background-color: rgba(120, 120, 120, 0.6);
	display: inline-block;
	margin-right: 14px;
	margin-left: 10px;
	color: #787878;
}

.divided-content > *:first-child:before {
	margin-left: 0;
	display: none;
}

.divided-content > *:last-child:after {
	margin-right: 0;
	display: none;
}

.divided-content.outside-dividers > *:first-child:before,
.divided-content.outside-dividers > *:last-child:after {
	display: inline-block;
}

.with_padding {
	padding: 20px;
}

@media (min-width: 400px) {
	.with_padding {
		padding: 35px;
	}
}

@media (min-width: 400px) {
	.with_padding.small_padding {
		padding: 25px;
	}
}

@media (min-width: 1200px) {
	.with_padding.big-padding {
		padding: 55px 60px;
	}
}

.with_background {
	background-color: #f2f2f2;
}

.with_background .with_background {
	background-color: #ffffff;
}

.muted_background {
	background-color: #f2f2f2;
}

.with_border {
	border: 1px solid #e4e4e4;
}

.with_border.thick_border {
	border-width: 10px;
}

.with_shadow {
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.with_big_shadow {
	box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.1);
}

.no_top_border {
	border-top: none;
}

.no_bottom_border {
	border-bottom: none;
}

.bottom_color_border {
	position: relative;
}

.bottom_color_border:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 3px;
	background-color: #F15931;
	z-index: 1;
}

.bottom_color_border.with_border:after {
	bottom: -1px;
	left: -1px;
	right: -1px;
}

.bottom_color_border.color2:after {
	background-color: #F15931;
}

.bottom_color_border.color3:after {
	background-color: #fcb315;
}

.bottom_color_border.color4:after {
	background-color: #03a8cb;
}

.top_color_border {
	border-top: 3px solid #F15931;
}

.with_abs_button {
	position: relative;
	margin-bottom: 20px;
}

.with_abs_button .theme_button {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -30px;
	white-space: nowrap;
}

.with_abs_button > *:nth-last-child(2) {
	margin-bottom: 0;
}

@media (max-width: 399px) {
	.with_abs_button.with_padding {
		padding-bottom: 35px;
	}
}

.bottom-border {
	position: relative;
}

.bottom-border:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: rgba(0, 0, 0, 0.1);
}

.before_cover,
.after_cover {
	overflow: hidden;
}

.before_cover,
.after_cover,
.before_cover > *,
.after_cover > * {
	position: relative;
	z-index: 2;
}

.before_cover:before,
.after_cover:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.top-corner {
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1;
}

.big {
	font-size: 32px;
	line-height: 1;
}

strong.big {
	font-size: 24px;
	font-weight: 400;
	font-family: 'Ubuntu', sans-serif;
}

/* borders, colors and backgrouns */
.response,
.highlight,
.highlight1 {
	color: #F15931;
	border-color: #F15931;
}

.highlight2 {
	color: #F15931;
	border-color: #F15931;
}

.highlight3 {
	color: #F15931;
	border-color: #F15931;
}

.highlight4 {
	color: #03a8cb;
	border-color: #03a8cb;
}

.red, .required {
	color: #fa5c5d;
}

.lightgrey {
	color: #bfbfbf;
}

.black {
	color: #1f232b;
	border-color: #1f232b;
}

.grey {
	color: #3a3d44;
	border-color: #3a3d44;
}

.light {
	color: #ffffff;
}

.fontcolor {
	color: #787878;
}

.greylinks a {
	color: #787878;
}

.greylinks a.social-icon {
	color: rgba(120, 120, 120, 0.6);
}

.greylinks a.social-icon:hover, .greylinks a.social-icon:focus, .greylinks a.social-icon:active {
	color: #F15931;
}

.darklinks a {
	color: #3a3d44;
}

.darklinks a:hover {
	color: #F15931;
}

.darklinks.color2 a:hover {
	color: #F15931;
}

.colorlinks a {
	color: #F15931;
}

.colorlinks a:hover {
	color: #F15931;
}

.underlined-links a {
	text-decoration: underline;
}

a.underline-link {
	text-decoration: underline;
}

.thin {
	font-weight: 300;
}

.regular {
	font-weight: 400;
}

.medium {
	font-weight: 500;
}

.semibold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.weight-black {
	font-weight: 900;
}

.success_color {
	color: #72b946;
}

.info_color {
	color: #03a8cb;
}

.warning_color {
	color: #F15931;
}

.danger_color {
	color: #F15931;
}

.main_bg_color,
.main_bg_color2,
.main_bg_color3,
.main_bg_color4,
.dark_bg_color,
.darkblue_bg_color,
.darkgrey_bg_color,
.darkgrey_bg.bg_teaser,
.success_bg_color,
.info_bg_color,
.warning_bg_color,
.danger_bg_color,
.danger_bg_color,
.transp_gradient_bg,
[class*="gradient_bg"] {
	color: #e0e0e1;
}

.main_bg_color h1, .main_bg_color h2, .main_bg_color h3, .main_bg_color h4, .main_bg_color h5, .main_bg_color h6,
.main_bg_color2 h1,
.main_bg_color2 h2,
.main_bg_color2 h3,
.main_bg_color2 h4,
.main_bg_color2 h5,
.main_bg_color2 h6,
.main_bg_color3 h1,
.main_bg_color3 h2,
.main_bg_color3 h3,
.main_bg_color3 h4,
.main_bg_color3 h5,
.main_bg_color3 h6,
.main_bg_color4 h1,
.main_bg_color4 h2,
.main_bg_color4 h3,
.main_bg_color4 h4,
.main_bg_color4 h5,
.main_bg_color4 h6,
.dark_bg_color h1,
.dark_bg_color h2,
.dark_bg_color h3,
.dark_bg_color h4,
.dark_bg_color h5,
.dark_bg_color h6,
.darkblue_bg_color h1,
.darkblue_bg_color h2,
.darkblue_bg_color h3,
.darkblue_bg_color h4,
.darkblue_bg_color h5,
.darkblue_bg_color h6,
.darkgrey_bg_color h1,
.darkgrey_bg_color h2,
.darkgrey_bg_color h3,
.darkgrey_bg_color h4,
.darkgrey_bg_color h5,
.darkgrey_bg_color h6,
.darkgrey_bg.bg_teaser h1,
.darkgrey_bg.bg_teaser h2,
.darkgrey_bg.bg_teaser h3,
.darkgrey_bg.bg_teaser h4,
.darkgrey_bg.bg_teaser h5,
.darkgrey_bg.bg_teaser h6,
.success_bg_color h1,
.success_bg_color h2,
.success_bg_color h3,
.success_bg_color h4,
.success_bg_color h5,
.success_bg_color h6,
.info_bg_color h1,
.info_bg_color h2,
.info_bg_color h3,
.info_bg_color h4,
.info_bg_color h5,
.info_bg_color h6,
.warning_bg_color h1,
.warning_bg_color h2,
.warning_bg_color h3,
.warning_bg_color h4,
.warning_bg_color h5,
.warning_bg_color h6,
.danger_bg_color h1,
.danger_bg_color h2,
.danger_bg_color h3,
.danger_bg_color h4,
.danger_bg_color h5,
.danger_bg_color h6,
.danger_bg_color h1,
.danger_bg_color h2,
.danger_bg_color h3,
.danger_bg_color h4,
.danger_bg_color h5,
.danger_bg_color h6,
.transp_gradient_bg h1,
.transp_gradient_bg h2,
.transp_gradient_bg h3,
.transp_gradient_bg h4,
.transp_gradient_bg h5,
.transp_gradient_bg h6,
[class*="gradient_bg"] h1,
[class*="gradient_bg"] h2,
[class*="gradient_bg"] h3,
[class*="gradient_bg"] h4,
[class*="gradient_bg"] h5,
[class*="gradient_bg"] h6 {
	color: #ffffff;
}

.main_bg_color {
	background-color: #F15931;
}

.main_bg_color.transp {
	background-color: rgba(232, 82, 66, 0.89);
}

.main_bg_color2 {
	background-color: #F15931;
}

.main_bg_color2.transp {
	background-color: rgba(243, 112, 43, 0.89);
}

.main_bg_color3 {
	background-color: #fcb315;
}

.main_bg_color3.transp {
	background-color: rgba(252, 179, 21, 0.89);
}

.main_bg_color4 {
	background-color: #03a8cb;
}

.main_bg_color4.transp {
	background-color: rgba(3, 168, 203, 0.89);
}

.dark_bg_color {
	background-color: #1f232b;
}

.darkblue_bg_color {
	color: #ffffff;
	background-color: #00202b;
}

.darkgrey_bg_color {
	color: #ffffff;
	background-color: #3a3d44;
}

.success_bg_color {
	background-color: #72b946;
}

.info_bg_color {
	background-color: #03a8cb;
}

.warning_bg_color {
	background-color: #F15931;
}

.danger_bg_color {
	background-color: #F15931;
}

.light_bg_color {
	background-color: #ffffff;
}

.gradient_bg {
	background: linear-gradient(to right, #F15931, #f3702b);
}

.transp_bg {
	background-color: transparent !important;
}

.theme_bg {
	background-color: #ffffff;
}

/*dividers, margins and paddings*/
.divider_20 {
	margin: 20px 0;
}

.divider_30 {
	margin: 30px 0;
}

.divider_40 {
	margin: 40px 0;
}

.divider_60_2 {
	width: 60px;
	height: 2px;
}

.text-center hr[class*="divider_"],
.text-center hr[class*="_divider"] {
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}

.margin_0 {
	margin: 0 !important;
}

.topmargin_0 {
	margin-top: 0 !important;
}

.topmargin_1 {
	margin-top: 1px;
}

.topmargin_5 {
	margin-top: 5px;
}

.topmargin_10 {
	margin-top: 10px;
}

.topmargin_15 {
	margin-top: 15px;
}

.topmargin_20 {
	margin-top: 20px;
}

.topmargin_25 {
	margin-top: 25px;
}

.topmargin_30 {
	margin-top: 30px;
}

.topmargin_35 {
	margin-top: 35px;
}

.topmargin_40 {
	margin-top: 40px;
}

.topmargin_45 {
	margin-top: 45px;
}

.topmargin_50 {
	margin-top: 50px;
}

.topmargin_60 {
	margin-top: 60px;
}

.topmargin_80 {
	margin-top: 80px;
}

.bottommargin_0 {
	margin-bottom: 0 !important;
}

.bottommargin_5 {
	margin-bottom: 5px;
}

.bottommargin_10 {
	margin-bottom: 10px;
}

.bottommargin_15 {
	margin-bottom: 15px;
}

.bottommargin_20 {
	margin-bottom: 20px;
}

.bottommargin_25 {
	margin-bottom: 25px;
}

.bottommargin_30 {
	margin-bottom: 30px;
}

.bottommargin_40 {
	margin-bottom: 40px;
}

.bottommargin_50 {
	margin-bottom: 50px;
}

.bottommargin_60 {
	margin-bottom: 60px;
}

.leftmargin_10 {
	margin-left: 10px;
}

.leftmargin_20 {
	margin-left: 20px;
}

.leftmargin_30 {
	margin-left: 30px;
}

.leftmargin_40 {
	margin-left: 40px;
}

.leftmargin_50 {
	margin-left: 50px;
}

.leftmargin_60 {
	margin-left: 60px;
}

.rightmargin_10 {
	margin-right: 10px;
}

.rightmargin_20 {
	margin-right: 20px;
}

.rightmargin_30 {
	margin-right: 30px;
}

.rightmargin_40 {
	margin-right: 40px;
}

.rightmargin_50 {
	margin-right: 50px;
}

.rightmargin_60 {
	margin-right: 60px;
}

.padding_0 {
	padding: 0;
}

.padding_10 {
	padding: 10px;
}

.padding_20 {
	padding: 20px;
}

.padding_30 {
	padding: 30px;
}

.padding_40 {
	padding: 40px;
}

.toppadding_10 {
	padding-top: 10px;
}

.toppadding_20 {
	padding-top: 20px;
}

.toppadding_30 {
	padding-top: 30px;
}

.toppadding_40 {
	padding-top: 40px;
}

.toppadding_50 {
	padding-top: 50px;
}

.toppadding_60 {
	padding-top: 60px;
}

.bottompadding_10 {
	padding-bottom: 10px;
}

.bottompadding_20 {
	padding-bottom: 20px;
}

.bottompadding_30 {
	padding-bottom: 30px;
}

.bottompadding_40 {
	padding-bottom: 40px;
}

.bottompadding_50 {
	padding-bottom: 50px;
}

.bottompadding_60 {
	padding-bottom: 60px;
}

.leftpadding_5 {
	padding-left: 5px;
}

.leftpadding_10 {
	padding-left: 10px;
}

.leftpadding_20 {
	padding-left: 20px;
}

.leftpadding_30 {
	padding-left: 30px;
}

.leftpadding_40 {
	padding-left: 40px;
}

.leftpadding_50 {
	padding-left: 50px;
}

.leftpadding_60 {
	padding-left: 60px;
}

.rightpadding_5 {
	padding-right: 5px;
}

.rightpadding_10 {
	padding-right: 10px;
}

.rightpadding_20 {
	padding-right: 20px;
}

.rightpadding_25 {
	padding-right: 25px;
}

.rightpadding_30 {
	padding-right: 30px;
}

.rightpadding_40 {
	padding-right: 40px;
}

.rightpadding_50 {
	padding-right: 50px;
}

.rightpadding_60 {
	padding-right: 60px;
}

.fontsize_12 {
	font-size: 12px;
}

.fontsize_14 {
	font-size: 14px;
}

.fontsize_16 {
	font-size: 16px;
}

.fontsize_18 {
	font-size: 18px;
}

.fontsize_20 {
	font-size: 20px;
	line-height: 30px;
}

.fontsize_24 {
	font-size: 24px;
}

.fontsize_26 {
	font-size: 26px;
}

.fontsize_28 {
	font-size: 28px;
}

.fontsize_30 {
	font-size: 30px;
}

.fontsize_32 {
	font-size: 32px;
}

/*alignment*/
.display_table {
	display: table;
	min-width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	border-collapse: collapse;
}

.display_table_cell {
	display: table-cell;
	vertical-align: middle;
	float: none;
	overflow: hidden;
	zoom: 1;
	box-sizing: border-box;
}

@media (min-width: 768px) {
	.pull-sm-right {
		float: right;
	}

	.pull-sm-left {
		float: left;
	}

	.text-sm-right {
		text-align: right;
	}

	.text-sm-left {
		text-align: left;
	}

	.text-sm-center {
		text-align: center;
	}

	.display_table_sm {
		display: table;
		min-width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		border-collapse: collapse;
	}

	.display_table_cell_sm {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (min-width: 992px) {
	.pull-md-right {
		float: right;
	}

	.pull-md-left {
		float: left;
	}

	.text-md-right {
		text-align: right;
	}

	.text-md-left {
		text-align: left;
	}

	.text-md-center {
		text-align: center;
	}

	.display_inline_md {
		display: inline;
	}

	.display_table_md {
		display: table;
		min-width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		border-collapse: collapse;
	}

	.display_table_cell_md {
		display: table-cell;
		vertical-align: middle;
		float: none;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (min-width: 1200px) {
	.pull-lg-right {
		float: right;
	}

	.pull-lg-left {
		float: left;
	}

	.text-lg-right {
		text-align: right;
	}

	.text-lg-left {
		text-align: left;
	}

	.text-lg-center {
		text-align: center;
	}

	.display_table_lg {
		display: table;
		min-width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		border-collapse: collapse;
	}

	.display_table_cell_lg {
		display: table-cell;
		vertical-align: middle;
		float: none;
		overflow: hidden;
		zoom: 1;
		box-sizing: border-box;
	}
}

@media (max-width: 767px) {
	.text-xs-right {
		text-align: right;
	}

	.text-xs-left {
		text-align: left;
	}

	.text-xs-center {
		text-align: center;
	}
}

@media (max-width: 500px) {
	.hidden-xxs {
		display: none;
	}
}

.inline-dropdown {
	list-style: none;
	margin: 0;
}

.inline-dropdown > li {
	display: inline-block;
	margin: 0 -2px;
}

.small-text {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;
}

.small-text.small {
	font-size: 12px;
}

.hero-text {
	font-size: 40px;
	font-weight: bold;
	line-height: 0.8em;
}

@media (min-width: 992px) {
	.hero-text {
		font-size: 80px;
	}
}

.nowrap {
	white-space: nowrap;
}

.media.inline-block {
	display: inline-block;
}

.media.inline-block .media-body {
	width: auto;
}

.content-justify {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.content-justify.content-margins > * {
	margin-top: 10px;
	margin-bottom: 10px;
}

.content-justify.nowrap {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.flex-wrap {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.flex-wrap:before, .flex-wrap:after {
	display: none;
}

.row.flex-wrap.v-center {
	-ms-flex-align: center;
	align-items: center;
}

.row.flex-wrap.v-center-content {
	-ms-flex-align: stretch;
	align-items: stretch;
}

.row.flex-wrap.v-center-content > [class*='col-'] {
	display: -ms-flexbox;
	display: flex;
}

.row.flex-wrap.v-center-content > [class*='col-'] > * {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
}

.inline-content {
	margin-right: -9px;
	margin-left: -9px;
}

.inline-content > * {
	display: inline-block;
	vertical-align: middle;
	margin-right: 9px;
	margin-bottom: 0;
	margin-left: 9px;
}

.inline-content > *.media {
	margin-top: 10px;
	margin-bottom: 10px;
}

.inline-content.v-spacing > * {
	margin-bottom: 10px;
}

.inline-content.v-spacing > *:last-child {
	margin-right: 5px;
	margin-bottom: 10px;
}

@media (min-width: 1200px) {
	.inline-content.big-spacing {
		margin-right: -13px;
		margin-left: -13px;
	}

	.inline-content.big-spacing > * {
		margin-right: 13px;
		margin-left: 13px;
	}
}

@media (min-width: 1600px) {
	.inline-content.big-spacing {
		margin-right: -21px;
		margin-left: -21px;
	}

	.inline-content.big-spacing > * {
		margin-right: 21px;
		margin-left: 21px;
	}
}

.item-meta .inline-content > * {
	margin-right: 10px;
	margin-left: 10px;
}

.display-flex {
	display: -ms-flexbox;
	display: flex;
}

.v-center {
	-ms-flex-align: center;
	align-items: center;
}

.content-3lines-ellipsis {
	display: block;
	display: -webkit-box;
	font-size: 1rem;
	height: 5.625rem;
	-webkit-line-clamp: 3;
	line-height: 1.875em;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (min-width: 992px) {
	.md-content-3lines-ellipsis {
		display: block;
		display: -webkit-box;
		font-size: 1rem;
		height: 5.625rem;
		-webkit-line-clamp: 3;
		line-height: 1.875em;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.two-cols-text {
		column-count: 2;
	}

	.two-cols-text ul {
		margin: 0;
	}
}

/* media items - common side and vertical items */
.with_border .item-media,
.with_border .entry-thumbnail {
	margin: -1px;
	z-index: 2;
}

.with_border .item-media + header,
.with_border .entry-thumbnail + header {
	margin: 0 -1px;
}

.with_border.full-padding .item-media {
	margin: 0;
}

/* side and vertical items paddings */
.side-item .row {
	margin: 0;
}

.side-item .row [class*="col-"] {
	padding: 0;
	margin: 0;
}

.side-item.side-sm .item-content {
	padding-top: 30px;
}

@media (min-width: 768px) {
	.side-item.side-sm .row {
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 992px) {
	.side-item.side-md .row {
		display: -ms-flexbox;
		display: flex;
	}

	.side-item.side-md .row [class*="col-"] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}

@media (min-width: 1200px) {
	.side-item.side-md .col-lg-4 {
		width: 36%;
	}

	.side-item.side-md .col-lg-8 {
		width: 64%;
	}
}

@media (min-width: 992px) {
	.side-item.side-md.bottom_color_border:after {
		top: -1px;
		height: auto;
		width: 3px;
	}

	.side-item.side-md.bottom_color_border.left:after {
		left: auto;
	}
}

@media (min-width: 992px) {
	.side-item.side-md.right {
		display: -ms-flexbox;
		display: flex;
	}

	.side-item.side-md.right [class*="col-"]:first-child {
		-ms-flex-order: 2;
		order: 2;
	}

	.side-item.side-md.right [class*="col-"]:nth-child(2) {
		-ms-flex-order: 1;
		order: 1;
	}
}

/*-xs- side-item paddings and margins*/
@media (max-width: 767px) {
	.no-content-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content,
  .full-padding [class*='col-xs-'][class*='col-xs-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-xs-12 .item-content,
  .full-padding .col-xs-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-sm- side-item paddings and margins*/
@media (min-width: 768px) {
	.no-content-padding [class*='col-sm-'] .item-content,
  .full-padding [class*='col-sm-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content,
  .full-padding [class*='col-sm-'][class*='col-sm-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-sm-12 .item-content,
  .full-padding .col-sm-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-md- side-item paddings and margins*/
@media (min-width: 992px) {
	.no-content-padding [class*='col-md-'] .item-content,
  .full-padding [class*='col-md-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-md-'][class*='col-md-pull'] .item-content,
  .full-padding [class*='col-md-'][class*='col-md-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-md-12 .item-content,
  .full-padding .col-md-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

/*-lg- side-item paddings and margins*/
@media (min-width: 1200px) {
	.no-content-padding [class*='col-lg-'] .item-content,
  .full-padding [class*='col-lg-'] .item-content {
		padding-left: 30px;
	}

	.no-content-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content,
  .full-padding [class*='col-lg-'][class*='col-lg-pull'] .item-content {
		padding-left: 0;
		padding-right: 30px;
	}

	.no-content-padding .col-lg-12 .item-content,
  .full-padding .col-lg-12 .item-content {
		padding-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}
}

.vertical-item .item-content {
	position: relative;
}

.vertical-item.post .item-content {
	padding-top: 0;
}

.vertical-item.content-padding .item-content {
	padding: 30px 20px;
}

@media (min-width: 400px) {
	.vertical-item.content-padding .item-content {
		padding: 35px 40px;
	}
}

@media (min-width: 1200px) {
	.vertical-item.big-padding .item-content {
		padding: 55px;
	}
}

@media (min-width: 1200px) {
	.vertical-item.big-vertical-padding .item-content {
		padding-top: 55px;
		padding-bottom: 55px;
	}
}

.vertical-item.content-absolute {
	position: relative;
}

.vertical-item.content-absolute .item-content {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;
	z-index: 2;
}

@media (min-width: 400px) {
	.vertical-item.content-absolute .item-content {
		padding: 35px 40px;
	}
}

@media (min-width: 1200px) {
	.vertical-item.content-absolute .item-content.big-padding {
		padding: 55px 60px;
	}
}

.vertical-item.content-absolute .item-content.ds {
	background-color: rgba(31, 35, 43, 0.8);
}

.vertical-item.content-absolute.v-center .item-content {
	top: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
}

.vertical-item.content-absolute.hover-content .item-content {
	transform: scaleY(0);
	transition: transform 0.3s ease 0s;
}

.vertical-item.content-absolute.hover-content:hover .item-content {
	transform: scaleY(1);
}

.vertical-item + .item-title {
	padding: 10px 30px;
}

.vertical-item + .item-title h2, .vertical-item + .item-title h3, .vertical-item + .item-title h4 {
	margin: 0;
}

.side-item.content-padding .item-content {
	padding: 25px 20px;
}

@media (min-width: 400px) {
	.side-item.content-padding .item-content {
		padding: 35px 40px;
	}
}

@media (min-width: 1200px) {
	.side-item.big-padding .item-content {
		padding: 55px 60px;
	}
}

/* item meta */
.entry-meta {
	position: relative;
}

.item-media-wrap {
	position: relative;
}

.item-media-wrap .entry-meta {
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

@media (min-width: 1200px) {
	.item-media-wrap .entry-meta {
		position: absolute;
	}

	.ls .item-media-wrap .entry-meta {
		background-color: rgba(58, 61, 68, 0.8);
	}

	.ds .item-media-wrap .entry-meta {
		background-color: rgba(255, 255, 255, 0.8);
	}
}

.content-padding .item-media-wrap + .entry-meta,
.content-padding > .entry-meta:first-child,
.content-padding [class*="col-"] > .entry-meta:first-child,
.content-padding .item-media + .entry-meta,
.content-padding .item-media-wrap .entry-meta {
	padding: 12px 20px 11px;
}

@media (min-width: 400px) {
	.content-padding .item-media-wrap + .entry-meta,
  .content-padding > .entry-meta:first-child,
  .content-padding [class*="col-"] > .entry-meta:first-child,
  .content-padding .item-media + .entry-meta,
  .content-padding .item-media-wrap .entry-meta {
		padding: 12px 35px 11px;
	}
}

.content-padding .item-media-wrap + .entry-meta.no-avatar > *:first-child,
.content-padding > .entry-meta:first-child.no-avatar > *:first-child,
.content-padding [class*="col-"] > .entry-meta:first-child.no-avatar > *:first-child,
.content-padding .item-media + .entry-meta.no-avatar > *:first-child,
.content-padding .item-media-wrap .entry-meta.no-avatar > *:first-child {
	max-width: 100%;
}

@media (min-width: 1200px) {
	.big-padding .item-media-wrap + .entry-meta,
  .big-padding > .entry-meta:first-child,
  .big-padding [class*="col-"] > .entry-meta:first-child,
  .big-padding .item-media + .entry-meta,
  .big-padding .item-media-wrap .entry-meta {
		padding: 17px 55px 16px;
	}
}

@media (min-width: 1200px) {
	.big-padding.side-item .item-media-wrap + .entry-meta,
  .big-padding.side-item > .entry-meta:first-child,
  .big-padding.side-item [class*="col-"] > .entry-meta:first-child,
  .big-padding.side-item .item-media + .entry-meta,
  .big-padding.side-item .item-media-wrap .entry-meta {
		padding: 17px 55px 16px;
	}
}

/* item media links */
.item-media-wrap {
	line-height: 0;
}

.item-media-wrap > * {
	line-height: 1.625rem;
}

.item-media {
	position: relative;
	overflow: hidden;
}

.item-media img {
	width: auto;
}

.media-links {
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: transparent !important;
}

.media-links div {
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -25px;
	z-index: 4;
}

.media-links:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(31, 35, 43, 0.6);
	filter: alpha(opacity=0);
	opacity: 0;
	transition: all 0.2s linear;
}

.media-links.color-overlay:before {
	opacity: 1;
}

[class*="-item"]:hover .media-links.color-overlay:before {
	opacity: 0;
}

[class*="-item"]:hover .media-links:before {
	opacity: 1;
}

.media-links.no-overlay:before {
	display: none;
}

.media-links.inverse:before {
	opacity: 1;
}

[class*="-item"]:hover .media-links.inverse:before {
	opacity: 0;
}

.media-links a.abs-link {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
}

[class*="-item"]:hover .media-links a.abs-link {
	opacity: 1;
}

[class*="-item"] .links-wrap a,
[class*="-item"] .links-wrap > span {
	position: relative;
	width: 60px;
	height: 60px;
	line-height: 60px;
	top: -100px;
	border-radius: 30px;
	display: inline-block;
	z-index: 5;
	font-size: 16px;
	text-align: center;
	filter: alpha(opacity=0);
	opacity: 0;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*="-item"] .links-wrap a.p-view {
	color: #ffffff;
}

[class*="-item"] .links-wrap a.p-view:hover {
	color: #F15931;
}

[class*="-item"] .links-wrap a.p-link {
	color: #3a3d44;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-moz-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-ms-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-o-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
	-webkit-transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
}

[class*="-item"] .links-wrap a.p-link:hover {
	color: #F15931;
}

[class*="-item"] .links-wrap a:before {
	font-family: 'FontAwesome';
	content: "\f002";
}

[class*="-item"] .links-wrap a.p-link:before {
	content: "\f067";
	font-size: 14px;
}

[class*="-item"] .links-wrap > span {
	font-size: 24px;
}

.media-links .bottom-links {
	top: auto;
	bottom: -20px;
	opacity: 0;
	transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*="-item"]:hover .links-wrap a,
[class*="-item"]:hover .links-wrap > span {
	top: 0px;
	filter: alpha(opacity=100);
	opacity: 1;
}

[class*="-item"]:hover .media-links div {
	filter: alpha(opacity=100);
	opacity: 1;
}

[class*="-item"]:hover .media-links .bottom-links {
	bottom: 30px;
	opacity: 1;
}

.featured-post {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	text-align: center;
	border-radius: 0 0 100% 0;
}

.featured-post i {
	position: absolute;
	top: 12px;
	left: 12px;
	color: #fff;
	font-size: 14px;
}

@media (min-width: 1200px) {
	.featured-post {
		width: 60px;
		height: 60px;
	}

	.featured-post i {
		top: 19px;
		left: 19px;
	}
}

/* 
** Header 
*/
.header_white {
	color: #787878;
	background-color: #ffffff;
}

.header_white.affix {
	box-shadow: 0px 1px 15px 0 rgba(0, 0, 0, 0.07);
}

.header_white a.logo {
	color: #3a3d44;
}

.header_white + .ls.page_content {
	border-top: 1px solid #e4e4e4;
}

.ls:not(.ms) + .header_white > header:not(.ms) {
	border-top: 1px solid #e4e4e4;
}

.header_darkgrey {
	color: #ffffff;
	background-color: #3a3d44;
}

.header_darkgrey.affix {
	box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.3);
}

.header_darkgrey a[class*='socicon-'] {
	color: #ffffff;
}

.header_darkgrey .logo-meta,
.header_darkgrey .grey,
.header_darkgrey .black {
	color: #ffffff;
}

.header_darkgrey.with_top_border:before {
	background-color: rgba(255, 255, 255, 0.2);
}

.ds:not(.ms) + .header_darkgrey > header:not(.ms) {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.affix.page_header [class*="container"]:before {
	background-color: transparent !important;
}

.header_color {
	background-color: #F15931;
}

@media (min-width: 768px) {
	.header_transparent_wrap {
		position: relative;
		height: 0 !important;
	}

	.header_transparent_wrap .header_transparent:not(.affix).header_darkgrey {
		background-color: rgba(31, 35, 43, 0.75);
	}

	.transparent_wrapper {
		position: relative;
		height: 0 !important;
	}

	.transparent_wrapper > *:not(.page_header_wrapper) {
		position: relative;
		z-index: 3;
		background-color: transparent;
	}

	.transparent_wrapper .page_header:not(.affix) {
		background-color: transparent;
	}
}

/* logo */
.logo {
	display: inline-block;
	word-break: break-word;
	position: relative;
	z-index: 2;
	padding: 5px 0;
}

.logo img {
/*	max-width: 60px; */
}

@media (min-width: 1600px) {
	.logo img {
		max-width: 100px;
	}
}

.page_header_side .logo img {
	max-width: 220px;
}

.logo.logo_with_text {
	white-space: nowrap;
}

.ls .logo {
	color: #3a3d44;
}

.header_color .logo,
.header_darkgrey .logo {
	color: #ffffff;
}

.floating_logo .logo {
	float: left;
	margin-right: 30px;
}

.logo.vertical_logo {
	padding: 0;
	text-align: center;
}

.logo.vertical_logo img {
	display: block;
	margin: auto;
}

.logo.vertical_logo .logo_text {
	margin: 5px auto 0;
	text-align: center;
}

.logo.vertical_logo .logo_text .big {
	margin-bottom: 0;
}

@media (min-width: 992px) {
	.header_logo_center .logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.logo_text {
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	margin-top: -5px;
	margin-left: 15px;
	color: #3a3d44;
	text-align: left;
	font-size: 34px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
}

@media (min-width: 1600px) {
	.logo_text {
		margin-left: 24px;
	}

	.page_header_side .logo_text {
		margin-left: 15px;
	}
}

.logo_text small {
	font-size: 10px;
	font-weight: 400;
	text-transform: uppercase;
	display: block;
	font-family: 'Lato', sans-serif;
	letter-spacing: 0.02em;
	margin-top: 2px;
	margin-left: 2px;
}

.ds .logo_text,
.header_darkgrey .logo_text {
	color: #ffffff;
}

@media (min-width: 992px) {
	.header_logo_center .logo_wrapper {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

.logo-meta {
	line-height: 1;
	display: inline-block;
	margin: 11px 20px;
	vertical-align: middle;
	text-align: left;
}

.page_header_side .logo-meta {
	display: block !important;
}

.page_header {
	transition: background 0.2s ease-in-out 0s, margin 0.6s ease-in-out 0s;
	top: 0;
	left: 0;
	right: 0;
	position: relative;
	z-index: 1000;
	margin: 0;
	padding: 0;
	height: auto;
}

.page_header.affix {
	position: fixed;
	z-index: 1001;
}

.page_header.mobile-active {
	z-index: 1002;
}

.page_header.affix-bottom {
	top: 0 !important;
	position: fixed;
}

.page_header .search_modal_button {
	background-position: 50% 0;
	border-color: transparent;
}

.page_header [class*="display_table_cell"] {
	overflow: visible;
}

.page_header [class*="col-"] {
	min-height: 0;
}

@media (max-width: 1599px) {
	.page_header .header_right_buttons h5 {
		font-size: 16px;
	}

	.page_header .header_right_buttons .big {
		font-size: 24px;
	}
}

#search_modal {
	color: #ffffff;
}

#search_modal .search-form {
	margin: 0 0 0 60px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#search_modal a {
	color: #ffffff;
	opacity: 0.6;
	font-size: 12px;
	text-transform: uppercase;
}

#search_modal a:hover {
	color: #F15931;
	opacity: 1;
}

#search_modal .searchform-respond {
	width: 300px;
	margin: 20px auto;
}

#search_modal .form-control {
	border: none;
	background-color: transparent;
	height: 50px;
	color: #ffffff;
	width: 100%;
}

#search_modal .form-control:-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

#search_modal .form-control::-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
	opacity: 1;
}

#search_modal .form-control:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}

#search_modal .form-control::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}

#search_modal .theme_button {
	padding: 0;
}

#search_modal .close {
	padding: 14px 14px 13px;
	position: absolute;
	min-width: 0;
	color: #ffffff;
	text-shadow: none;
	opacity: 1;
}

.page_header .header-button {
	line-height: 60px;
}

.header_right_buttons .inline-list.small-padding > li {
	padding-top: 0;
	padding-bottom: 0;
}

/* header floats */
.page_header:before,
.page_header:after {
	content: '';
	display: block;
	clear: both;
}

.page_header {
	height: auto;
}

.header_left_logo {
	white-space: nowrap;
	padding: 0 15px;
	overflow: visible;
}

[class*="display_table_cell"] .header_left_logo {
	padding-left: 0;
}

.header_mainmenu {
	overflow: visible;
	width: 10000px;
	padding: 0 15px;
}

.header_right_buttons {
	white-space: nowrap;
	padding: 0 15px;
	text-align: right;
	overflow: visible;
}

.header_right_buttons.ls {
	background-color: transparent !important;
}

/*
** Side Header
*/
/* side header layout */
body {
	position: relative;
	left: 0;
}

body:after {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	visibility: hidden;
	opacity: 0;
}

body,
body:after,
.page_header_side {
	transition: all 0.6s ease-in-out;
}

body {
	transition-property: left, right;
}

body.active-side-header {
	left: 270px;
}

body.active-side-header .page_header_side {
	left: 0;
}

body.active-side-header:after {
	visibility: visible;
	opacity: 1;
}

.page_header_side {
	z-index: 1002;
	position: fixed;
	width: 270px;
	left: -270px;
	top: 0;
	bottom: 0;
}

.page_header_side.active-slide-side-header {
	box-shadow: 0px 0px 4px 3px rgba(50, 50, 50, 0.05);
}

.page_header_side ~ * .toggle_menu {
	display: none;
}

.page_header_side .widget {
	margin-top: 20px;
	padding-left: 30px;
	padding-right: 30px;
}

.page_header_side .logo-meta {
	margin: 30px;
}

.page_header_side .social-icons {
	display: block;
	margin: 20px 30px 0;
}

.side_header_inner {
	padding: 10px 0 60px;
}

.toggle_menu_side {
	position: absolute;
	top: 5px;
	right: -65px;
	background-color: #ffffff;
	width: 60px;
	height: 60px;
	cursor: pointer;
	box-shadow: 0 0 4px 2px rgba(50, 50, 50, 0.05);
	border-radius: 0;
	z-index: 2;
}

.toggle_menu_side:before,
.toggle_menu_side span,
.toggle_menu_side span:before,
.toggle_menu_side span:after {
	content: '';
	display: block;
	width: 24px;
	position: absolute;
	background-color: #3a3d44;
	left: 0;
	transform: rotate(0deg);
	transition: .25s ease-in-out;
	height: 2px;
}

.toggle_menu_side:before,
.toggle_menu_side span {
	left: 18px;
	top: 50%;
}

.toggle_menu_side span:before {
	opacity: 1;
	top: -6px;
}

.toggle_menu_side span:after {
	opacity: 1;
	top: 6px;
}

.active-slide-side-header .toggle_menu_side:before,
.active-side-header .toggle_menu_side:before {
	transform: rotate(45deg);
}

.active-slide-side-header .toggle_menu_side span,
.active-side-header .toggle_menu_side span {
	transform: rotate(-45deg);
}

.active-slide-side-header .toggle_menu_side span:before, .active-slide-side-header .toggle_menu_side span:after,
.active-side-header .toggle_menu_side span:before,
.active-side-header .toggle_menu_side span:after {
	opacity: 0;
}

.ds .toggle_menu_side {
	background-color: #3d3d47;
}

.ds .toggle_menu_side span,
.ds .toggle_menu_side span:before,
.ds .toggle_menu_side span:after, .ds .toggle_menu_side:before {
	background-color: #ffffff;
}

.bs .toggle_menu_side {
	background-color: #00202b;
}

@media (max-width: 350px) {
	.toggle_menu_side {
		top: 0;
		right: -50px;
	}
}

.page_header_side.page_header_side_sticked .toggle_menu_side {
	transition: 0.6s ease-in-out;
	right: -65px;
	top: 5px;
}

.admin .page_header_side.page_header_side_sticked .toggle_menu_side {
	right: -60px;
	top: 0;
}

.admin .page_header_side.page_header_side_sticked.active-slide-side-header .toggle_menu_side {
	top: 0;
	right: 0;
	box-shadow: none;
}

.page_header_side.active-slide-side-header {
	left: 0;
}

.container {
	max-width: 100%;
}

@media (max-width: 767px) {
	.container {
		max-width: 500px;
	}
}

@media (min-width: 600px) {
	.page_header_side.page_header_side_sticked.active-slide-side-header {
		left: 0;
	}

	.page_header_side_sticked ~ * {
		transition: margin 0.6s ease-in-out;
	}

	.page_header_side_sticked ~ div .page_header {
		padding-left: 35px;
	}

	.page_header_side_sticked.active-slide-side-header ~ div .page_header {
		padding-left: 0;
		margin-left: 270px;
	}

	.page_header_side_sticked.active-slide-side-header ~ * {
		margin-left: 270px;
	}

	.boxed .page_header_side_sticked.active-slide-side-header ~ *,
  .boxed .page_header_side_sticked.active-slide-side-header ~ div .page_header {
		margin-left: 0;
	}

	.page_header_side_sticked ~ .page_header_wrapper {
		margin-left: 0;
	}
}

.page_header_side.header_side_right {
	left: auto;
	right: -270px;
}

.page_header_side.header_side_right.active-slide-side-header {
	right: 0;
}

.page_header_side.header_side_right .toggle_menu_side {
	right: auto;
	left: -65px;
}

@media (max-width: 350px) {
	.page_header_side.header_side_right .toggle_menu_side {
		left: -50px;
	}
}

body.active-side-header.slide-right {
	left: -270px;
}

body.active-side-header.slide-right .page_header_side {
	left: auto;
	right: 0;
}

/* side clickable menu styles */
.menu-side-click {
	padding: 0;
	margin: 19px 0;
}

.menu-side-click i {
	width: 1em;
	text-align: center;
	margin-right: 10px;
}

.menu-side-click .activate_submenu {
	cursor: pointer;
	position: absolute;
	right: 30px;
	top: 7px;
	font-size: 11px;
	color: #3a3d44;
	text-align: center;
	width: 10px;
	opacity: 0.9;
	transform: rotate(-90deg);
	transition: .25s ease-in-out;
}

.menu-side-click .activate_submenu:before {
	font-family: 'FontAwesome';
	content: "\f0dd";
}

.ds .menu-side-click .activate_submenu {
	color: #ffffff;
}

.menu-side-click .active-submenu > .activate_submenu {
	transform: rotate(0deg);
}

.menu-side-click li {
	position: relative;
	list-style: none;
}

.menu-side-click > li {
	padding: 0 40px 0 27px;
	font-weight: 400;
	background-color: transparent;
	border-left: 3px solid transparent;
	transition: 0.3s ease-in-out;
}

.menu-side-click > li a {
	display: block;
	padding: 7px 0;
}

.menu-side-click > li.active-submenu {
	padding-top: 19px;
	padding-bottom: 10px;
	border-color: #F15931;
}

.bs .menu-side-click > li.active-submenu {
	background-color: #00202b;
}

.ls .menu-side-click > li.active-submenu {
	background-color: #f2f2f2;
}

.menu-side-click > li.active-submenu > .activate_submenu {
	top: 22px;
}

.menu-side-click > li li {
	padding-right: 40px;
	font-weight: 500;
}

.menu-side-click li.active > a {
	color: #F15931;
}

.menu-side-click ul {
	opacity: 0;
	height: 0;
	padding-left: 26px;
}

.menu-side-click ul ul {
	padding-left: 10px;
	font-size: 95%;
}

.menu-side-click .active-submenu > div.mega-menu {
	height: auto;
}

.menu-side-click .active-submenu > ul,
.menu-side-click .active-submenu > div.mega-menu > ul {
	opacity: 1;
	height: auto;
}

.menu-side-click ul, .menu-side-click div.mega-menu {
	margin-right: -40px;
	margin-top: 0;
	margin-bottom: 0;
	height: 0;
	overflow: hidden;
	transition: opacity 0.6s, margin-top 0.4s, margin-bottom 0.4s, height 0.3s ease-in-out;
}

.menu-side-click ul li.sfHover > .mega-menu ul {
	display: block;
	height: 100%;
}

.menu-side-click div.mega-menu > ul {
	margin-right: 0;
	height: auto;
}

.side_header_social {
	margin: 30px;
	text-align: center;
}

/* sidebar scroll plugin */
.scroll-wrapper {
	overflow: hidden;
	padding: 0;
	position: relative;
	height: 100%;
}

.scroll-wrapper > .scroll-content {
	border: none;
	box-sizing: content-box;
	height: auto;
	left: 0;
	margin: 0;
	max-height: none;
	max-width: none;
	overflow: scroll;
	padding: 0;
	position: relative;
	top: 0;
	width: auto;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
	height: 0;
	width: 0;
}

.scroll-wrapper.scroll--rtl {
	direction: rtl;
}

.scroll-element {
	box-sizing: content-box;
	display: none;
}

.scroll-element div {
	box-sizing: content-box;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
	cursor: default;
}

.scroll-element.scroll-x.scroll-scrollx_visible, .scroll-element.scroll-y.scroll-scrolly_visible {
	display: block;
}

.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div {
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 10;
}

.scrollbar-macosx > .scroll-element div {
	display: block;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
}

.scrollbar-macosx > .scroll-element .scroll-element_track {
	display: none;
}

.scrollbar-macosx > .scroll-element .scroll-bar {
	background-color: rgba(127, 127, 127, 0.4);
	display: block;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	border-radius: 1px;
	transition: opacity 0.2s linear;
}

.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	filter: alpha(opacity=70);
	opacity: 0.7;
}

.scrollbar-macosx > .scroll-element.scroll-x {
	bottom: 0px;
	height: 0px;
	left: 0;
	min-width: 100%;
	overflow: visible;
	width: 100%;
}

.scrollbar-macosx > .scroll-element.scroll-y {
	height: 100%;
	min-height: 100%;
	right: 0px;
	top: 0;
	width: 0px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
	height: 7px;
	min-width: 10px;
	top: -9px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
	left: -9px;
	min-height: 10px;
	width: 7px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
	left: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
	left: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
	top: 2px;
}

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
	top: -4px;
}

.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
	left: -11px;
}

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
	top: -11px;
}

/*
** Light Section - .ls
*/
.ls {
	background-color: #ffffff;
	color: #787878;
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6 {
	color: #1f232b;
}

.ls h1 a,
.ls h2 a,
.ls h3 a,
.ls h4 a,
.ls h5 a,
.ls h6 a {
	color: inherit;
}

.ls h1 a:hover,
.ls h2 a:hover,
.ls h3 a:hover,
.ls h4 a:hover,
.ls h5 a:hover,
.ls h6 a:hover {
	color: #F15931;
}

.ls .main_bg_color,
.ls .main_bg_color2,
.ls .main_bg_color3,
.ls .main_bg_color4,
.ls .success_bg_color,
.ls .info_bg_color,
.ls .warning_bg_color,
.ls .danger_bg_color,
.ls .danger_bg_color,
.ls .transp_gradient_bg,
.ls .gradient_bg {
	color: #ffffff;
}

.ls .main_bg_color h1,
.ls .main_bg_color h2,
.ls .main_bg_color h3,
.ls .main_bg_color h4,
.ls .main_bg_color h5,
.ls .main_bg_color h6,
.ls .main_bg_color2 h1,
.ls .main_bg_color2 h2,
.ls .main_bg_color2 h3,
.ls .main_bg_color2 h4,
.ls .main_bg_color2 h5,
.ls .main_bg_color2 h6,
.ls .main_bg_color3 h1,
.ls .main_bg_color3 h2,
.ls .main_bg_color3 h3,
.ls .main_bg_color3 h4,
.ls .main_bg_color3 h5,
.ls .main_bg_color3 h6,
.ls .main_bg_color4 h1,
.ls .main_bg_color4 h2,
.ls .main_bg_color4 h3,
.ls .main_bg_color4 h4,
.ls .main_bg_color4 h5,
.ls .main_bg_color4 h6,
.ls .success_bg_color h1,
.ls .success_bg_color h2,
.ls .success_bg_color h3,
.ls .success_bg_color h4,
.ls .success_bg_color h5,
.ls .success_bg_color h6,
.ls .info_bg_color h1,
.ls .info_bg_color h2,
.ls .info_bg_color h3,
.ls .info_bg_color h4,
.ls .info_bg_color h5,
.ls .info_bg_color h6,
.ls .warning_bg_color h1,
.ls .warning_bg_color h2,
.ls .warning_bg_color h3,
.ls .warning_bg_color h4,
.ls .warning_bg_color h5,
.ls .warning_bg_color h6,
.ls .danger_bg_color h1,
.ls .danger_bg_color h2,
.ls .danger_bg_color h3,
.ls .danger_bg_color h4,
.ls .danger_bg_color h5,
.ls .danger_bg_color h6,
.ls .danger_bg_color h1,
.ls .danger_bg_color h2,
.ls .danger_bg_color h3,
.ls .danger_bg_color h4,
.ls .danger_bg_color h5,
.ls .danger_bg_color h6,
.ls .transp_gradient_bg h1,
.ls .transp_gradient_bg h2,
.ls .transp_gradient_bg h3,
.ls .transp_gradient_bg h4,
.ls .transp_gradient_bg h5,
.ls .transp_gradient_bg h6,
.ls .gradient_bg h1,
.ls .gradient_bg h2,
.ls .gradient_bg h3,
.ls .gradient_bg h4,
.ls .gradient_bg h5,
.ls .gradient_bg h6 {
	color: #ffffff;
}

.ls .main_bg_color h1 a:hover, .ls .main_bg_color h1 a:active, .ls .main_bg_color h1 a:focus,
.ls .main_bg_color h2 a:hover,
.ls .main_bg_color h2 a:active,
.ls .main_bg_color h2 a:focus,
.ls .main_bg_color h3 a:hover,
.ls .main_bg_color h3 a:active,
.ls .main_bg_color h3 a:focus,
.ls .main_bg_color h4 a:hover,
.ls .main_bg_color h4 a:active,
.ls .main_bg_color h4 a:focus,
.ls .main_bg_color h5 a:hover,
.ls .main_bg_color h5 a:active,
.ls .main_bg_color h5 a:focus,
.ls .main_bg_color h6 a:hover,
.ls .main_bg_color h6 a:active,
.ls .main_bg_color h6 a:focus,
.ls .main_bg_color2 h1 a:hover,
.ls .main_bg_color2 h1 a:active,
.ls .main_bg_color2 h1 a:focus,
.ls .main_bg_color2 h2 a:hover,
.ls .main_bg_color2 h2 a:active,
.ls .main_bg_color2 h2 a:focus,
.ls .main_bg_color2 h3 a:hover,
.ls .main_bg_color2 h3 a:active,
.ls .main_bg_color2 h3 a:focus,
.ls .main_bg_color2 h4 a:hover,
.ls .main_bg_color2 h4 a:active,
.ls .main_bg_color2 h4 a:focus,
.ls .main_bg_color2 h5 a:hover,
.ls .main_bg_color2 h5 a:active,
.ls .main_bg_color2 h5 a:focus,
.ls .main_bg_color2 h6 a:hover,
.ls .main_bg_color2 h6 a:active,
.ls .main_bg_color2 h6 a:focus,
.ls .main_bg_color3 h1 a:hover,
.ls .main_bg_color3 h1 a:active,
.ls .main_bg_color3 h1 a:focus,
.ls .main_bg_color3 h2 a:hover,
.ls .main_bg_color3 h2 a:active,
.ls .main_bg_color3 h2 a:focus,
.ls .main_bg_color3 h3 a:hover,
.ls .main_bg_color3 h3 a:active,
.ls .main_bg_color3 h3 a:focus,
.ls .main_bg_color3 h4 a:hover,
.ls .main_bg_color3 h4 a:active,
.ls .main_bg_color3 h4 a:focus,
.ls .main_bg_color3 h5 a:hover,
.ls .main_bg_color3 h5 a:active,
.ls .main_bg_color3 h5 a:focus,
.ls .main_bg_color3 h6 a:hover,
.ls .main_bg_color3 h6 a:active,
.ls .main_bg_color3 h6 a:focus,
.ls .main_bg_color4 h1 a:hover,
.ls .main_bg_color4 h1 a:active,
.ls .main_bg_color4 h1 a:focus,
.ls .main_bg_color4 h2 a:hover,
.ls .main_bg_color4 h2 a:active,
.ls .main_bg_color4 h2 a:focus,
.ls .main_bg_color4 h3 a:hover,
.ls .main_bg_color4 h3 a:active,
.ls .main_bg_color4 h3 a:focus,
.ls .main_bg_color4 h4 a:hover,
.ls .main_bg_color4 h4 a:active,
.ls .main_bg_color4 h4 a:focus,
.ls .main_bg_color4 h5 a:hover,
.ls .main_bg_color4 h5 a:active,
.ls .main_bg_color4 h5 a:focus,
.ls .main_bg_color4 h6 a:hover,
.ls .main_bg_color4 h6 a:active,
.ls .main_bg_color4 h6 a:focus,
.ls .success_bg_color h1 a:hover,
.ls .success_bg_color h1 a:active,
.ls .success_bg_color h1 a:focus,
.ls .success_bg_color h2 a:hover,
.ls .success_bg_color h2 a:active,
.ls .success_bg_color h2 a:focus,
.ls .success_bg_color h3 a:hover,
.ls .success_bg_color h3 a:active,
.ls .success_bg_color h3 a:focus,
.ls .success_bg_color h4 a:hover,
.ls .success_bg_color h4 a:active,
.ls .success_bg_color h4 a:focus,
.ls .success_bg_color h5 a:hover,
.ls .success_bg_color h5 a:active,
.ls .success_bg_color h5 a:focus,
.ls .success_bg_color h6 a:hover,
.ls .success_bg_color h6 a:active,
.ls .success_bg_color h6 a:focus,
.ls .info_bg_color h1 a:hover,
.ls .info_bg_color h1 a:active,
.ls .info_bg_color h1 a:focus,
.ls .info_bg_color h2 a:hover,
.ls .info_bg_color h2 a:active,
.ls .info_bg_color h2 a:focus,
.ls .info_bg_color h3 a:hover,
.ls .info_bg_color h3 a:active,
.ls .info_bg_color h3 a:focus,
.ls .info_bg_color h4 a:hover,
.ls .info_bg_color h4 a:active,
.ls .info_bg_color h4 a:focus,
.ls .info_bg_color h5 a:hover,
.ls .info_bg_color h5 a:active,
.ls .info_bg_color h5 a:focus,
.ls .info_bg_color h6 a:hover,
.ls .info_bg_color h6 a:active,
.ls .info_bg_color h6 a:focus,
.ls .warning_bg_color h1 a:hover,
.ls .warning_bg_color h1 a:active,
.ls .warning_bg_color h1 a:focus,
.ls .warning_bg_color h2 a:hover,
.ls .warning_bg_color h2 a:active,
.ls .warning_bg_color h2 a:focus,
.ls .warning_bg_color h3 a:hover,
.ls .warning_bg_color h3 a:active,
.ls .warning_bg_color h3 a:focus,
.ls .warning_bg_color h4 a:hover,
.ls .warning_bg_color h4 a:active,
.ls .warning_bg_color h4 a:focus,
.ls .warning_bg_color h5 a:hover,
.ls .warning_bg_color h5 a:active,
.ls .warning_bg_color h5 a:focus,
.ls .warning_bg_color h6 a:hover,
.ls .warning_bg_color h6 a:active,
.ls .warning_bg_color h6 a:focus,
.ls .danger_bg_color h1 a:hover,
.ls .danger_bg_color h1 a:active,
.ls .danger_bg_color h1 a:focus,
.ls .danger_bg_color h2 a:hover,
.ls .danger_bg_color h2 a:active,
.ls .danger_bg_color h2 a:focus,
.ls .danger_bg_color h3 a:hover,
.ls .danger_bg_color h3 a:active,
.ls .danger_bg_color h3 a:focus,
.ls .danger_bg_color h4 a:hover,
.ls .danger_bg_color h4 a:active,
.ls .danger_bg_color h4 a:focus,
.ls .danger_bg_color h5 a:hover,
.ls .danger_bg_color h5 a:active,
.ls .danger_bg_color h5 a:focus,
.ls .danger_bg_color h6 a:hover,
.ls .danger_bg_color h6 a:active,
.ls .danger_bg_color h6 a:focus,
.ls .danger_bg_color h1 a:hover,
.ls .danger_bg_color h1 a:active,
.ls .danger_bg_color h1 a:focus,
.ls .danger_bg_color h2 a:hover,
.ls .danger_bg_color h2 a:active,
.ls .danger_bg_color h2 a:focus,
.ls .danger_bg_color h3 a:hover,
.ls .danger_bg_color h3 a:active,
.ls .danger_bg_color h3 a:focus,
.ls .danger_bg_color h4 a:hover,
.ls .danger_bg_color h4 a:active,
.ls .danger_bg_color h4 a:focus,
.ls .danger_bg_color h5 a:hover,
.ls .danger_bg_color h5 a:active,
.ls .danger_bg_color h5 a:focus,
.ls .danger_bg_color h6 a:hover,
.ls .danger_bg_color h6 a:active,
.ls .danger_bg_color h6 a:focus,
.ls .transp_gradient_bg h1 a:hover,
.ls .transp_gradient_bg h1 a:active,
.ls .transp_gradient_bg h1 a:focus,
.ls .transp_gradient_bg h2 a:hover,
.ls .transp_gradient_bg h2 a:active,
.ls .transp_gradient_bg h2 a:focus,
.ls .transp_gradient_bg h3 a:hover,
.ls .transp_gradient_bg h3 a:active,
.ls .transp_gradient_bg h3 a:focus,
.ls .transp_gradient_bg h4 a:hover,
.ls .transp_gradient_bg h4 a:active,
.ls .transp_gradient_bg h4 a:focus,
.ls .transp_gradient_bg h5 a:hover,
.ls .transp_gradient_bg h5 a:active,
.ls .transp_gradient_bg h5 a:focus,
.ls .transp_gradient_bg h6 a:hover,
.ls .transp_gradient_bg h6 a:active,
.ls .transp_gradient_bg h6 a:focus,
.ls .gradient_bg h1 a:hover,
.ls .gradient_bg h1 a:active,
.ls .gradient_bg h1 a:focus,
.ls .gradient_bg h2 a:hover,
.ls .gradient_bg h2 a:active,
.ls .gradient_bg h2 a:focus,
.ls .gradient_bg h3 a:hover,
.ls .gradient_bg h3 a:active,
.ls .gradient_bg h3 a:focus,
.ls .gradient_bg h4 a:hover,
.ls .gradient_bg h4 a:active,
.ls .gradient_bg h4 a:focus,
.ls .gradient_bg h5 a:hover,
.ls .gradient_bg h5 a:active,
.ls .gradient_bg h5 a:focus,
.ls .gradient_bg h6 a:hover,
.ls .gradient_bg h6 a:active,
.ls .gradient_bg h6 a:focus {
	color: #3a3d44;
}

.ls [class*='color_bg'] .highlight {
	color: #ffffff;
}

.ls .hoverable-banner.gradient_bg_color:hover .highlight2 {
	color: #F15931;
}

.ls .light_bg_color {
	color: #3a3d44;
}

.ls a {
	color: #F15931;
}

.ls a:hover {
	color: #3a3d44;
}

.ls .grey {
	color: #3a3d44;
}

.ls .highlight,
.ls .highlight1 {
	color: #F15931;
	border-color: #F15931;
}

.ls .highlight2 {
	color: #F15931;
	border-color: #F15931;
}

.ls .greylinks a {
	color: #787878;
}

.ls .greylinks a:hover, .ls .greylinks a:focus, .ls .greylinks a:active {
	color: #F15931;
}

.ls .greylinks a.social-icon:not(:hover) {
	color: #bfbfbf;
}

.ls .greylinks.color2 a:hover, .ls .greylinks.color2 a:focus, .ls .greylinks.color2 a:active {
	color: #F15931;
}

.ls .greylinks.color3 a:hover, .ls .greylinks.color3 a:focus, .ls .greylinks.color3 a:active {
	color: #fcb315;
}

.ls .greylinks.color4 a:hover, .ls .greylinks.color4 a:focus, .ls .greylinks.color4 a:active {
	color: #03a8cb;
}

.ls .darklinks a {
	color: #3a3d44;
}

.ls .darklinks a:hover {
	color: #F15931;
}

.ls .color2.darklinks a:hover {
	color: #F15931;
}

.ls .color3.darklinks a:hover {
	color: #fcb315;
}

.ls .color4.darklinks a:hover {
	color: #03a8cb;
}

.ls .highlightlinks a,
.ls .highlightlink {
	color: #F15931;
}

.ls .highlightlinks a:hover,
.ls .highlightlink:hover {
	color: #3a3d44;
}

.ls .highlight2links a,
.ls .highlight2link {
	color: #F15931;
}

.ls .highlight2links a:hover,
.ls .highlight2link:hover {
	color: #3a3d44;
}

.ls .highlight3links a,
.ls .highlight3link {
	color: #fcb315;
}

.ls .highlight3links a:hover,
.ls .highlight3link:hover {
	color: #3a3d44;
}

.ls .highlight4links a,
.ls .highlight4link {
	color: #03a8cb;
}

.ls .highlight4links a:hover,
.ls .highlight4link:hover {
	color: #3a3d44;
}

.ls .colorlinks a {
	color: #F15931;
}

.ls .colorlinks a:hover {
	color: #F15931;
}

.ls .breadcrumb > li + li:before {
	color: #787878;
}

.ls .breadcrumb > .active {
	color: #3a3d44;
}

.ls .nav-unstyled .active a {
	color: #F15931;
}

.ls .nav-unstyled.color2 .active a {
	color: #F15931;
}

.ls .nav-unstyled.color3 .active a {
	color: #fcb315;
}

.ls .nav-unstyled.color4 .active a {
	color: #03a8cb;
}

.ls .theme_buttons a,
.ls .theme_button {
	color: #ffffff;
}

.ls .theme_buttons a:hover, .ls .theme_buttons a:focus, .ls .theme_buttons a:active,
.ls .theme_button:hover,
.ls .theme_button:focus,
.ls .theme_button:active {
	color: #F15931;
}

.ls .theme_buttons.inverse a,
.ls .theme_button.inverse {
	color: #3a3d44;
}

.ls .theme_buttons.inverse a:hover, .ls .theme_buttons.inverse a:focus, .ls .theme_buttons.inverse a:active,
.ls .theme_button.inverse:hover,
.ls .theme_button.inverse:focus,
.ls .theme_button.inverse:active {
	color: #F15931;
}

.ls .theme_buttons.color1.iverse a,
.ls .theme_button.color1.inverse {
	color: #F15931;
}

.ls .theme_buttons.color2.iverse a,
.ls .theme_button.color2.inverse {
	color: #F15931;
}

.ls .theme_buttons.color3.iverse a,
.ls .theme_button.color3.inverse {
	color: #fcb315;
}

.ls .theme_buttons.color4.iverse a,
.ls .theme_button.color4.inverse {
	color: #03a8cb;
}

.ls .theme_buttons[class*="color"],
.ls .theme_button[class*="color"] {
	color: #ffffff;
}

.ls .theme_buttons[class*="color"]:hover, .ls .theme_buttons[class*="color"]:focus, .ls .theme_buttons[class*="color"]:active,
.ls .theme_button[class*="color"]:hover,
.ls .theme_button[class*="color"]:focus,
.ls .theme_button[class*="color"]:active {
	color: #3a3d44;
}

.ls .theme_button.no_bg_button {
	background-color: transparent !important;
	color: #787878;
}

.ls .theme_button.no_bg_button:hover, .ls .theme_button.no_bg_button:focus, .ls .theme_button.no_bg_button:active {
	color: #F15931;
}

.ls .theme_button.no_bg_button.color1 {
	color: #F15931;
}

.ls .theme_button.no_bg_button.color2 {
	color: #F15931;
}

.ls .theme_button.no_bg_button.color3 {
	color: #fcb315;
}

.ls .theme_button.no_bg_button.color4 {
	color: #03a8cb;
}

.ls .theme_button.no_bg_button[class*="color"]:hover, .ls .theme_button.no_bg_button[class*="color"]:focus, .ls .theme_button.no_bg_button[class*="color"]:active {
	color: #3a3d44;
}

.ls:not(.ms) + .page_copyright.ls {
	background-color: #f7f7f7;
}

.ls .select-group:hover select + .theme_button,
.ls .select-group:hover select + i {
	color: #F15931;
}

/*
** Grey Section - light section, muted section - .ls.ms
*/
.ls.ms {
	background-color: #f7f7f7;
}

.ls.ms.dark {
	background-color: #eaeaea;
}

.ls.ms .with_background {
	background-color: #ffffff;
}

.ls.ms .theme_bg .with_background {
	background-color: #f2f2f2;
}

.ls.ms .main_bg_color {
	background-color: #F15931;
}

.ls.ms .main_bg_color2 {
	background-color: #F15931;
}

.ls.ms hr {
	background-color: rgba(120, 120, 120, 0.2);
}

.ls.ms .with_background input[type="text"],
.ls.ms .with_background input[type="email"],
.ls.ms .with_background input[type="url"],
.ls.ms .with_background input[type="password"],
.ls.ms .with_background input[type="search"],
.ls.ms .with_background input[type="tel"],
.ls.ms .with_background input[type="number"],
.ls.ms .with_background textarea,
.ls.ms .with_background select,
.ls.ms .with_background .form-control {
	border-color: rgba(120, 120, 120, 0.2);
}

.ls.ms td,
.ls.ms th,
.ls.ms .with_border {
	border-color: #d7d7d7;
}

.ls.ms .bottom_color_border {
	border-bottom-color: #F15931;
}

.ls.ms .bottom_color2_border {
	border-bottom-color: #F15931;
}

.ls.ms .bottom_color3_border {
	border-bottom-color: #fcb315;
}

.ls.ms .bottom_color4_border {
	border-bottom-color: #03a8cb;
}

.ls.ms .tab-content {
	background-color: #ffffff;
}

.ls.ms .tab-content .with_border {
	border-color: #e6e6e6;
}

.ls.ms .tab-content.no-border {
	background-color: transparent;
}

.ls.ms .tab-content .form-control {
	background-color: #f7f7f7;
}

.ls.ms .pagination > li > a,
.ls.ms .pagination > li > span,
.ls.ms .pagination > li > a,
.ls.ms .pagination > li > span {
	background-color: #fff;
}

.ls.ms .pagination > li > a:hover,
.ls.ms .pagination > li > span:hover,
.ls.ms .pagination > li > a:focus,
.ls.ms .pagination > li > span:focus,
.ls.ms .pagination > .active > a,
.ls.ms .pagination > .active > span,
.ls.ms .pagination > .active > a:hover,
.ls.ms .pagination > .active > span:hover,
.ls.ms .pagination > .active > a:focus,
.ls.ms .pagination > .active > span:focus {
	background: #F15931;
	border-color: #F15931;
}

.ls.ms .widget_calendar tbody {
	background-color: #ffffff;
}

.ls.ms .owl-nav > div {
	background-color: #fff;
}

/*
** Dark Section and Dark Muted sections - .ds and .ds.ms
*/
/* darkgrey section - .ds */
.ds {
	background-color: #3a3d44;
	color: #e0e0e1;
}

.ds.parallax .theme-divider, .ds.background_cover .theme-divider {
	background-color: #fff;
}

/* dark section - .ds.ms */
.ds.ms {
	background-color: #1f232b;
}

/* dark section blue - .ds.bs */
.ds.bs {
	background-color: #00202b;
}

.ls .inverse-bg {
	background-color: #3a3d44;
}

.ds {
  /* shop */
}

.ds h1,
.ds h2,
.ds h3,
.ds h4,
.ds h5,
.ds h6 {
	color: #ffffff;
}

.ds h1 a,
.ds h2 a,
.ds h3 a,
.ds h4 a,
.ds h5 a,
.ds h6 a {
	color: inherit;
}

.ds h1 a:hover,
.ds h2 a:hover,
.ds h3 a:hover,
.ds h4 a:hover,
.ds h5 a:hover,
.ds h6 a:hover {
	color: #F15931;
}

.ds .main_bg_color,
.ds .main_bg_color2,
.ds .success_bg_color,
.ds .info_bg_color,
.ds .warning_bg_color,
.ds .danger_bg_color,
.ds .danger_bg_color,
.ds .transp_gradient_bg,
.ds .gradient_bg {
	color: #ffffff;
}

.ds .main_bg_color h1,
.ds .main_bg_color h2,
.ds .main_bg_color h3,
.ds .main_bg_color h4,
.ds .main_bg_color h5,
.ds .main_bg_color h6,
.ds .main_bg_color2 h1,
.ds .main_bg_color2 h2,
.ds .main_bg_color2 h3,
.ds .main_bg_color2 h4,
.ds .main_bg_color2 h5,
.ds .main_bg_color2 h6,
.ds .success_bg_color h1,
.ds .success_bg_color h2,
.ds .success_bg_color h3,
.ds .success_bg_color h4,
.ds .success_bg_color h5,
.ds .success_bg_color h6,
.ds .info_bg_color h1,
.ds .info_bg_color h2,
.ds .info_bg_color h3,
.ds .info_bg_color h4,
.ds .info_bg_color h5,
.ds .info_bg_color h6,
.ds .warning_bg_color h1,
.ds .warning_bg_color h2,
.ds .warning_bg_color h3,
.ds .warning_bg_color h4,
.ds .warning_bg_color h5,
.ds .warning_bg_color h6,
.ds .danger_bg_color h1,
.ds .danger_bg_color h2,
.ds .danger_bg_color h3,
.ds .danger_bg_color h4,
.ds .danger_bg_color h5,
.ds .danger_bg_color h6,
.ds .danger_bg_color h1,
.ds .danger_bg_color h2,
.ds .danger_bg_color h3,
.ds .danger_bg_color h4,
.ds .danger_bg_color h5,
.ds .danger_bg_color h6,
.ds .transp_gradient_bg h1,
.ds .transp_gradient_bg h2,
.ds .transp_gradient_bg h3,
.ds .transp_gradient_bg h4,
.ds .transp_gradient_bg h5,
.ds .transp_gradient_bg h6,
.ds .gradient_bg h1,
.ds .gradient_bg h2,
.ds .gradient_bg h3,
.ds .gradient_bg h4,
.ds .gradient_bg h5,
.ds .gradient_bg h6 {
	color: #ffffff;
}

.ds .main_bg_color h1 a:hover, .ds .main_bg_color h1 a:active, .ds .main_bg_color h1 a:focus,
.ds .main_bg_color h2 a:hover,
.ds .main_bg_color h2 a:active,
.ds .main_bg_color h2 a:focus,
.ds .main_bg_color h3 a:hover,
.ds .main_bg_color h3 a:active,
.ds .main_bg_color h3 a:focus,
.ds .main_bg_color h4 a:hover,
.ds .main_bg_color h4 a:active,
.ds .main_bg_color h4 a:focus,
.ds .main_bg_color h5 a:hover,
.ds .main_bg_color h5 a:active,
.ds .main_bg_color h5 a:focus,
.ds .main_bg_color h6 a:hover,
.ds .main_bg_color h6 a:active,
.ds .main_bg_color h6 a:focus,
.ds .main_bg_color2 h1 a:hover,
.ds .main_bg_color2 h1 a:active,
.ds .main_bg_color2 h1 a:focus,
.ds .main_bg_color2 h2 a:hover,
.ds .main_bg_color2 h2 a:active,
.ds .main_bg_color2 h2 a:focus,
.ds .main_bg_color2 h3 a:hover,
.ds .main_bg_color2 h3 a:active,
.ds .main_bg_color2 h3 a:focus,
.ds .main_bg_color2 h4 a:hover,
.ds .main_bg_color2 h4 a:active,
.ds .main_bg_color2 h4 a:focus,
.ds .main_bg_color2 h5 a:hover,
.ds .main_bg_color2 h5 a:active,
.ds .main_bg_color2 h5 a:focus,
.ds .main_bg_color2 h6 a:hover,
.ds .main_bg_color2 h6 a:active,
.ds .main_bg_color2 h6 a:focus,
.ds .success_bg_color h1 a:hover,
.ds .success_bg_color h1 a:active,
.ds .success_bg_color h1 a:focus,
.ds .success_bg_color h2 a:hover,
.ds .success_bg_color h2 a:active,
.ds .success_bg_color h2 a:focus,
.ds .success_bg_color h3 a:hover,
.ds .success_bg_color h3 a:active,
.ds .success_bg_color h3 a:focus,
.ds .success_bg_color h4 a:hover,
.ds .success_bg_color h4 a:active,
.ds .success_bg_color h4 a:focus,
.ds .success_bg_color h5 a:hover,
.ds .success_bg_color h5 a:active,
.ds .success_bg_color h5 a:focus,
.ds .success_bg_color h6 a:hover,
.ds .success_bg_color h6 a:active,
.ds .success_bg_color h6 a:focus,
.ds .info_bg_color h1 a:hover,
.ds .info_bg_color h1 a:active,
.ds .info_bg_color h1 a:focus,
.ds .info_bg_color h2 a:hover,
.ds .info_bg_color h2 a:active,
.ds .info_bg_color h2 a:focus,
.ds .info_bg_color h3 a:hover,
.ds .info_bg_color h3 a:active,
.ds .info_bg_color h3 a:focus,
.ds .info_bg_color h4 a:hover,
.ds .info_bg_color h4 a:active,
.ds .info_bg_color h4 a:focus,
.ds .info_bg_color h5 a:hover,
.ds .info_bg_color h5 a:active,
.ds .info_bg_color h5 a:focus,
.ds .info_bg_color h6 a:hover,
.ds .info_bg_color h6 a:active,
.ds .info_bg_color h6 a:focus,
.ds .warning_bg_color h1 a:hover,
.ds .warning_bg_color h1 a:active,
.ds .warning_bg_color h1 a:focus,
.ds .warning_bg_color h2 a:hover,
.ds .warning_bg_color h2 a:active,
.ds .warning_bg_color h2 a:focus,
.ds .warning_bg_color h3 a:hover,
.ds .warning_bg_color h3 a:active,
.ds .warning_bg_color h3 a:focus,
.ds .warning_bg_color h4 a:hover,
.ds .warning_bg_color h4 a:active,
.ds .warning_bg_color h4 a:focus,
.ds .warning_bg_color h5 a:hover,
.ds .warning_bg_color h5 a:active,
.ds .warning_bg_color h5 a:focus,
.ds .warning_bg_color h6 a:hover,
.ds .warning_bg_color h6 a:active,
.ds .warning_bg_color h6 a:focus,
.ds .danger_bg_color h1 a:hover,
.ds .danger_bg_color h1 a:active,
.ds .danger_bg_color h1 a:focus,
.ds .danger_bg_color h2 a:hover,
.ds .danger_bg_color h2 a:active,
.ds .danger_bg_color h2 a:focus,
.ds .danger_bg_color h3 a:hover,
.ds .danger_bg_color h3 a:active,
.ds .danger_bg_color h3 a:focus,
.ds .danger_bg_color h4 a:hover,
.ds .danger_bg_color h4 a:active,
.ds .danger_bg_color h4 a:focus,
.ds .danger_bg_color h5 a:hover,
.ds .danger_bg_color h5 a:active,
.ds .danger_bg_color h5 a:focus,
.ds .danger_bg_color h6 a:hover,
.ds .danger_bg_color h6 a:active,
.ds .danger_bg_color h6 a:focus,
.ds .danger_bg_color h1 a:hover,
.ds .danger_bg_color h1 a:active,
.ds .danger_bg_color h1 a:focus,
.ds .danger_bg_color h2 a:hover,
.ds .danger_bg_color h2 a:active,
.ds .danger_bg_color h2 a:focus,
.ds .danger_bg_color h3 a:hover,
.ds .danger_bg_color h3 a:active,
.ds .danger_bg_color h3 a:focus,
.ds .danger_bg_color h4 a:hover,
.ds .danger_bg_color h4 a:active,
.ds .danger_bg_color h4 a:focus,
.ds .danger_bg_color h5 a:hover,
.ds .danger_bg_color h5 a:active,
.ds .danger_bg_color h5 a:focus,
.ds .danger_bg_color h6 a:hover,
.ds .danger_bg_color h6 a:active,
.ds .danger_bg_color h6 a:focus,
.ds .transp_gradient_bg h1 a:hover,
.ds .transp_gradient_bg h1 a:active,
.ds .transp_gradient_bg h1 a:focus,
.ds .transp_gradient_bg h2 a:hover,
.ds .transp_gradient_bg h2 a:active,
.ds .transp_gradient_bg h2 a:focus,
.ds .transp_gradient_bg h3 a:hover,
.ds .transp_gradient_bg h3 a:active,
.ds .transp_gradient_bg h3 a:focus,
.ds .transp_gradient_bg h4 a:hover,
.ds .transp_gradient_bg h4 a:active,
.ds .transp_gradient_bg h4 a:focus,
.ds .transp_gradient_bg h5 a:hover,
.ds .transp_gradient_bg h5 a:active,
.ds .transp_gradient_bg h5 a:focus,
.ds .transp_gradient_bg h6 a:hover,
.ds .transp_gradient_bg h6 a:active,
.ds .transp_gradient_bg h6 a:focus,
.ds .gradient_bg h1 a:hover,
.ds .gradient_bg h1 a:active,
.ds .gradient_bg h1 a:focus,
.ds .gradient_bg h2 a:hover,
.ds .gradient_bg h2 a:active,
.ds .gradient_bg h2 a:focus,
.ds .gradient_bg h3 a:hover,
.ds .gradient_bg h3 a:active,
.ds .gradient_bg h3 a:focus,
.ds .gradient_bg h4 a:hover,
.ds .gradient_bg h4 a:active,
.ds .gradient_bg h4 a:focus,
.ds .gradient_bg h5 a:hover,
.ds .gradient_bg h5 a:active,
.ds .gradient_bg h5 a:focus,
.ds .gradient_bg h6 a:hover,
.ds .gradient_bg h6 a:active,
.ds .gradient_bg h6 a:focus {
	color: #3a3d44;
}

.ds .ls {
	background-color: #ffffff;
	color: #787878;
}

.ds .ls h1,
.ds .ls h2,
.ds .ls h3,
.ds .ls h4,
.ds .ls h5,
.ds .ls h6 {
	color: #3a3d44;
}

.ds .ls h1 a,
.ds .ls h2 a,
.ds .ls h3 a,
.ds .ls h4 a,
.ds .ls h5 a,
.ds .ls h6 a {
	color: inherit;
}

.ds .ls h1 a:hover,
.ds .ls h2 a:hover,
.ds .ls h3 a:hover,
.ds .ls h4 a:hover,
.ds .ls h5 a:hover,
.ds .ls h6 a:hover {
	color: #F15931;
}

.ds .ls a {
	color: #F15931;
}

.ds .ls a:hover {
	color: #3a3d44;
}

.ds .ls .darklinks a {
	color: #3a3d44;
}

.ds .ls .darklinks a:hover {
	color: #F15931;
}

.ds .ls .greylinks a {
	color: #787878;
}

.ds .ls .greylinks a:hover {
	color: #F15931;
}

.ds .ls .highlightlinks a:hover {
	color: #3a3d44;
}

.ds .ls hr {
	background-color: #e4e4e4;
}

.ds .ls blockquote {
	color: #3a3d44;
}

.ds .ls .highlight {
	color: #F15931;
}

.ds .ls .grey {
	color: #3a3d44;
}

.ds .ls .item-content + .entry-meta:before {
	background-color: #e4e4e4;
}

.ds label {
	color: #ffffff;
}

.ds a {
	color: #ffffff;
}

.ds a:hover {
	color: #F15931;
}

.ds blockquote {
	color: #ffffff;
}

.ds blockquote:before {
	color: #ffffff;
}

.ds hr {
	border-color: rgba(255, 255, 255, 0.2);
	background-color: rgba(255, 255, 255, 0.2);
}

.ds .social-icon.color-icon:hover {
	color: #ffffff;
}

.ds .muted_background,
.ds .with_background,
.ds .well {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .inverse-bg {
	background-color: #ffffff;
}

.ds .theme_bg {
	background-color: #000;
}

.ds .main_bg_color {
	background-color: #F15931;
}

.ds .main_bg_color2 {
	background-color: #F15931;
}

.ds.muted_section:before {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .with_border {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .highlight,
.ds .highlight1 {
	color: #F15931;
	border-color: #F15931;
}

.ds .highlight2 {
	color: #F15931;
	border-color: #F15931;
}

.ds .grey, .ds .black {
	color: #ffffff;
	border-color: #ffffff;
}

.ds .lightgrey {
	color: #e0e0e1;
}

.ds .darkgrey {
	color: #787878;
}

.ds .colorlinks a {
	color: #F15931;
}

.ds .colorlinks a:hover {
	color: #F15931;
}

.ds .greylinks a {
	color: #e0e0e1;
}

.ds .greylinks a:hover {
	color: #F15931;
}

.ds .greylinks a.social-icon {
	color: #e0e0e1;
}

.ds .darklinks a,
.ds .darklink {
	color: #ffffff;
}

.ds .darklinks a:hover,
.ds .darklink:hover {
	color: #F15931;
}

.ds .highlightlinks a,
.ds .highlightlink {
	color: #F15931;
}

.ds .highlightlinks a:hover,
.ds .highlightlink:hover {
	color: #ffffff;
}

.ds .highlight2links a,
.ds .highlight2link {
	color: #F15931;
}

.ds .highlight2links a:hover,
.ds .highlight2link:hover {
	color: #ffffff;
}

.ds.with_top_border:before, .ds.with_bottom_border:after {
	background-color: rgba(255, 255, 255, 0.2);
}

.ds input[type="text"],
.ds input[type="email"],
.ds input[type="url"],
.ds input[type="password"],
.ds input[type="search"],
.ds input[type="tel"],
.ds input[type="number"],
.ds textarea,
.ds select,
.ds .form-control,
.ds .select-group {
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.5);
}

.ds .with_background .widget_search [type="text"],
.ds .with_background .widget_search [type="search"],
.ds .with_background .widget_product_search [type="search"],
.ds .with_background .widget_mailchimp [type="email"] {
	background-color: #3a3d44;
	border-color: rgba(255, 255, 255, 0.2);
}

.ds form label + [class*="icon-"] {
	color: #787878;
}

.ds option {
	color: #3a3d44;
}

.ds .form-group-select:before {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .form-control:-moz-placeholder {
	color: #e0e0e1;
}

.ds .form-control::-moz-placeholder {
	color: #e0e0e1;
}

.ds .form-control:-ms-input-placeholder {
	color: #e0e0e1;
}

.ds .form-control::-webkit-input-placeholder {
	color: #e0e0e1;
}

.ds .form-control:focus:-moz-placeholder {
	color: transparent;
}

.ds .form-control:focus::-moz-placeholder {
	color: transparent;
}

.ds .form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.ds .form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

.ds .contact-form.transparent-background .form-control {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .table,
.ds .table-bordered,
.ds .table th,
.ds .table td,
.ds table th,
.ds table td {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds table th {
	color: #ffffff;
}

.ds .table_template th,
.ds .table_template td {
	border-color: transparent;
}

.ds .table_template th {
	color: inherit;
}

.ds .table-striped > tbody > tr:nth-child(odd),
.ds .table-striped > tbody > tr:nth-child(odd) > td,
.ds .table-striped > tbody > tr:nth-child(odd) > th {
	border-color: rgba(255, 255, 255, 0.2);
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .color-icon.bg-icon:hover {
	color: #ffffff;
}

.ds .color-bg-icon:hover {
	background-color: #F15931;
}

.ds .nav-unstyled {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .nav-tabs > li > a {
	border-color: #ffffff;
	color: #ffffff;
}

.ds .tab-content {
	color: #ffffff;
	background-color: #787878;
}

.ds .tab-content.no-border {
	background-color: transparent;
}

.ds .nav-tabs > li > a:hover,
.ds .nav-tabs > li.active > a,
.ds .nav-tabs > li.active > a:hover,
.ds .nav-tabs > li.active > a:focus {
	border-color: transparent;
	background-color: #787878;
	color: #ffffff;
}

.ds .price-table.style1 {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .plan-price p {
	color: #ffffff;
}

.ds .features-list .disabled:before {
	color: #ffffff;
}

.ds .features-list li + li {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds ul.list1 li {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .testimonials-carousel .media + p {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .post-adds .share_button > i,
.ds .post-adds .like_button > i {
	color: #ffffff;
}

.ds .widget_shopping_cart .cart_list,
.ds .widget_recent_entries li,
.ds .widget_recent_comments li,
.ds .widget_archive li,
.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_nav_menu li,
.ds .widget_pages li,
.ds .widget_popular_entries li {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_nav_menu ul ul {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_popular_entries .media-heading {
	color: #ffffff;
}

.ds .tweet_list li + li .tweet_right {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_tag_cloud a {
	color: #ffffff;
}

.ds .widget_shopping_cart li a + a {
	color: #ffffff;
}

.ds .widget_shopping_cart li a + a:hover {
	color: #F15931;
}

.ds .widget .woocommerce-Price-amount {
	color: #ffffff;
}

.ds .summary .price {
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .summary .product_meta {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_price_filter .price_label {
	color: #ffffff;
}

.ds #calendar_wrap {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar table {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar tfoot td a {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar caption {
	color: #ffffff;
}

.ds .widget_calendar thead {
	background-color: #f2f2f2;
}

.ds .widget_calendar tbody {
	background-color: rgba(255, 255, 255, 0.05);
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_calendar th {
	color: #3a3d44;
}

.ds .widget_calendar tbody td a:hover {
	color: #ffffff;
}

.ds .widget_categories li,
.ds .widget_meta li,
.ds .widget_pages li,
.ds .widget_nav_menu li,
.ds .widget_popular_entries li,
.ds .widget_recent_posts li {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .widget_recent_entries > ul > li:last-child,
.ds .widget_recent_comments > ul > li:last-child,
.ds .widget_archive > ul > li:last-child,
.ds .widget_categories > ul > li:last-child,
.ds .widget_meta > ul > li:last-child,
.ds .widget_popular_entries > ul > li:last-child,
.ds .widget_nav_menu ul.menu > li:last-child,
.ds .widget_pages > ul > li:last-child {
	border-bottom-color: rgba(255, 255, 255, 0.2);
}

.ds .grid-view h3:before, .ds .grid-view h3:after {
	background-color: rgba(255, 255, 255, 0.2);
}

.ds .owl-carousel .owl-nav > div {
	background-color: rgba(255, 255, 255, 0.2);
}

.ds .owl-carousel .owl-nav > div:after {
	color: #ffffff;
}

.ds .owl-carousel .owl-nav > div:hover:after {
	color: #F15931;
}

.ds .ui-slider {
	background-color: #ffffff;
}

.ds .ui-slider .ui-slider-handle {
	background-color: #ffffff;
}

.ds #sort_view {
	background-color: transparent;
	color: #ffffff;
}

.ds #toggle_shop_view.grid-view:after, .ds #toggle_shop_view:before {
	background-color: rgba(247, 247, 247, 0.05);
}

.ds .widget_layered_nav .color-filters a:before {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .table.cart a {
	color: #ffffff;
}

.ds .pagination > li > a,
.ds .pagination > li > span {
	color: #e0e0e1;
	border-color: rgba(255, 255, 255, 0.2);
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .pagination > li.active > a,
.ds .pagination > li.active > span,
.ds .pagination > li > a:hover,
.ds .pagination > li > span:hover,
.ds .pagination > li > a:focus,
.ds .pagination > li > span:focus {
	color: #ffffff;
	background-color: #F15931;
}

.ds .thumbnail {
	background-color: transparent;
}

.ds .thumbnail h3 a {
	color: #ffffff;
}

.ds .thumbnail .caption {
	border-color: transparent;
	background-color: rgba(252, 252, 252, 0.05);
}

.ds .progress {
	background-color: #f7f7f7;
}

.ds .panel-body {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .entry-meta .comments-link a {
	color: #ffffff;
}

.ds #timetable tbody th,
.ds #timetable tbody td {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds div .fw-shortcode-calendar-wrapper .page-header h3,
.ds div .btn-group button[data-calendar-nav],
.ds div .btn-group button[data-calendar-nav*='today'] {
	color: #ffffff;
}

.ds .entry-meta.with_top_border:before {
	background-color: rgba(255, 255, 255, 0.2);
}

.ds .text-nav .owl-nav > div {
	background-color: #3a3d44;
}

.ds .text-nav .owl-nav > div:after {
	color: #ffffff;
}

.ds .text-nav .owl-nav > div:hover:after {
	color: #F15931;
}

.ds a.border-icon {
	border-color: #787878;
}

.ds .nav-unstyled .active a {
	color: #ffffff;
}

.ds .countdown-row .countdown-period {
	color: #ffffff;
}

.ds .widget_slider .owl-carousel .owl-nav > div:after {
	color: #ffffff;
}

.ds .widget_slider .owl-carousel .owl-nav > div:hover:after {
	color: #F15931;
}

.ds .divided-content > * + *:before {
	color: #e0e0e1;
}

.ds .nav-unstyled .active a {
	color: #F15931;
}

.ds .theme_buttons a,
.ds .theme_button {
	color: #ffffff;
	background-color: #515660;
}

.ds .theme_buttons a:hover, .ds .theme_buttons a:focus, .ds .theme_buttons a:active,
.ds .theme_button:hover,
.ds .theme_button:focus,
.ds .theme_button:active {
	color: #F15931;
}

.ds .theme_buttons.inverse a,
.ds .theme_button.inverse {
	color: #515660;
	background-color: #ffffff;
	border-color: #515660;
}

.ds .theme_buttons.inverse a:hover, .ds .theme_buttons.inverse a:focus, .ds .theme_buttons.inverse a:active,
.ds .theme_button.inverse:hover,
.ds .theme_button.inverse:focus,
.ds .theme_button.inverse:active {
	color: #F15931;
}

.ds .theme_buttons.color1 a,
.ds .theme_button.color1,
.ds .button.alt,
.ds .btn-accent,
.ds .wc-proceed-to-checkout .checkout-button,
.ds .form-submit [type="submit"] {
	color: #ffffff;
	background-color: #F15931;
	border-color: #F15931;
}

.ds .theme_buttons.color1 a:hover, .ds .theme_buttons.color1 a:focus, .ds .theme_buttons.color1 a:active,
.ds .theme_button.color1:hover,
.ds .theme_button.color1:focus,
.ds .theme_button.color1:active,
.ds .button.alt:hover,
.ds .button.alt:focus,
.ds .button.alt:active,
.ds .btn-accent:hover,
.ds .btn-accent:focus,
.ds .btn-accent:active,
.ds .wc-proceed-to-checkout .checkout-button:hover,
.ds .wc-proceed-to-checkout .checkout-button:focus,
.ds .wc-proceed-to-checkout .checkout-button:active,
.ds .form-submit [type="submit"]:hover,
.ds .form-submit [type="submit"]:focus,
.ds .form-submit [type="submit"]:active {
	color: #3a3d44;
}

.ds .theme_buttons.color1.inverse a,
.ds .theme_button.color1.inverse {
	color: #F15931;
	background-color: #ffffff;
}

.ds .theme_buttons.color1.inverse a:hover, .ds .theme_buttons.color1.inverse a:focus, .ds .theme_buttons.color1.inverse a:active,
.ds .theme_button.color1.inverse:hover,
.ds .theme_button.color1.inverse:focus,
.ds .theme_button.color1.inverse:active {
	color: #ffffff;
	background-color: #F15931;
}

.ds .theme_buttons.color2 a,
.ds .theme_button.color2 {
	background-color: #F15931;
}

.ds .theme_buttons.color2.inverse a,
.ds .theme_button.color2.inverse {
	color: #F15931;
	border-color: #F15931;
}

.ds .theme_buttons.color3 a,
.ds .theme_button.color3 {
	background-color: #fcb315;
}

.ds .theme_buttons.color3.inverse a,
.ds .theme_button.color3.inverse {
	color: #fcb315;
	border-color: #fcb315;
}

.ds .theme_buttons.color4 a,
.ds .theme_button.color4 {
	background-color: #03a8cb;
}

.ds .theme_buttons.color4.inverse a,
.ds .theme_button.color4.inverse {
	color: #03a8cb;
	border-color: #03a8cb;
}

.ds .theme_buttons[class*="color"]:hover, .ds .theme_buttons[class*="color"]:active, .ds .theme_buttons[class*="color"]:focus,
.ds .theme_button[class*="color"]:hover,
.ds .theme_button[class*="color"]:active,
.ds .theme_button[class*="color"]:focus {
	color: #3a3d44;
}

.ds .theme_buttons.inverse[class*="color"]:hover, .ds .theme_buttons.inverse[class*="color"]:active, .ds .theme_buttons.inverse[class*="color"]:focus,
.ds .theme_button.inverse[class*="color"]:hover,
.ds .theme_button.inverse[class*="color"]:active,
.ds .theme_button.inverse[class*="color"]:focus {
	color: #ffffff;
}

.ds .complex_button[class*="color"]:not(:hover) {
	color: #ffffff;
}

.ds .complex_button:not([class*="color"]) {
	border-color: #515660;
}

.ds .complex_button:not([class*="color"]) .left-icon,
.ds .complex_button:not([class*="color"]) .right-icon {
	background-color: #515660;
}

.ds .complex_button[class*="color"] {
	background-color: transparent;
}

.ds .complex_button.color1 {
	border-color: #F15931;
}

.ds .complex_button.color1:hover, .ds .complex_button.color1:focus, .ds .complex_button.color1:active {
	color: #F15931;
}

.ds .complex_button.color2 {
	border-color: #F15931;
}

.ds .complex_button.color2:hover, .ds .complex_button.color2:focus, .ds .complex_button.color2:active {
	color: #F15931;
}

.ds .theme_button.no_bg_button.color1 {
	color: #F15931;
}

.ds .theme_button.no_bg_button.color1:hover {
	color: #ffffff;
}

.ds .theme_button.no_bg_button.color2 {
	color: #F15931;
}

.ds .theme_button.no_bg_button.color2:hover {
	color: #ffffff;
}

.ds .dropdown-menu {
	background-color: #3a3d44;
}

.ds .content-padding footer:before {
	background-color: rgba(255, 255, 255, 0.2);
}

.ds .item-content + footer.item-meta > div + div,
.ds .item-content + footer.item-meta {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .teaser .dashed_border {
	background-image: none;
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.ds .format-chat.with_background .entry-content p:nth-child(2n) {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .item-content + .entry-meta:before {
	background-color: rgba(255, 255, 255, 0.2);
}

.ds h2.section_header + hr + .section-excerpt {
	font-weight: 300;
	color: #ffffff;
}

.ds .media_items_list .media {
	background-color: rgba(255, 255, 255, 0.05);
}

.ds .media_items_list .media .media-body {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .side-item.side-md .row .item-content,
.ds .side-item.side-md .row footer.entry-meta {
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .apsc-each-profile {
	background-color: rgba(255, 255, 255, 0.05);
	border-color: rgba(255, 255, 255, 0.2);
}

.ds .apsc-each-profile .social-icon span {
	color: #fff;
}

.ds .widget_tag_cloud a,
.ds .tag-links a {
	color: #ffffff;
	background-color: rgba(255, 255, 255, 0.05);
	border-color: rgba(255, 255, 255, 0.2);
}

/*
** Color Section - .cs 
*/
.cs.main_color2,
.cs.main_bg_color2 {
	background-color: #F15931;
}

.cs.main_color2 .highlight,
.cs.main_bg_color2 .highlight {
	color: #F15931;
}

.cs.main_color2 .theme_button.color2,
.cs.main_bg_color2 .theme_button.color2 {
	color: #F15931;
	background-color: #fff;
}

.cs.main_color2 .theme_button.color2:hover, .cs.main_color2 .theme_button.color2:focus, .cs.main_color2 .theme_button.color2:active,
.cs.main_bg_color2 .theme_button.color2:hover,
.cs.main_bg_color2 .theme_button.color2:focus,
.cs.main_bg_color2 .theme_button.color2:active {
	color: #3a3d44;
}

.cs.main_color3,
.cs.main_bg_color3 {
	background-color: #fcb315;
}

.cs.main_color3 .highlight,
.cs.main_bg_color3 .highlight {
	color: #F15931;
}

.cs.main_color4,
.cs.main_bg_color4 {
	background-color: #03a8cb;
}

.cs.main_color4 .highlight,
.cs.main_bg_color4 .highlight {
	color: #F15931;
}

.cs.gradient {
	background: linear-gradient(-90deg, #f3702b, #F15931);
}

.cs.gradient .breadcrumb > .active {
	color: #3a3d44;
}

.cs {
	background-color: #F15931;
	color: #fff;
}

.cs.parallax {
	color: rgba(255, 255, 255, 0.8);
}

.cs .ls .greylinks a {
	color: #787878;
}

.cs .ls .greylinks a:hover, .cs .ls .greylinks a:focus, .cs .ls .greylinks a:active {
	color: #787878;
}

.cs .ls h1 a:hover,
.cs .ls h2 a:hover,
.cs .ls h3 a:hover,
.cs .ls h4 a:hover,
.cs .ls h5 a:hover,
.cs .ls h6 a:hover {
	color: #F15931;
}

.cs .ds .greylinks a {
	color: #787878;
}

.cs .ds .greylinks a:hover, .cs .ds .greylinks a:focus, .cs .ds .greylinks a:active {
	color: #787878;
}

.cs .ds h1,
.cs .ds h2,
.cs .ds h3,
.cs .ds h4,
.cs .ds h5,
.cs .ds h6 {
	color: #ffffff;
}

.cs .ds h1 a,
.cs .ds h2 a,
.cs .ds h3 a,
.cs .ds h4 a,
.cs .ds h5 a,
.cs .ds h6 a {
	color: inherit;
}

.cs .ds h1 a:hover,
.cs .ds h2 a:hover,
.cs .ds h3 a:hover,
.cs .ds h4 a:hover,
.cs .ds h5 a:hover,
.cs .ds h6 a:hover {
	color: #F15931;
}

.cs h1,
.cs h2,
.cs h3,
.cs h4,
.cs h5,
.cs h6 {
	color: #ffffff;
}

.cs h1 a,
.cs h2 a,
.cs h3 a,
.cs h4 a,
.cs h5 a,
.cs h6 a {
	color: inherit;
}

.cs h1 a:hover,
.cs h2 a:hover,
.cs h3 a:hover,
.cs h4 a:hover,
.cs h5 a:hover,
.cs h6 a:hover {
	color: #3a3d44;
}

.cs h2.section_header {
	border-left-color: #fff;
}

.cs a {
	color: #ffffff;
}

.cs a:hover, .cs a:active, .cs a:focus {
	color: rgba(255, 255, 255, 0.5);
}

.cs blockquote, .cs .blockquote {
	color: #ffffff;
}

.cs .with_background {
	background-color: rgba(255, 255, 255, 0.23);
}

.cs .with_border {
	border-color: rgba(255, 255, 255, 0.23);
}

.cs.with_top_border .container:before,
.cs.with_bottom_border .container:after {
	background-color: #ee7c6f;
}

.cs .greylinks a,
.cs .greylinks a.social-icon {
	color: #3a3d44;
}

.cs .greylinks a:hover,
.cs .greylinks a.social-icon:hover {
	color: #ffffff;
}

.cs .darklinks a {
	color: #ffffff;
}

.cs .darklinks a:hover {
	color: #F15931;
}

.cs .highlightlinks a,
.cs .highlightlinks a.social-icon {
	color: rgba(255, 255, 255, 0.5);
}

.cs .black {
	border-color: #ffffff;
	color: #ffffff;
}

.cs .grey {
	border-color: #3a3d44;
	color: #3a3d44;
}

.cs .lightgrey {
	color: rgba(255, 255, 255, 0.6);
}

.cs .response,
.cs .highlight {
	color: #fff;
}

.cs .breadcrumb > .active,
.cs .breadcrumb > li:last-child {
	color: #3a3d44;
}

.cs .light_bg_color .highlight, .cs .light_bg_color.highlight {
	color: #F15931;
}

.cs ul.list1 li {
	border-color: rgba(255, 255, 255, 0.23);
}

.cs [class*='socicon-'] {
	border-color: rgba(255, 255, 255, 0.23);
}

.cs .color-icon.bg-icon {
	background-color: rgba(255, 255, 255, 0.23);
}

.cs a.social-icon.color-bg-icon:hover {
	background-color: #3a3d44;
}

.cs .pagination > li > a,
.cs .pagination > li > span {
	color: #ffffff;
}

.cs .pagination > li.active > a,
.cs .pagination > li.active > span,
.cs .pagination > li > a:hover,
.cs .pagination > li > span:hover,
.cs .pagination > li > a:focus,
.cs .pagination > li > span:focus {
	color: #ffffff;
}

.cs .widget_recent_entries li + li,
.cs .widget_recent_comments li + li,
.cs .widget_archive li + li,
.cs .widget_categories li + li,
.cs .widget_meta li + li,
.cs .widget_popular_entries li + li,
.cs .widget_nav_menu li + li,
.cs .widget_pages li + li {
	border-top-color: rgba(255, 255, 255, 0.23);
}

.cs .theme_buttons a:hover, .cs .theme_buttons a:focus, .cs .theme_buttons a:active,
.cs .theme_button:hover,
.cs .theme_button:focus,
.cs .theme_button:active {
	color: #F15931;
}

.cs .theme_buttons.inverse a,
.cs .theme_button.inverse {
	color: #3a3d44;
	border-color: #3a3d44;
}

.cs .theme_buttons.inverse a:hover, .cs .theme_buttons.inverse a:focus, .cs .theme_buttons.inverse a:active,
.cs .theme_button.inverse:hover,
.cs .theme_button.inverse:focus,
.cs .theme_button.inverse:active {
	color: #ffffff;
}

.cs .theme_buttons[class*="color"],
.cs .theme_button[class*="color"] {
	color: #ffffff;
}

.cs .theme_buttons[class*="color"]:hover, .cs .theme_buttons[class*="color"]:focus, .cs .theme_buttons[class*="color"]:active,
.cs .theme_button[class*="color"]:hover,
.cs .theme_button[class*="color"]:focus,
.cs .theme_button[class*="color"]:active {
	color: #3a3d44;
}

.cs .theme_buttons.color1 a,
.cs .theme_button.color1 {
	color: #F15931;
	background-color: #ffffff;
}

.cs .theme_buttons.color1 a:hover, .cs .theme_buttons.color1 a:focus, .cs .theme_buttons.color1 a:active,
.cs .theme_button.color1:hover,
.cs .theme_button.color1:focus,
.cs .theme_button.color1:active {
	color: #3a3d44;
}

.cs .theme_buttons.color1.inverse a,
.cs .theme_button.color1.inverse {
	color: #ffffff;
	border-color: #ffffff;
}

.cs .theme_buttons.color1.inverse a:hover, .cs .theme_buttons.color1.inverse a:focus, .cs .theme_buttons.color1.inverse a:active,
.cs .theme_button.color1.inverse:hover,
.cs .theme_button.color1.inverse:focus,
.cs .theme_button.color1.inverse:active {
	color: #3a3d44;
}

.cs .theme_buttons.color2.inverse a,
.cs .theme_button.color2.inverse {
	color: #F15931;
	border-color: #F15931;
}

.cs .theme_buttons.color2.inverse a:hover, .cs .theme_buttons.color2.inverse a:focus, .cs .theme_buttons.color2.inverse a:active,
.cs .theme_button.color2.inverse:hover,
.cs .theme_button.color2.inverse:focus,
.cs .theme_button.color2.inverse:active {
	color: #ffffff;
	background-color: #F15931;
}

.cs .theme_buttons.color3.inverse a,
.cs .theme_button.color3.inverse {
	color: #fcb315;
	border-color: #fcb315;
}

.cs .theme_buttons.color3.inverse a:hover, .cs .theme_buttons.color3.inverse a:focus, .cs .theme_buttons.color3.inverse a:active,
.cs .theme_button.color3.inverse:hover,
.cs .theme_button.color3.inverse:focus,
.cs .theme_button.color3.inverse:active {
	color: #ffffff;
}

.cs .theme_buttons.color4.inverse a,
.cs .theme_button.color4.inverse {
	color: #03a8cb;
	border-color: #03a8cb;
}

.cs .theme_buttons.color4.inverse a:hover, .cs .theme_buttons.color4.inverse a:focus, .cs .theme_buttons.color4.inverse a:active,
.cs .theme_button.color4.inverse:hover,
.cs .theme_button.color4.inverse:focus,
.cs .theme_button.color4.inverse:active {
	color: #ffffff;
}

.cs .theme_button.no_bg_button {
	color: #ffffff;
}

.cs .theme_button.no_bg_button:hover, .cs .theme_button.no_bg_button:active, .cs .theme_button.no_bg_button:focus {
	color: #3a3d44 !important;
}

.cs .theme_button.no_bg_button.color1 {
	color: #ffffff;
}

.cs .theme_button.no_bg_button.color2 {
	color: #ffffff;
}

.cs .divided-content > * + *:before {
	color: #ffffff;
}

.cs .list3 li:after {
	color: #fff;
}

.cs #isotope_filters a,
.cs .filters a,
.cs #isotope_filters a.selected,
.cs #isotope_filters a:hover,
.cs .filters a.selected,
.cs .filters a:hover {
	color: #ffffff;
}

.cs #isotope_filters a:after,
.cs .filters a:after,
.cs #isotope_filters a.selected:after,
.cs #isotope_filters a:hover:after,
.cs .filters a.selected:after,
.cs .filters a:hover:after {
	background-color: #ffffff;
}

.cs.with_top_border:before, .cs.with_bottom_border:after,
.cs.with_top_border_container .container:before,
.cs.with_bottom_border_container .container:after {
	background-color: rgba(255, 255, 255, 0.23);
}

.cs .flex-control-nav.owl-carousel:after {
	background-color: #ffffff;
}

.cs .breadcrumb > li + li:before {
	color: #ffffff;
}

.cs input[type="text"],
.cs input[type="email"],
.cs input[type="url"],
.cs input[type="password"],
.cs input[type="search"],
.cs input[type="tel"],
.cs input[type="number"],
.cs textarea,
.cs select,
.cs .form-control,
.cs .select-group {
	border-color: rgba(255, 255, 255, 0.3);
}

.cs .form-control:focus {
	border-color: rgba(255, 255, 255, 0.7);
}

.cs .form-control:-moz-placeholder {
	color: #ffffff;
}

.cs .form-control::-moz-placeholder {
	color: #ffffff;
}

.cs .form-control:-ms-input-placeholder {
	color: #ffffff;
}

.cs .form-control::-webkit-input-placeholder {
	color: #ffffff;
}

.cs .form-control:focus:-moz-placeholder {
	color: transparent;
}

.cs .form-control:focus::-moz-placeholder {
	color: transparent;
}

.cs .form-control:focus:-ms-input-placeholder {
	color: transparent;
}

.cs .form-control:focus::-webkit-input-placeholder {
	color: transparent;
}

/*
** Template Specific Styles
*/
/* topline styles */
.page_topline {
	font-size: 14px;
	position: relative;
	z-index: 1001;
}

.page_topline a.social-icon {
	vertical-align: baseline;
}

@media (max-width: 767px) {
	.page_topline .xs-block {
		display: block;
		margin: auto;
	}
}

.page_topline .searchform input[type="text"] {
	border: none;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}

@media (min-width: 400px) {
	.page_topline .searchform input[type="text"] {
		min-width: 380px;
	}
}

.page_topline .searchform .form-group + .theme_button {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.page_topline .searchform .form-group + .theme_button:before {
	font-size: 28px;
}

.page_topline .searchform #search-close {
	position: absolute;
	top: 0;
	left: 0;
}

#topline-animation-wrap {
	position: relative;
}

#topline-show {
	display: none;
}

@media (min-width: 1200px) {
	#topline-show {
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -15px;
	}
}

/* toplogo styles */
@media (min-width: 1600px) {
	.page_toplogo .teasers-row {
		max-width: 85%;
		margin: auto;
	}
}

.page_toplogo .row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.page_toplogo .row:before {
	display: none;
}

@media (max-width: 399px) {
	.page_toplogo strong.big {
		font-size: 18px;
	}
}

@media (min-width: 1200px) {
	.page_toplogo.section_padding_lg_25 > [class*="container"] {
		padding-top: 25px;
		padding-bottom: 25px;
	}
}

/* title section (breadcrumbs) styles */
.page_breadcrumbs .breadcrumbs_logo {
	margin: 0 0 15px;
}

.page_breadcrumbs .breadcrumbs_logo img {
	opacity: 0.3;
}

.page_breadcrumbs h1,
.page_breadcrumbs h2 {
	display: inline;
	font-size: 30px;
	font-weight: 500;
	line-height: 0.9em;
	word-wrap: break-word;
}

.page_breadcrumbs h1 + .breadcrumb,
.page_breadcrumbs h2 + .breadcrumb {
	margin-top: 10px;
}

@media (min-width: 992px) {
	.page_breadcrumbs h1,
	.page_breadcrumbs h2 {
		font-size: 40px;
	}
}

.page_breadcrumbs h2.small {
	font-size: 30px;
}

@media screen and (min-width: 992px) {
	.page_breadcrumbs .display_table_md {
		width: auto;
		min-width: 0;
	}

	.page_breadcrumbs h1.display_table_cell_md,
	.page_breadcrumbs h2.display_table_cell_md {
		padding-right: 20px;
		padding-left: 15px;
	}

	.page_breadcrumbs .breadcrumb.display_table_cell_md {
		border-left: 1px solid rgba(255, 255, 255, 0.23);
		padding-left: 25px;
	}
}

.page_breadcrumbs .bottom_breadcrumbs {
	text-align: center;
	float: right;
	padding: 4px 20px 0;
	margin: 0 0 -25px 0;
}

@media (max-width: 1199px) {
	.page_breadcrumbs.section_padding_top_65.section_padding_bottom_65 > [class*="container"] {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.taxonomy-name-title {
	display: none;
}

/* template parallax sections */
.section_404.background_cover {
	background-image: url(/static/img/parallax/404.jpg);
}

@media (max-width: 991px) {
	.section_404.background_cover {
		background-position: center left;
	}
}

.page_breadcrumbs.background_cover {
	background-image: url(/static/img/parallax/breadcrumbs.jpg);
}


@media (max-width: 1199px) {
	.page_footer.parallax + .page_copyright.parallax {
		background-image: none;
	}

	.page_footer.parallax + .page_copyright.parallax > [class*="container"]:before, .page_footer.parallax + .page_copyright.parallax:before {
		display: none !important;
	}
}

/* footer styles */
.page_footer .widget {
	margin-top: 30px;
}

.page_footer .vertical_logo .logo_text .big {
	font-size: 32px;
}

@media (min-width: 768px) {
	.page_footer .widget {
		margin-top: 40px;
	}

	.page_footer .widget_about {
		margin-top: 26px;
	}
}

@media (min-width: 992px) {
	.page_footer .widget_media_margin {
		margin-top: 75px;
	}
}

@media (min-width: 1200px) {
	.page_footer .with_padding {
		padding: 60px;
	}
}

@media (max-width: 499px) {
	.page_footer.template_footer > [class*="container"] {
		padding-top: 0;
	}

	.page_footer.template_footer > [class*="container"] > .row > .col-xs-12:first-child {
		margin-top: 0;
		padding: 0;
	}
}

.page_footer .widget_mailchimp.with_padding .form-group + .theme_button {
	position: static;
	border-radius: 10px;
	font-size: 14px;
	width: auto;
	line-height: 1;
	padding: 18px 30px;
}

.page_footer .widget_mailchimp.with_padding .form-group + .theme_button:before {
	display: none;
}

@media (min-width: 1600px) {
	.page_footer .widget_mailchimp.with_padding .form-group + .theme_button {
		padding: 23px 40px;
	}
}

@media (min-width: 992px) {
	.page_footer .widget_mailchimp.with_padding .form-group-wrap {
		display: -ms-flexbox;
		display: flex;
	}

	.page_footer .widget_mailchimp.with_padding .form-group-wrap .form-group {
		-ms-flex: auto;
		flex: auto;
		margin-right: 40px;
	}
}

@media (max-width: 991px) {
	.page_footer .widget_mailchimp.with_padding {
		text-align: center;
	}

	.page_footer .widget_mailchimp.with_padding .form-group {
		margin-bottom: 20px;
	}
}

@media (min-width: 992px) {
	.page_footer .widget_mailchimp.with_padding {
		margin-bottom: 35px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
	}

	.page_footer .widget_mailchimp.with_padding .widget-title {
		margin-right: 35px;
		margin-bottom: 0;
	}

	.page_footer .widget_mailchimp.with_padding form {
		-ms-flex: auto;
		flex: auto;
	}
}

@media (min-width: 1200px) {
	.page_footer .widget_mailchimp.with_padding {
		margin-bottom: 55px;
	}
}

/* copyrights styles */
.ds:not(.ms) + .page_copyright:not(.ms) .container,
.ds.ms + .page_copyright.ds.ms .container {
	position: relative;
}

.ds:not(.ms) + .page_copyright:not(.ms) .container:before,
.ds.ms + .page_copyright.ds.ms .container:before {
	content: "";
	display: block;
	position: absolute;
	height: 1px;
	left: 15px;
	right: 15px;
	top: 0;
	background-color: rgba(255, 255, 255, 0.2);
}

/* google map  */
#map,
.page_map {
	height: 550px;
}

#map img,
.page_map img {
	max-width: inherit;
}

#map p,
.page_map p {
	line-height: 1.3;
	margin-bottom: 0;
}

#map .map_marker_description,
.page_map .map_marker_description {
	padding: 10px 5px 5px;
}

#map .map_marker_icon,
.page_map .map_marker_icon {
	display: none;
}

/* Other */
@media (min-width: 1200px) and (max-width: 1599px) {
	.section_intro_overlap .teaser[class*="_bg_color"],
  .section_intro_overlap .teaser[class*="with_"] {
		padding: 30px;
	}

	.section_intro_overlap .teaser_icon.thick_border_icon[class*="_bg_color"].big_wrapper,
  .section_intro_overlap .teaser_icon.thick_border_icon.with_background.big_wrapper {
		width: calc(2em + 20px);
		height: calc(2em + 20px);
	}

	.section_intro_overlap .teaser_icon.thick_border_icon[class*="_bg_color"].big_wrapper > *,
  .section_intro_overlap .teaser_icon.thick_border_icon.with_background.big_wrapper > * {
		line-height: 2em;
	}
}

@media (max-width: 767px) {
	#about [class*="container"] {
		padding-top: 70px;
	}
}

@media (max-width: 991px) {
	#about .col-md-6:nth-child(2) {
		margin-top: 23px;
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	#contact .contact-form-submit {
		margin-top: 20px;
	}
}

@media (max-width: 399px) {
	#contact .with_padding {
		padding-top: 15px;
		padding-bottom: 30px;
	}
}

@media (max-width: 499px) {
	#contact > [class*="container"] > .row > .col-xs-12 {
		padding: 0;
	}
}

#contact textarea {
	max-height: 140px;
}

@media (min-width: 1200px) {
	.intro_section + .section_intro_overlap {
		overflow: visible !important;
		position: relative;
		z-index: 2;
	}

	.intro_section + .section_intro_overlap .container-fluid {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

@media (min-width: 768px) {
	#team .owl-carousel .owl-nav {
		top: -80px;
		right: 0;
		left: auto;
	}

	#team .owl-carousel .owl-nav > div {
		position: relative;
	}

	#team .owl-carousel .owl-nav > div:first-child {
		margin-right: 10px;
	}
}

@media (min-width: 768px) and (min-width: 1200px) {
	#team .owl-carousel .owl-nav {
		top: -90px;
	}
}

@media (max-width: 1199px) {
	#blog > [class*="container"] {
		padding-bottom: 70px;
	}
}

/* Color variations */
.loop-colors .owl-item:nth-child(4n + 2) .more-link:not(:hover),
.loop-colors > *:nth-child(4n + 2) .more-link:not(:hover),
[class*="col-"]:nth-child(4n + 2) > .loop-color .more-link:not(:hover),
.loop-color:nth-child(4n + 2) .more-link:not(:hover) {
	color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .darklinks a:hover,
.loop-colors .owl-item:nth-child(4n + 2) .greylinks a:hover,
.loop-colors .owl-item:nth-child(4n + 2) .highlight,
.loop-colors > *:nth-child(4n + 2) .darklinks a:hover,
.loop-colors > *:nth-child(4n + 2) .greylinks a:hover,
.loop-colors > *:nth-child(4n + 2) .highlight,
[class*="col-"]:nth-child(4n + 2) > .loop-color .darklinks a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color .greylinks a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color .highlight,
.loop-color:nth-child(4n + 2) .darklinks a:hover,
.loop-color:nth-child(4n + 2) .greylinks a:hover,
.loop-color:nth-child(4n + 2) .highlight {
	color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) h1 a:hover, .loop-colors .owl-item:nth-child(4n + 2) h2 a:hover, .loop-colors .owl-item:nth-child(4n + 2) h3 a:hover, .loop-colors .owl-item:nth-child(4n + 2) h4 a:hover, .loop-colors .owl-item:nth-child(4n + 2) h5 a:hover, .loop-colors .owl-item:nth-child(4n + 2) h6 a:hover,
.loop-colors > *:nth-child(4n + 2) h1 a:hover,
.loop-colors > *:nth-child(4n + 2) h2 a:hover,
.loop-colors > *:nth-child(4n + 2) h3 a:hover,
.loop-colors > *:nth-child(4n + 2) h4 a:hover,
.loop-colors > *:nth-child(4n + 2) h5 a:hover,
.loop-colors > *:nth-child(4n + 2) h6 a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color h1 a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color h2 a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color h3 a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color h4 a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color h5 a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color h6 a:hover,
.loop-color:nth-child(4n + 2) h1 a:hover,
.loop-color:nth-child(4n + 2) h2 a:hover,
.loop-color:nth-child(4n + 2) h3 a:hover,
.loop-color:nth-child(4n + 2) h4 a:hover,
.loop-color:nth-child(4n + 2) h5 a:hover,
.loop-color:nth-child(4n + 2) h6 a:hover {
	color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .greylinks a:hover,
.loop-colors .owl-item:nth-child(4n + 2) .darklinks a:hover,
.loop-colors > *:nth-child(4n + 2) .greylinks a:hover,
.loop-colors > *:nth-child(4n + 2) .darklinks a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color .greylinks a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color .darklinks a:hover,
.loop-color:nth-child(4n + 2) .greylinks a:hover,
.loop-color:nth-child(4n + 2) .darklinks a:hover {
	color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .highlightlinks a,
.loop-colors > *:nth-child(4n + 2) .highlightlinks a,
[class*="col-"]:nth-child(4n + 2) > .loop-color .highlightlinks a,
.loop-color:nth-child(4n + 2) .highlightlinks a {
	color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .highlightlinks a:hover,
.loop-colors > *:nth-child(4n + 2) .highlightlinks a:hover,
[class*="col-"]:nth-child(4n + 2) > .loop-color .highlightlinks a:hover,
.loop-color:nth-child(4n + 2) .highlightlinks a:hover {
	color: #3a3d44;
}

.loop-colors .owl-item:nth-child(4n + 2) .main_bg_color,
.loop-colors > *:nth-child(4n + 2) .main_bg_color,
[class*="col-"]:nth-child(4n + 2) > .loop-color .main_bg_color,
.loop-color:nth-child(4n + 2) .main_bg_color {
	background-color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .star-rating span:before,
.loop-colors > *:nth-child(4n + 2) .star-rating span:before,
[class*="col-"]:nth-child(4n + 2) > .loop-color .star-rating span:before,
.loop-color:nth-child(4n + 2) .star-rating span:before {
	color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2).bottom_color_border:after,
.loop-colors .owl-item:nth-child(4n + 2) .bottom_color_border:after,
.loop-colors > *:nth-child(4n + 2).bottom_color_border:after,
.loop-colors > *:nth-child(4n + 2) .bottom_color_border:after,
[class*="col-"]:nth-child(4n + 2) > .loop-color.bottom_color_border:after,
[class*="col-"]:nth-child(4n + 2) > .loop-color .bottom_color_border:after,
.loop-color:nth-child(4n + 2).bottom_color_border:after,
.loop-color:nth-child(4n + 2) .bottom_color_border:after {
	background-color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .owl-dot.active span,
.loop-colors .owl-item:nth-child(4n + 2) .owl-dot:hover span,
.loop-colors > *:nth-child(4n + 2) .owl-dot.active span,
.loop-colors > *:nth-child(4n + 2) .owl-dot:hover span,
[class*="col-"]:nth-child(4n + 2) > .loop-color .owl-dot.active span,
[class*="col-"]:nth-child(4n + 2) > .loop-color .owl-dot:hover span,
.loop-color:nth-child(4n + 2) .owl-dot.active span,
.loop-color:nth-child(4n + 2) .owl-dot:hover span {
	border-color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .price,
.loop-colors > *:nth-child(4n + 2) .price,
[class*="col-"]:nth-child(4n + 2) > .loop-color .price,
.loop-color:nth-child(4n + 2) .price {
	color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .add_to_cart,
.loop-colors > *:nth-child(4n + 2) .add_to_cart,
[class*="col-"]:nth-child(4n + 2) > .loop-color .add_to_cart,
.loop-color:nth-child(4n + 2) .add_to_cart {
	border-color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 2) .add_to_cart:not(:hover),
.loop-colors > *:nth-child(4n + 2) .add_to_cart:not(:hover),
[class*="col-"]:nth-child(4n + 2) > .loop-color .add_to_cart:not(:hover),
.loop-color:nth-child(4n + 2) .add_to_cart:not(:hover) {
	color: #fcb315;
}

.loop-colors .owl-item:nth-child(4n + 3) .more-link:not(:hover),
.loop-colors > *:nth-child(4n + 3) .more-link:not(:hover),
[class*="col-"]:nth-child(4n + 3) > .loop-color .more-link:not(:hover),
.loop-color:nth-child(4n + 3) .more-link:not(:hover) {
	color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .darklinks a:hover,
.loop-colors .owl-item:nth-child(4n + 3) .greylinks a:hover,
.loop-colors .owl-item:nth-child(4n + 3) .highlight,
.loop-colors > *:nth-child(4n + 3) .darklinks a:hover,
.loop-colors > *:nth-child(4n + 3) .greylinks a:hover,
.loop-colors > *:nth-child(4n + 3) .highlight,
[class*="col-"]:nth-child(4n + 3) > .loop-color .darklinks a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color .greylinks a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color .highlight,
.loop-color:nth-child(4n + 3) .darklinks a:hover,
.loop-color:nth-child(4n + 3) .greylinks a:hover,
.loop-color:nth-child(4n + 3) .highlight {
	color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) h1 a:hover, .loop-colors .owl-item:nth-child(4n + 3) h2 a:hover, .loop-colors .owl-item:nth-child(4n + 3) h3 a:hover, .loop-colors .owl-item:nth-child(4n + 3) h4 a:hover, .loop-colors .owl-item:nth-child(4n + 3) h5 a:hover, .loop-colors .owl-item:nth-child(4n + 3) h6 a:hover,
.loop-colors > *:nth-child(4n + 3) h1 a:hover,
.loop-colors > *:nth-child(4n + 3) h2 a:hover,
.loop-colors > *:nth-child(4n + 3) h3 a:hover,
.loop-colors > *:nth-child(4n + 3) h4 a:hover,
.loop-colors > *:nth-child(4n + 3) h5 a:hover,
.loop-colors > *:nth-child(4n + 3) h6 a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color h1 a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color h2 a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color h3 a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color h4 a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color h5 a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color h6 a:hover,
.loop-color:nth-child(4n + 3) h1 a:hover,
.loop-color:nth-child(4n + 3) h2 a:hover,
.loop-color:nth-child(4n + 3) h3 a:hover,
.loop-color:nth-child(4n + 3) h4 a:hover,
.loop-color:nth-child(4n + 3) h5 a:hover,
.loop-color:nth-child(4n + 3) h6 a:hover {
	color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .greylinks a:hover,
.loop-colors .owl-item:nth-child(4n + 3) .darklinks a:hover,
.loop-colors > *:nth-child(4n + 3) .greylinks a:hover,
.loop-colors > *:nth-child(4n + 3) .darklinks a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color .greylinks a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color .darklinks a:hover,
.loop-color:nth-child(4n + 3) .greylinks a:hover,
.loop-color:nth-child(4n + 3) .darklinks a:hover {
	color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .highlightlinks a,
.loop-colors > *:nth-child(4n + 3) .highlightlinks a,
[class*="col-"]:nth-child(4n + 3) > .loop-color .highlightlinks a,
.loop-color:nth-child(4n + 3) .highlightlinks a {
	color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .highlightlinks a:hover,
.loop-colors > *:nth-child(4n + 3) .highlightlinks a:hover,
[class*="col-"]:nth-child(4n + 3) > .loop-color .highlightlinks a:hover,
.loop-color:nth-child(4n + 3) .highlightlinks a:hover {
	color: #3a3d44;
}

.loop-colors .owl-item:nth-child(4n + 3) .main_bg_color,
.loop-colors > *:nth-child(4n + 3) .main_bg_color,
[class*="col-"]:nth-child(4n + 3) > .loop-color .main_bg_color,
.loop-color:nth-child(4n + 3) .main_bg_color {
	background-color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .star-rating span:before,
.loop-colors > *:nth-child(4n + 3) .star-rating span:before,
[class*="col-"]:nth-child(4n + 3) > .loop-color .star-rating span:before,
.loop-color:nth-child(4n + 3) .star-rating span:before {
	color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3).bottom_color_border:after,
.loop-colors .owl-item:nth-child(4n + 3) .bottom_color_border:after,
.loop-colors > *:nth-child(4n + 3).bottom_color_border:after,
.loop-colors > *:nth-child(4n + 3) .bottom_color_border:after,
[class*="col-"]:nth-child(4n + 3) > .loop-color.bottom_color_border:after,
[class*="col-"]:nth-child(4n + 3) > .loop-color .bottom_color_border:after,
.loop-color:nth-child(4n + 3).bottom_color_border:after,
.loop-color:nth-child(4n + 3) .bottom_color_border:after {
	background-color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .owl-dot.active span,
.loop-colors .owl-item:nth-child(4n + 3) .owl-dot:hover span,
.loop-colors > *:nth-child(4n + 3) .owl-dot.active span,
.loop-colors > *:nth-child(4n + 3) .owl-dot:hover span,
[class*="col-"]:nth-child(4n + 3) > .loop-color .owl-dot.active span,
[class*="col-"]:nth-child(4n + 3) > .loop-color .owl-dot:hover span,
.loop-color:nth-child(4n + 3) .owl-dot.active span,
.loop-color:nth-child(4n + 3) .owl-dot:hover span {
	border-color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .price,
.loop-colors > *:nth-child(4n + 3) .price,
[class*="col-"]:nth-child(4n + 3) > .loop-color .price,
.loop-color:nth-child(4n + 3) .price {
	color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .add_to_cart,
.loop-colors > *:nth-child(4n + 3) .add_to_cart,
[class*="col-"]:nth-child(4n + 3) > .loop-color .add_to_cart,
.loop-color:nth-child(4n + 3) .add_to_cart {
	border-color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 3) .add_to_cart:not(:hover),
.loop-colors > *:nth-child(4n + 3) .add_to_cart:not(:hover),
[class*="col-"]:nth-child(4n + 3) > .loop-color .add_to_cart:not(:hover),
.loop-color:nth-child(4n + 3) .add_to_cart:not(:hover) {
	color: #03a8cb;
}

.loop-colors .owl-item:nth-child(4n + 4) .more-link:not(:hover),
.loop-colors > *:nth-child(4n + 4) .more-link:not(:hover),
[class*="col-"]:nth-child(4n + 4) > .loop-color .more-link:not(:hover),
.loop-color:nth-child(4n + 4) .more-link:not(:hover) {
	color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .darklinks a:hover,
.loop-colors .owl-item:nth-child(4n + 4) .greylinks a:hover,
.loop-colors .owl-item:nth-child(4n + 4) .highlight,
.loop-colors > *:nth-child(4n + 4) .darklinks a:hover,
.loop-colors > *:nth-child(4n + 4) .greylinks a:hover,
.loop-colors > *:nth-child(4n + 4) .highlight,
[class*="col-"]:nth-child(4n + 4) > .loop-color .darklinks a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color .greylinks a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color .highlight,
.loop-color:nth-child(4n + 4) .darklinks a:hover,
.loop-color:nth-child(4n + 4) .greylinks a:hover,
.loop-color:nth-child(4n + 4) .highlight {
	color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) h1 a:hover, .loop-colors .owl-item:nth-child(4n + 4) h2 a:hover, .loop-colors .owl-item:nth-child(4n + 4) h3 a:hover, .loop-colors .owl-item:nth-child(4n + 4) h4 a:hover, .loop-colors .owl-item:nth-child(4n + 4) h5 a:hover, .loop-colors .owl-item:nth-child(4n + 4) h6 a:hover,
.loop-colors > *:nth-child(4n + 4) h1 a:hover,
.loop-colors > *:nth-child(4n + 4) h2 a:hover,
.loop-colors > *:nth-child(4n + 4) h3 a:hover,
.loop-colors > *:nth-child(4n + 4) h4 a:hover,
.loop-colors > *:nth-child(4n + 4) h5 a:hover,
.loop-colors > *:nth-child(4n + 4) h6 a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color h1 a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color h2 a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color h3 a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color h4 a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color h5 a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color h6 a:hover,
.loop-color:nth-child(4n + 4) h1 a:hover,
.loop-color:nth-child(4n + 4) h2 a:hover,
.loop-color:nth-child(4n + 4) h3 a:hover,
.loop-color:nth-child(4n + 4) h4 a:hover,
.loop-color:nth-child(4n + 4) h5 a:hover,
.loop-color:nth-child(4n + 4) h6 a:hover {
	color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .greylinks a:hover,
.loop-colors .owl-item:nth-child(4n + 4) .darklinks a:hover,
.loop-colors > *:nth-child(4n + 4) .greylinks a:hover,
.loop-colors > *:nth-child(4n + 4) .darklinks a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color .greylinks a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color .darklinks a:hover,
.loop-color:nth-child(4n + 4) .greylinks a:hover,
.loop-color:nth-child(4n + 4) .darklinks a:hover {
	color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .highlightlinks a,
.loop-colors > *:nth-child(4n + 4) .highlightlinks a,
[class*="col-"]:nth-child(4n + 4) > .loop-color .highlightlinks a,
.loop-color:nth-child(4n + 4) .highlightlinks a {
	color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .highlightlinks a:hover,
.loop-colors > *:nth-child(4n + 4) .highlightlinks a:hover,
[class*="col-"]:nth-child(4n + 4) > .loop-color .highlightlinks a:hover,
.loop-color:nth-child(4n + 4) .highlightlinks a:hover {
	color: #3a3d44;
}

.loop-colors .owl-item:nth-child(4n + 4) .main_bg_color,
.loop-colors > *:nth-child(4n + 4) .main_bg_color,
[class*="col-"]:nth-child(4n + 4) > .loop-color .main_bg_color,
.loop-color:nth-child(4n + 4) .main_bg_color {
	background-color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .star-rating span:before,
.loop-colors > *:nth-child(4n + 4) .star-rating span:before,
[class*="col-"]:nth-child(4n + 4) > .loop-color .star-rating span:before,
.loop-color:nth-child(4n + 4) .star-rating span:before {
	color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4).bottom_color_border:after,
.loop-colors .owl-item:nth-child(4n + 4) .bottom_color_border:after,
.loop-colors > *:nth-child(4n + 4).bottom_color_border:after,
.loop-colors > *:nth-child(4n + 4) .bottom_color_border:after,
[class*="col-"]:nth-child(4n + 4) > .loop-color.bottom_color_border:after,
[class*="col-"]:nth-child(4n + 4) > .loop-color .bottom_color_border:after,
.loop-color:nth-child(4n + 4).bottom_color_border:after,
.loop-color:nth-child(4n + 4) .bottom_color_border:after {
	background-color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .owl-dot.active span,
.loop-colors .owl-item:nth-child(4n + 4) .owl-dot:hover span,
.loop-colors > *:nth-child(4n + 4) .owl-dot.active span,
.loop-colors > *:nth-child(4n + 4) .owl-dot:hover span,
[class*="col-"]:nth-child(4n + 4) > .loop-color .owl-dot.active span,
[class*="col-"]:nth-child(4n + 4) > .loop-color .owl-dot:hover span,
.loop-color:nth-child(4n + 4) .owl-dot.active span,
.loop-color:nth-child(4n + 4) .owl-dot:hover span {
	border-color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .price,
.loop-colors > *:nth-child(4n + 4) .price,
[class*="col-"]:nth-child(4n + 4) > .loop-color .price,
.loop-color:nth-child(4n + 4) .price {
	color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .add_to_cart,
.loop-colors > *:nth-child(4n + 4) .add_to_cart,
[class*="col-"]:nth-child(4n + 4) > .loop-color .add_to_cart,
.loop-color:nth-child(4n + 4) .add_to_cart {
	border-color: #F15931;
}

.loop-colors .owl-item:nth-child(4n + 4) .add_to_cart:not(:hover),
.loop-colors > *:nth-child(4n + 4) .add_to_cart:not(:hover),
[class*="col-"]:nth-child(4n + 4) > .loop-color .add_to_cart:not(:hover),
.loop-color:nth-child(4n + 4) .add_to_cart:not(:hover) {
	color: #F15931;
}

/* 
** Intro Section Styles
*/
@media (min-width: 992px) {
	.page_mainslider.ds.light_ms_bg_color {
		background-color: #ffffff;
	}
}

.page_mainslider.ds.light_bg_color {
	background-color: #ffffff;
}

.intro_section .rounded-container {
	width: 120%;
	transform: translateX(-8.33333%);
	position: relative;
	border-radius: 0 0 50% 50%;
	overflow: hidden;
	max-height: 100%;
}

.intro_section .rounded-container img {
	width: 83.3333333333%;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.slide-image-wrap .rounded-container {
	left: 50%;
	transform: translateX(-50%);
}

.all-scr-cover .slide-image-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-size: cover;
	background-position: center;
}

.slide-image-wrap img {
	visibility: hidden;
}

.intro_section {
	position: relative;
	clear: both;
	max-width: 100%;
	overflow: hidden;
}

.intro_section > img {
	width: 100%;
}

.intro_section.ds {
	color: #ffffff;
}

.intro_section .slides {
	display: -ms-flexbox;
	display: flex;
}

.intro_section .slides > li {
	position: relative;
	overflow: hidden;
}

.intro_section .flexslider:before, .intro_section .flexslider:after {
	content: '';
	display: block;
	clear: both;
}

.intro_section .flexslider .intro-layer img {
	width: auto;
	display: inline-block;
}

.intro_section .flex-control-nav {
	position: absolute;
}

.intro_section.blog_slider,
.blog-slider .intro_section {
	margin-bottom: 40px;
}

.intro_section.blog_slider.all-scr-cover .slide_description_wrapper,
.blog-slider .intro_section.all-scr-cover .slide_description_wrapper {
	padding: 20% 0;
}

@media (min-width: 1200px) {
	.intro_section.blog_slider,
  .blog-slider .intro_section {
		margin-bottom: 60px;
	}

	.intro_section.blog_slider .slides > li,
  .blog-slider .intro_section .slides > li {
		height: 600px;
	}
}

.intro_section.blog_slider img,
.intro_section.blog_slider .slide-image-wrap,
.blog-slider .intro_section img,
.blog-slider .intro_section .slide-image-wrap {
	opacity: 0.4;
}

.intro_section.blog_slider .flex-direction-nav,
.blog-slider .intro_section .flex-direction-nav {
	display: none;
}

.intro_section.blog_slider .flex-control-nav,
.blog-slider .intro_section .flex-control-nav {
	bottom: 10%;
}

.intro_section.blog_slider h2,
.blog-slider .intro_section h2 {
	margin-top: 0;
	margin-bottom: 0;
}

.intro_section .flexslider .container,
.intro_section img + .container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.intro_section [class*="col-"] {
	position: static;
	min-height: 0;
}

.intro_section .slide_description_wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 100%;
	font-size: 0;
	z-index: 5;
	padding: 30px 0;
}

@media (min-width: 600px) {
	.boxed .intro_section .slide_description_wrapper {
		padding-left: 60px;
		padding-right: 60px;
	}
}

@media (min-width: 992px) {
	.intro_section .slide_description_wrapper {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.intro_section .slide_description {
	font-size: 20px;
	line-height: 1.5em;
	font-family: 'Ubuntu', sans-serif;
}

.intro_section .slide_description > * {
	visibility: hidden;
}

.intro_section .slide_description_wrapper:before,
.intro_section .slide_description {
	display: inline-block;
	vertical-align: middle;
}

.intro_section .slide_description_wrapper:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
}

.intro_section .slide_description_wrapper.slide_text_top:before {
	vertical-align: top;
}

.intro_section h2 {
	font-size: 40px;
	line-height: 0.9em;
	margin-bottom: 0.3em;
	margin-top: 0.15em;
}

.intro_section hr {
	background-color: #fff;
	margin-bottom: 30px;
}

.intro_section .slide_buttons {
	margin: 30px -10px 0;
}

.intro_section .slide_buttons .theme_button {
	vertical-align: top;
	margin: 0 10px 10px;
}

@media (min-width: 1200px) {
	.intro_section .slide_buttons {
		margin-top: 50px;
	}
}

.intro_section .dots-disabled .nav-container {
	display: none;
}

.intro_section.all-scr-cover .container,
.intro_section.all-scr-cover img + .container {
	position: relative;
}

.intro_section.all-scr-cover .slide_description_wrapper {
	position: relative;
	padding: 15% 0;
}

.intro_section .nav-container {
	display: none;
}

@media (min-width: 768px) {
	.intro_section h2 {
		font-size: 60px;
	}

	.transparent_wrapper + .page_mainslider .slide_description_wrapper {
		padding-top: calc(10% + 150px);
	}

	.intro_section.all-scr-cover .slide_description_wrapper {
		padding: 15% 30px;
	}
}

@media (min-width: 992px) {
	.intro_section .slide_description {
		font-size: 24px;
	}

	.intro_section .flex-control-nav {
		bottom: 8%;
	}

	.intro_section.all-scr-cover .slide_description_wrapper {
		padding: 12% 30px;
	}

	.transparent_wrapper + .page_mainslider .slide_description_wrapper {
		padding-top: calc(19% + 50px);
	}
}

@media (min-width: 1200px) {
	.intro_section h2 {
		font-size: 80px;
	}

	.intro_section.all-scr-cover .slide_description_wrapper {
		padding: 10% 30px;
	}

	.intro_section.bottom-overlap-teasers .slide_description_wrapper {
		padding-bottom: calc(10% + 194px);
	}

	.intro_section.page_mainslider .flex-direction-nav a {
		top: calc(50% - 105px);
	}

	.transparent_wrapper + .page_mainslider .slide_description_wrapper {
		padding-top: calc(10% + 100px);
	}
}

@media (min-width: 1400px) {
	.intro_section.all-scr-cover .slide_description_wrapper {
		padding: 16% 30px;
	}

	.intro_section.bottom-overlap-teasers .slide_description_wrapper {
		padding-bottom: 25%;
	}

	.transparent_wrapper + .page_mainslider .slide_description_wrapper {
		padding-top: calc(16% + 100px);
	}
}

@media (min-width: 1600px) {
	.intro_section.all-scr-cover .slide_description_wrapper {
		padding: 18.5% 30px;
	}

	.intro_section.bottom-overlap-teasers .slide_description_wrapper {
		padding-bottom: calc(18.5% + 200px);
	}

	.intro_section h2 {
		font-size: 100px;
	}

	.transparent_wrapper + .page_mainslider .slide_description_wrapper {
		padding-top: calc(19% + 150px);
	}
}

@media (max-width: 991px) {
	.intro_section {
		text-align: center;
	}

	.intro_section .theme-divider.big {
		margin-right: auto;
		margin-left: auto;
	}

	.intro_section .theme-divider.big:before {
		width: 40%;
		left: 50%;
		transform: translateX(-50%);
	}

	.intro_section .nav-container {
		padding: 0;
	}

	.intro_section .flex-control-nav,
  .intro_section .flex-custom-nav {
		position: static;
		text-align: center;
		margin-top: 0;
		margin-bottom: 20px;
	}

	.intro_section.all-scr-cover .flex-control-nav,
  .intro_section.all-scr-cover .flex-custom-nav {
		position: absolute;
		bottom: 0;
	}

	.intro_section .flexslider .container {
		position: static;
		width: auto;
		max-width: 100%;
	}

	.intro_section .slide_description_wrapper:before {
		display: none;
	}

	.intro_section .slide_description {
		display: block;
		margin: auto;
		text-align: center;
	}

	.intro_section .slide_description_wrapper {
		position: static;
	}
}

@media (max-width: 767px) {
	.intro_section .flex-direction-nav a {
		top: 40px;
	}

	.intro_section .flex-direction-nav {
		display: none;
	}
}

/*
** Main Menu styles
*/
.sf-menu a {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	font-family: 'Ubuntu', sans-serif;
}

/* Desktop menu - (min 992px) styles */
@media (min-width: 1200px) {
	.mainmenu_wrapper {
		position: relative;
	}

	.header_darkgrey.with_menu_icon .mainmenu_wrapper:after {
		color: #ffffff;
	}
  /* menu layout */
	.sf-menu, .sf-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.sf-menu li {
		position: relative;
	}

	.sf-menu li > ul {
		position: absolute;
		display: none;
		top: 100%;
		z-index: 99;
	}

	.sf-menu > li > ul {
		left: 50%;
		transform: translateX(-50%);
	}

	.sf-menu li:hover > ul,
  .sf-menu li.sfHover > ul {
		display: block;
	}

	.sf-menu a {
		display: block;
		position: relative;
	}

	.sf-menu li > ul > li > ul {
		top: -21px;
		left: 100%;
		margin-left: 1px;
	}
  /* menu skin */
	.sf-menu a {
		padding: 5px 10px;
		text-decoration: none;
		zoom: 1;
    /* IE7 */
		transition-property: color, background-color, border-color, padding-top, padding-bottom;
	}

	.sf-menu a {
		color: #787878;
	}

	.sf-menu li {
		white-space: nowrap;
    /* no need for Supersubs plugin */
		*white-space: normal;
    /* ...unless you support IE7 (let it wrap) */
		transition: background .2s;
	}

	.sf-menu > li {
		display: inline-block;
		position: relative;
		vertical-align: middle;
		margin: 0 -2px;
	}

	.sf-menu > li:first-child {
		margin-left: 0;
	}

	.sf-menu > li:last-child {
		margin-right: 0;
	}

	.sf-menu ul li > a:before {
		content: "";
		position: absolute;
		top: 0;
		left: 30px;
		right: 30px;
		height: 1px;
		background-color: rgba(120, 120, 120, 0.2);
	}

	.sf-menu ul li:first-child > a:before {
		display: none;
	}

	.mega-menu .mega-menu-col > a:before {
		display: none;
	}

	.sf-menu > li > a {
		padding-top: 25px;
		padding-bottom: 25px;
		padding-right: 0;
		padding-left: 0;
	}

	.sf-menu li:hover,
  .sf-menu li.sfHover {
    /* only transition out, not in */
		transition: none;
	}
  /* second and next levels */
	.sf-menu > li .mega-menu,
  .sf-menu li > ul {
		margin-top: 10px;
		text-align: center;
		list-style: none;
		padding: 20px 0;
		background-color: #ffffff;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
		min-width: 10em;
    /* allow long menu items to determine submenu width */
		border-radius: 0;
		border-bottom: 3px solid #F15931;
	}

	.sf-menu > li .mega-menu > li > ul,
  .sf-menu li > ul > li > ul {
		z-index: -1;
	}

	.sf-menu > li .mega-menu ul {
		background: #ffffff;
	}

	.sf-menu ul a {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 1200px) and (min-width: 1600px) {
	.sf-menu ul a {
		padding-right: 40px;
		padding-left: 40px;
	}
}

@media (min-width: 1200px) {
	.sf-menu .mega-menu ul {
		box-shadow: none;
		padding: 0;
	}

	.sf-menu .mega-menu ul a {
		padding-left: 0;
		padding-right: 0;
	}
/*
	.sf-menu ul a:hover,
  .sf-menu ul li.active > a {
		color: #F15931;
	}*/
  /* third level */
	.sf-menu ul ul {
		margin-top: 0;
	}

	.bordered_items .sf-menu > li {
		padding: 0;
	}

	.bordered_items .sf-menu > li + li:before {
		content: '';
		width: 1px;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.23);
	}

	.header_white .sf-menu > li > a {
		color: #3a3d44;
	}

	.header_white .sf-menu > li.active > a,
  .header_white .sf-menu > li.sfHover > a,
  .header_white .sf-menu .current-menu-item > a,
  .header_white .sf-menu .current-menu-ancestor > a {
	/*	color: #F15931;*/
	}
/*
	.header_white .sf-menu > li > a:hover {
		color: #F15931;
	}
*/
	.header_white .sf-menu > li + li:before {
		background-color: #e4e4e4;
	}

	.header_darkgrey .sf-menu ul li > a:before {
		background-color: rgba(255, 255, 255, 0.2);
	}


	.header_darkgrey .sf-menu > li .mega-menu,
  .header_darkgrey .sf-menu li > ul {
		background: linear-gradient(to top, #F15931 3px, #3a3d44 3px);
	}

	.header_darkgrey .sf-menu > li .mega-menu a,
  .header_darkgrey .sf-menu li > ul a {
		color: #ffffff;
	}

	.header_darkgrey .sf-menu > li .mega-menu a:hover,
  .header_darkgrey .sf-menu li > ul a:hover {
		color: #F15931;
	}

	.header_darkgrey .sf-menu > li .mega-menu ul {
		background: #3a3d44;
	}

	.header_darkgrey .sf-menu > li > a {
		color: #ffffff;
	}

	.header_darkgrey .sf-menu > li.active > a,
  .header_darkgrey .sf-menu .current-menu-item > a,
  .header_darkgrey .sf-menu .current-menu-ancestor > a {
		color: #F15931;
	}

	.header_darkgrey .sf-menu > li > a:hover {
		color: #F15931;
	}

	.header_color ul a {
		color: #ffffff;
	}

	.header_color ul a:hover,
  .header_color ul li.active > a,
  .header_color .sf-menu .current-menu-item > a,
  .header_color .sf-menu .current-menu-ancestor > a {
		color: #3a3d44;
	}

	.header_color .sf-menu ul a {
		color: #3a3d44;
	}

	.thin_header .sf-menu > li > a {
		padding-top: 25px;
		padding-bottom: 25px;
	}
}

/* -lg- paddings */
@media (min-width: 1200px) {
	.sf-menu > li > a {
		margin-left: 12px;
		margin-right: 12px;
	}

	.bordered_items .sf-menu > li > a {
		padding: 20px 30px;
	}
}

@media (min-width: 1400px) {
	.sf-menu > li > a {
		margin-left: 20px;
		margin-right: 20px;
	}

	.tall_header:not(.affix) .sf-menu > li > a {
		padding-top: 37px;
		padding-bottom: 37px;
	}

	.page_header:not(.affix):not(.bordered_items):not(.thin_header) .sf-menu > li > a {
		padding-top: 35px;
		padding-bottom: 35px;
	}
}

@media (min-width: 1600px) {
	.sf-menu > li > a {
		margin-left: 30px;
		margin-right: 30px;
	}

	.page_header:not(.affix):not(.bordered_items):not(.thin_header) .sf-menu > li > a {
		padding-top: 45px;
		padding-bottom: 45px;
	}

	.tall_header:not(.affix) .sf-menu > li > a {
		padding-top: 55px;
		padding-bottom: 55px;
	}

	.sf-menu a {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.sf-menu ul li > a:before {
		left: 40px;
		right: 40px;
	}

	.bordered_items .sf-menu > li > a {
		padding: 25px 40px;
	}
}

.sf-menu i[class] {
	margin-right: 7px;
}

.sf-menu .header-button {
	font-size: 14px;
}

.sf-menu .header-button i[class] {
	margin-right: 0;
}

/* hidden menu items */
@media (min-width: 992px) {
	.sf-menu .sf-md-hidden {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.sf-more-li {
		display: none;
	}
}

/* logo in center of menu */
.sf-menu > .sf-logo-li {
	visibility: hidden;
}

/* Mobile menu toggler */
@media (max-width: 1199px) {
	.toggle_menu {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -30px;
		margin-top: -30px;
		width: 60px;
		height: 60px;
		cursor: pointer;
		z-index: 1001;
		visibility: visible;
	}

	.toggler_right .toggle_menu {
		right: 0;
		left: auto;
	}

	.toggler_left .toggle_menu {
		right: auto;
		left: 0;
		margin-left: 0;
	}
}

@media (max-width: 1199px) and (max-width: 767px) {
	.toggler_xs_right .toggle_menu {
		right: 0;
		left: auto;
	}
}

@media (max-width: 1199px) and (max-width: 499px) {
	.toggler_xxs_right .toggle_menu {
		right: 0;
		left: auto;
	}
}

@media (max-width: 1199px) {
	.relative_toggler .toggle_menu {
		position: relative;
		display: block;
		top: auto;
		left: auto;
		margin: 0;
	}

	.relative_toggler .text-center .toggle_menu {
		margin: auto;
	}

	.ls .toggle_menu span,
  .ls .toggle_menu span:before,
  .ls .toggle_menu span:after,
  .header_white .toggle_menu span,
  .header_white .toggle_menu span:before,
  .header_white .toggle_menu span:after {
		background-color: #3a3d44;
	}

	.intro_section + .page_header_wrapper .toggle_menu span,
  .intro_section + .page_header_wrapper .toggle_menu span:before,
  .intro_section + .page_header_wrapper .toggle_menu span:after {
		background-color: #ffffff;
	}

	.intro_section + .page_header_wrapper .affix .toggle_menu span,
  .intro_section + .page_header_wrapper .affix .toggle_menu span:before,
  .intro_section + .page_header_wrapper .affix .toggle_menu span:after {
		background-color: #3a3d44;
	}

	.intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span,
  .intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span:before,
  .intro_section + .page_header_wrapper .affix .toggle_menu.mobile-active span:after {
		background-color: #ffffff;
	}

	.page_toplogo .toggle_menu {
		position: fixed;
		top: 0;
		left: auto;
		right: 0;
		margin: 0;
		z-index: 10;
		background-color: #ffffff;
	}

	.header_logo_center .toggle_menu {
		right: 0;
		left: auto;
	}

	.toggle_menu:hover span:before {
		top: -9px;
	}

	.toggle_menu:hover span:after {
		top: 9px;
	}

	.toggle_menu:before,
  .toggle_menu span,
  .toggle_menu span:before,
  .toggle_menu span:after {
		display: block;
		width: 30px;
		position: absolute;
		background-color: #ffffff;
		content: '';
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
		height: 2px;
	}

	.page_toplogo .toggle_menu:before, .page_toplogo
  .toggle_menu span, .page_toplogo
  .toggle_menu span:before, .page_toplogo
  .toggle_menu span:after {
		background-color: #3a3d44;
	}

	.toggle_menu:before,
  .toggle_menu span {
		left: 15px;
		top: 50%;
	}

	.toggle_menu span:before {
		opacity: 1;
		top: -8px;
	}

	.toggle_menu span:after {
		opacity: 1;
		top: 8px;
	}

	.toggle_menu.mobile-active {
		background-color: #3a3d44;
		border: none;
		left: auto;
		top: 0;
		right: 0;
		position: fixed;
		margin: 0;
		z-index: 3000;
	}

	.toggle_menu.mobile-active:before {
		background-color: #ffffff;
		transform: rotate(45deg);
	}

	.toggle_menu.mobile-active span {
		background-color: #ffffff;
		transform: rotate(-45deg);
	}

	.toggle_menu.mobile-active span:before,
  .toggle_menu.mobile-active span:after {
		opacity: 0;
	}
}

/* Mobile Menu (max 991px) styles */
@media (max-width: 1199px) {
	.mainmenu_wrapper {
    /*position: relative;*/
		position: fixed;
		visibility: hidden;
		z-index: 10;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: transparent;
		opacity: 0;
		transition: all 0.3s ease 0s;
	}

	.mobile-active .mainmenu_wrapper {
		visibility: visible;
		opacity: 1;
		background-color: #111;
		background-color: #1f232b;
	}

	.nav-justified > li > a {
		text-align: left;
	}

	.sf-menu {
		position: fixed;
		overflow: auto;
		background-color: #3a3d44;
		padding: 20px 30px 20px 10px;
		top: 0px;
		left: 0px;
		bottom: 0px;
		right: 100%;
		font-size: 1.1em;
		text-transform: uppercase;
		font-weight: 400;
		word-wrap: break-word;
		text-align: left;
		transition: all 0.4s ease-out 0s;
		opacity: 0;
	}

	.mobile-active .sf-menu {
		opacity: 1;
		right: 30px;
	}

	.sf-menu > li > a,
  .sf-menu a {
		color: #ffffff;
	}

	.sf-menu ul {
		list-style: none;
		padding-left: 10px;
	}

	.sf-menu ul li a {
		display: block;
		padding: 10px;
		font-weight: 300;
		font-size: 16px;
		text-transform: none;
	}

	.sf-menu ul li a:before {
		content: '-';
		padding-right: 5px;
	}

	.sf-menu ul ul li a:before {
		content: '--';
		padding-right: 5px;
	}
  /* arrows (for all except IE7) */
	.sf-arrows .sf-with-ul {
		padding-right: 3.5em;
	}
  /* styling for both css and generated arrows */
	.sf-arrows .sf-with-ul:after {
		font-family: 'FontAwesome';
		content: '\f107';
		position: absolute;
		right: 30px;
		height: 0;
		width: 0;
		font-size: 20px;
	}
}

/* Mega Menu */
@media screen and (min-width: 992px) {
	.mainmenu_wrapper ul .mega-menu .mega-menu-col {
		padding: 0;
	}

	.mainmenu_wrapper ul .mega-menu .mega-menu-col a {
		padding-right: 30px;
		padding-left: 30px;
	}

	.mainmenu_wrapper ul .mega-menu ul {
		min-width: auto;
		border: none;
	}

	.mainmenu_wrapper .menu-item-has-mega-menu {
		position: relative;
	}

	.mainmenu_wrapper .mega-menu {
		display: none;
		position: absolute;
		width: 940px;
		top: 100%;
		z-index: 10;
	}

	.mainmenu_wrapper .menu-item-has-mega-menu:hover .mega-menu {
		display: block;
	}
  /* row */
	.mainmenu_wrapper .mega-menu-row {
		position: static;
		display: table;
		table-layout: fixed;
	}

	.mainmenu_wrapper .mega-menu-row ul {
		position: static;
		display: block;
		border-top: none;
	}
  /* column */
	.mainmenu_wrapper .mega-menu-col {
		display: table-cell;
		width: 2000px;
		padding: 0 20px;
		border-left: 1px solid #e4e4e4;
    /* overrides from settings / styling / mega-menu */
	}

	.mainmenu_wrapper .mega-menu-col > a {
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 700;
	}

	.mainmenu_wrapper .mega-menu-col:first-child {
		border-left: none;
	}

	.mainmenu_wrapper ul ul .mega-menu {
		width: auto;
		top: -20px;
		margin-left: 1px;
		display: block;
		width: auto;
	}

	.mainmenu_wrapper ul ul .mega-menu li {
		display: block;
		width: auto;
		border: none;
	}

	.header_darkgrey .mainmenu_wrapper .mega-menu-col {
		border-left-color: rgba(255, 255, 255, 0.2);
	}
}

@media screen and (min-width: 1200px) {
	.mainmenu_wrapper .mega-menu {
		width: 1170px;
	}

	.mainmenu_wrapper ul .mega-menu ul {
		min-width: 10em;
	}
}

@media screen and (max-width: 991px) {
	.mainmenu_wrapper .mega-menu {
		position: static;
	}
}

/*
**Subpages
*/
.edit-link {
	text-align: right;
	display: block;
}

/*404*/
.not_found {
	font-size: 160px;
	font-weight: 500;
	line-height: 0.8;
	margin-bottom: 0;
	font-family: 'Ubuntu', sans-serif;
}

.not_found + h1 {
	font-size: 30px;
	margin: -40px 0 30px;
}

.not_found + h1 + p {
	margin: 0 0 10px;
}

.not_found + h1 + p + .widget_search {
	max-width: 410px;
	margin: 0 auto 10px;
}

@media (min-width: 768px) {
	.not_found {
		font-size: 200px;
	}

	.not_found + h1 {
		font-size: 40px;
	}
}

/*Blog*/
article iframe {
	margin-bottom: -8px;
}

.entry-header {
	margin-bottom: 15px;
}

@media (min-width: 768px) {
	.entry-header {
		margin-bottom: 20px;
	}
}

.side-item .entry-header {
	margin-bottom: 15px;
}

@media (min-width: 768px) {
	.side-item .entry-header {
		margin-bottom: 20px;
	}
}

.entry-header:last-child {
	margin-bottom: 0;
}

.single .type-post .entry-header {
	display: none;
}

.entry-header + .vertical-item {
	margin-top: 60px;
}

.entry-meta + .entry-title,
.catogories-links + .entry-title {
	margin-top: 5px;
}

.round_button + .entry-title {
	margin-top: 0;
}

.entry-title + .entry-meta {
	margin-top: 0;
}

.entry-title + .small-text {
	display: block;
	margin-top: -5px;
}

.entry-title:last-child {
	margin-bottom: 0;
}

.featured-post + .entry-title {
	margin-top: 0;
}

.categories-links + .entry-title {
	margin-top: 0;
}

.entry-title-divider {
	width: 50px;
	height: 3px;
	margin-top: 15px;
	margin-bottom: 10px;
}

.text-center .entry-title-divider {
	margin-right: auto;
	margin-left: auto;
}

h1.entry-title {
	font-size: 30px;
}

.entry-meta + h1.entry-title {
	margin-top: 5px;
}

h1.entry-title.small {
	font-size: 24px;
}

h3.entry-title {
	font-size: 28px;
	margin-bottom: 18px;
}

h3.entry-title.small {
	font-size: 20px;
}

@media (min-width: 1200px) {
	h3.entry-title {
		font-size: 32px;
	}

	h3.entry-title.small {
		font-size: 24px;
	}
}

h4.entry-title {
	font-size: 20px;
}

.entry-meta {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	font-family: 'Ubuntu', sans-serif;
}

.entry-header .entry-meta {
	line-height: 1.5em;
}

.entry-title + .entry-meta {
	margin-top: -5px;
}

.entry-meta i {
	min-width: 1.5em;
}

.entry-meta + .entry-title {
	margin-top: 10px;
}

@media (min-width: 992px) {
	.side-meta .item-content {
		display: -ms-flexbox;
		display: flex;
	}

	.side-meta .entry-meta {
		margin: 0;
		width: 23%;
		padding-right: 20px;
	}

	.side-meta .entry-meta > * {
		display: block;
		margin: 0;
	}

	.side-meta .entry-content-wrap {
		width: 78%;
	}

	.col-md-8 .side-meta .entry-meta {
		width: 26%;
	}

	.col-md-8 .side-meta .entry-content-wrap {
		width: 74%;
	}
}

.entry-excerpt {
	font-size: 18px;
	font-weight: 400;
}

.tag-links:last-child {
	margin-bottom: -10px;
}

article.post + article.post,
.search article + article,
.archive article + article {
	margin-top: 30px;
}

article.post + article.post.topmargin_30,
.search article + article.topmargin_30,
.archive article + article.topmargin_30 {
	margin-top: 30px;
}

@media (min-width: 992px) {
	article.post + article.post,
  .search article + article,
  .archive article + article {
		margin-top: 60px;
	}
}

/* mosaic blog */
.mosaic-post p {
	margin-bottom: 0;
}

.mosaic-post .post-social-links {
	margin: -1px;
	padding: 18px 40px 17px;
}

.entry-title + .entry-date {
	margin-bottom: 15px;
}

.entry-tags {
	margin-top: 30px;
}

.entry-tags .theme_button {
	margin-right: 6px;
}

.entry-tags span + span {
	margin-left: 14px;
}

.entry-tags i {
	font-size: 14px;
	margin-right: 5px;
}

.entry-content:before,
.entry-content:after {
	display: block;
	clear: both;
	content: '';
	width: 0;
	height: 0;
}

.entry-thumbnail {
	position: relative;
}

.entry-thumbnail .entry-thumbnail-carousel {
	margin-bottom: 0;
}

.entry-thumbnail .entry-thumbnail-carousel .owl-dots {
	position: absolute;
	bottom: 40px;
	text-align: center;
	left: 0;
	right: 0;
}

.entry-thumbnail[class*='col-'] {
	margin-bottom: 0;
}

/* share buttons */
.post-share {
	display: -ms-flexbox;
	display: flex;
}

.post-share .social-icon {
	-ms-flex-positive: 1;
	flex-grow: 1;
	margin-bottom: 0;
}

.post-share .social-icon + .social-icon {
	margin-left: 1px !important;
}

@media (min-width: 992px) {
	.post-share a.color-bg-icon {
		padding: 22px 14px;
	}
}

.entry-meta-corner {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	padding: 11px 3px;
	min-width: 60px;
	text-align: center;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	padding: 0 10px;
}

.entry-meta-corner a {
	color: #ffffff;
}

.entry-meta-corner a:hover {
	color: #3a3d44;
}

.entry-meta-corner span {
	display: block;
	line-height: 1;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
}

.entry-meta-corner .small-text {
	margin-right: -3px;
}

.entry-meta-corner span:last-child {
	font-size: 24px;
	font-weight: 500;
}

.rounded .entry-meta-corner {
	border-radius: 5px 0 5px 0;
}

.item-title + .categories-links {
	display: block;
	margin: 0 0 40px;
}

@media (min-width: 1200px) {
	.item-title + .categories-links {
		margin-top: -43px;
	}
}

.categories-links a,
.categories-links span {
	margin-right: 6px;
	font-weight: 500;
	text-transform: uppercase;
}

.categories-links a:last-child,
.categories-links span:last-child {
	margin-right: 0;
}

p.categories-links {
	margin-bottom: 7px;
}

.item-button {
	margin-top: 40px;
}

.st_facebook_hcount,
.st_twitter_hcount,
.st_googleplus_hcount {
	position: relative;
	text-align: right;
}

.st_facebook_hcount span,
.st_twitter_hcount span,
.st_googleplus_hcount span {
	-ms-box-sizing: content-box;
	box-sizing: content-box;
}

/*single post*/
.single-post article > .entry-thumbnail {
	margin-bottom: 40px;
}

.single-post .video-entry-thumbnail .entry-thumbnail {
	margin-bottom: 0;
}

.author-meta {
	margin-top: 50px;
}

.author-meta .entry-header {
	margin-bottom: 30px;
}

@media (min-width: 992px) {
	.author-meta {
		margin-top: 70px;
	}

	.author-meta .col-md-4 {
		width: 37%;
	}

	.author-meta .col-md-8 {
		width: 63%;
	}
}

/*comments*/
* + .comments-area {
	margin-top: 55px;
}

@media (min-width: 992px) {
	* + .comments-area {
		margin-top: 75px;
	}
}

.page-nav + .comments-area {
	margin-top: 25px;
}

.comments-area > h3 {
	text-align: center;
}

@media (min-width: 1200px) {
	.comments-area > h3 {
		margin-bottom: 40px;
	}
}

.comments-area li.comment {
	list-style: none;
}

.comments-area .media {
	position: relative;
}

.comment .comment-author {
	vertical-align: top;
	margin-right: 10px;
}

.comment .comment-author img {
	border-radius: 50%;
	max-width: 90px;
	border: 5px solid #e4e4e4;
}

.comment .comment-body {
	vertical-align: top;
	position: relative;
}

@media (min-width: 500px) {
	.comment .comment-body {
		width: calc(100% - 100px);
	}
}

@media (min-width: 992px) {
	.comment .comment-author {
		padding-right: 30px;
	}
}

article.comment {
	position: relative;
}

article.comment:before {
	content: "";
	position: absolute;
	top: 0;
	margin-top: -17px;
	left: 50%;
	height: 1px;
	width: 100vw;
	transform: translateX(-50%);
	background-color: #e4e4e4;
}

@media (min-width: 500px) {
	article.comment {
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 1200px) {
	article.comment:before {
		margin-top: -37px;
	}
}

.comment-list {
	margin: 0;
	padding: 15px 0;
	position: relative;
	list-style: none;
	overflow: hidden;
	border: 1px solid #e4e4e4;
	border-width: 1px 0;
}

@media (min-width: 1200px) {
	.comment-list {
		padding: 35px 0;
	}
}

.comment-list ol {
	padding-left: 25px;
}

.comment-list li.comment {
	margin-top: 30px;
}

@media (min-width: 1200px) {
	.comment-list li.comment {
		margin-top: 70px;
	}
}

.comment-list > li.comment:first-child {
	margin-top: 0;
}

@media (min-width: 992px) {
	.comment-list ol {
		padding-left: 50px;
	}
}

.comment-meta {
	margin-bottom: 0;
	padding: 5px 0 15px 0;
}

@media (min-width: 500px) {
	.comment-meta {
		padding-top: 21px;
		padding-right: 25px;
	}
}

.comment-meta .comment-metadata {
	display: block;
}

.comment-meta .comment-date {
	display: block;
	font-size: 12px;
	line-height: 1.8em;
	text-transform: uppercase;
	font-weight: 400;
	font-family: 'Ubuntu', sans-serif;
}

.comment-meta .author_url {
	font-family: 'Ubuntu', sans-serif;
	font-size: 18px;
	line-height: 1;
	font-weight: 400;
}

@media (min-width: 992px) {
	.comment-meta .author_url {
		font-size: 24px;
	}
}

.reply {
	display: block;
	margin-top: 10px;
}

.reply a {
	color: #787878;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	text-align: center;
}

.reply a i {
	padding-right: 5px;
}

.reply a:hover {
	color: #F15931;
}

.tab-content .reply a {
	background-color: #fff;
}

@media (min-width: 500px) {
	.reply {
		margin-top: 0;
	}

	.reply a {
		position: absolute;
		right: 0;
		top: 20px;
	}
}

.comments-wrapper {
	margin-top: 30px;
}

.page-nav + .comments-wrapper {
	margin-top: 0;
}

.comment-respond {
	margin-top: 50px;
}

.comment-respond p {
	margin-bottom: 10px;
	position: relative;
}

.comment-list + .comment-respond,
.comment + .comment-respond {
	margin-top: 70px;
}

.comment-form.muted_background {
	padding: 30px 30px 20px;
}

.comment-form p {
	margin-bottom: 0;
}

.comment-form label[for] {
	display: none;
}

.comment-form .theme_button {
	margin-right: 10px;
}

@media (max-width: 500px) {
	.comment-respond i.pull-left {
		display: none;
	}

	.comment-respond .comment-form {
		margin-left: 0;
	}
}

.comment-navigation {
	margin-top: 40px;
}

.comment-navigation .pagination > li > a,
.comment-navigation .pagination > li > span {
	padding: 5px 10px;
}

.page-nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 30px;
}

.page-nav .nav-item {
	height: 100%;
}

.page-nav .small-text {
	font-size: 12px;
}

.page-nav h4.entry-title {
	margin-top: 0;
	font-size: 24px;
}

.page-nav .darkgrey_bg.ms:after {
	background-color: rgba(31, 35, 43, 0.8);
}

@media (min-width: 992px) {
	.page-nav {
		margin-top: 50px;
	}
}

.page-nav:before, .page-nav:after {
	display: none;
}

.page-links > *:not(:first-child) {
	font-weight: 700;
	padding: 0 5px;
}

.post-related {
	margin-top: 60px;
}

.post-related h3 {
	text-align: center;
}

.post-related .entry-title {
	font-size: 16px;
	line-height: 1.5em;
}

.post-related .owl-carousel {
	overflow: hidden;
}

.post-related .owl-theme .owl-nav {
	top: 50%;
	opacity: 1;
	left: -30px;
	right: -30px;
}

.post-related .owl-theme .owl-nav > div {
	background-color: #f2f2f2;
}

.post-related .owl-theme .owl-nav .owl-prev {
	padding-left: 22px;
}

.post-related .owl-theme .owl-nav .owl-next {
	padding-right: 22px;
}

@media (min-width: 1200px) {
	.post-related {
		margin-top: 80px;
	}
}

.post-password-form label {
	margin-bottom: 0;
}

.post-password-form input[type="password"].form-control {
	border-color: rgba(0, 0, 0, 0.1);
}

.post-password-form .theme_button {
	margin-top: 10px;
	margin-bottom: 0;
	vertical-align: bottom;
}

#timetable_filter {
	padding: 0;
}

/* team */
.entry-header + .member-social {
	margin-top: -8px;
}

/* gallery */
.page_content + .page_portfolio {
	margin-top: -150px;
}

#isotope_filters,
.filters {
	text-align: center;
	margin-bottom: 20px;
	padding: 0;
	list-style: none;
}

#isotope_filters.bottommargin_30,
.filters.bottommargin_30 {
	margin-bottom: 30px;
}

#isotope_filters li,
.filters li {
	display: inline-block;
	margin: 0;
	padding: 0;
}

#isotope_filters a,
.filters a {
	position: relative;
	display: inline-block;
	padding: 0 15px;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	color: #3a3d44;
	font-family: 'Ubuntu', sans-serif;
}

#isotope_filters a.selected,
.filters a.selected {
	color: #F15931;
}

#isotope_filters a:hover,
.filters a:hover {
	color: #F15931;
}

#isotope_filters a:hover:after,
.filters a:hover:after {
	opacity: 1;
	transform: translateY(0) scaleX(1);
}

@media (min-width: 992px) {
	#isotope_filters a,
  .filters a {
		padding: 0 25px;
	}
}

#isotope_filters + .isotope_container:not('.columns_margin_0'),
.filters + .isotope_container:not('.columns_margin_0') {
	margin-top: -10px;
}

.gallery-item {
	overflow: hidden;
	position: relative;
}

.gallery-item .item-content h4 {
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 400;
	z-index: 5;
	transition: all 0.15s ease-in-out;
	filter: alpha(opacity=0);
	opacity: 0;
	transform: scale(0);
}

.gallery-item:hover .item-content h4 {
	transform: scale(1);
	filter: alpha(opacity=100);
	opacity: 1;
}

figure.gallery-item {
	margin-bottom: 10px;
}

.gallery-title-item + .item-title {
	padding: 25px 0 40px;
}

.gallery-title-item + .item-title h3 {
	font-size: 30px;
}

.gallery-title-item + .item-title .categories-links + h3 {
	margin-top: -5px;
}

i.loadmore_spinner {
	font-size: 30px;
	opacity: 0.3;
	margin: 10px 0 20px;
}

/* gallery single */
.gallery-single-title {
	margin-bottom: 30px;
	font-size: 40px;
}

.gallery-single-title + .block-header {
	margin-bottom: 40px;
}

.share-gallery-single {
	margin: 40px 0 16px;
}

.items-nav {
	padding: 30px 23px;
}

.items-nav[class*='display_table_'] {
	margin-top: 40px;
}

.items-nav h4 {
	margin: -5px 0 0;
}

.items-nav .media {
	position: relative;
	padding: 10px 0;
}

.items-nav .next-item img {
	margin-right: 30px;
	border-radius: 10px;
}

.items-nav .next-item i {
	right: 0;
}

.items-nav .prev-item img {
	margin-left: 30px;
	border-radius: 10px;
}

.items-nav a {
	position: relative;
}

.items-nav a i {
	top: 50%;
	margin-top: -14px;
	font-size: 20px;
}

.items-nav a i.position-absolute {
	position: absolute;
}

.loop-pagination {
	margin-top: 55px;
}

.loop-pagination .pagination {
	display: block;
}

.owl-carousel.related-photos-carousel {
	margin-bottom: 0;
}

/* post formats */
.format-status {
	line-height: 24px;
}

.format-status .entry-date + .entry-title {
	margin-top: 20px;
}

.format-status .avatar {
	max-width: 130px;
	border-radius: 50%;
}

.format-status .entry-meta {
	margin-top: 32px;
	margin-bottom: 16px;
}

.format-quote blockquote {
	margin-bottom: 0;
}

.single .format-quote blockquote {
	margin-top: 0;
}

.format-quote blockquote .post-author {
	max-width: 170px;
}

.format-chat .entry-content p {
	padding: 7px 0;
	margin-bottom: 0;
}

.format-chat .entry-content p strong {
	font-weight: 400;
}

.format-chat .entry-content p:nth-child(2n) {
	background-color: #f2f2f2;
	padding-left: 15px;
}

.format-gallery .owl-dots {
	position: absolute;
	bottom: 30px;
	left: 0;
	right: 0;
}

.format-gallery .owl-dots .owl-dot:not(.active):not(:hover) span {
	border-color: #ffffff;
}

@media (min-width: 992px) {
	.format-gallery .owl-dots {
		bottom: 50px;
	}
}

.masonry-feed h3.entry-title {
	font-size: 18px;
	font-weight: 400;
}

.masonry-feed .closest-event h3.entry-title {
	margin-top: 0.35em;
	font-size: 18px;
}

.masonry-feed .closest-event h3.entry-title + .entry-meta {
	margin-top: -10px;
}

@media (min-width: 400px) {
	.masonry-feed .closest-event h3.entry-title {
		font-size: 24px;
	}
}

@media (min-width: 768px) {
	.masonry-feed .closest-event h3.entry-title {
		font-size: 32px;
	}

	.masonry-feed .closest-event h3.entry-title + .entry-meta {
		margin-top: -7px;
	}
}

.masonry-feed .closest-event .entry-meta {
	color: #ffffff;
	line-height: 1.2em;
}

.masonry-feed .closest-event .countdown-amount {
	font-size: 28px;
}

.masonry-feed .closest-event .countdown-section {
	padding: 0 10px;
}

@media (max-width: 399px) {
	.masonry-feed .closest-event .countdown-amount {
		font-size: 20px;
	}

	.masonry-feed .closest-event .countdown-section {
		padding: 0 10px;
	}
}

@media (min-width: 992px) {
	.masonry-feed .col-md-3 {
		width: 24.95%;
	}
}

@media (min-width: 992px) {
	.masonry-feed .col-md-6 {
		width: 50.1%;
	}
}

@media (max-width: 767px) {
	.masonry-feed .vertical-item.content-absolute .item-content {
		padding: 20px 20px 35px;
	}
}

/*
isotope portfolio and gallery post
*/
.gallery-item {
	text-align: center;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	width: 50%;
}

.gallery-columns-3 .gallery-item {
	width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	width: 25%;
}

.gallery-columns-5 .gallery-item {
	width: 20%;
}

.gallery-columns-6 .gallery-item {
	width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	width: 11.11%;
}

.gallery-icon img {
	display: block;
	margin: 0 auto;
}

.gallery-caption {
	display: block;
	padding: 0.5em;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

@media (max-width: 600px) {
	.gallery-columns-4 .gallery-item,
  .gallery-columns-5 .gallery-item,
  .gallery-columns-6 .gallery-item,
  .gallery-columns-7 .gallery-item,
  .gallery-columns-8 .gallery-item,
  .gallery-columns-9 .gallery-item {
		width: 33%;
	}

	.gallery-columns-4 .gallery-caption,
  .gallery-columns-5 .gallery-caption,
  .gallery-columns-6 .gallery-caption,
  .gallery-columns-7 .gallery-caption,
  .gallery-columns-8 .gallery-caption,
  .gallery-columns-9 .gallery-caption {
		display: none;
	}
}

.isotope_container {
	transition: height 0.2s ease-in-out 0s;
}

.single-post .isotope_container {
	margin: 20px 0;
	clear: both;
}

/*
** Widgets
*/
/* common widgets styles */
aside > div + div {
	margin-top: 60px;
}

aside .widget + .widget {
	margin-top: 60px;
}

.widget-theme-wrapper.with_background, .widget-theme-wrapper.muted_background, .widget-theme-wrapper.cs, .widget-theme-wrapper.ds, .widget-theme-wrapper.ls {
	padding: 35px;
}

/* affix-aside */
.affix-aside {
	position: relative;
}

.affix-aside.affix {
	position: fixed;
	margin-top: -30px;
}

@media (max-width: 767px) {
	.affix-aside, .affix-aside.affix, .affix-aside.affix-bottom {
		position: static;
		width: inherit !important;
	}
}

.widget > h3,
.widget-title {
	position: relative;
	font-size: 24px;
	margin-bottom: 25px;
	line-height: 1;
}

.page_footer .widget > h3, .page_footer
.widget-title {
	font-size: 30px;
}

@media (min-width: 992px) {
	.page_footer .widget > h3, .page_footer
  .widget-title {
		margin-bottom: 42px;
	}
}

.text-center .widget > h3 + hr.theme-divider, .text-center
.widget-title + hr.theme-divider {
	margin-top: -15px;
	margin-bottom: 15px;
	width: 70px;
}

.text-center .widget > h3 + hr.theme-divider:before, .text-center
.widget-title + hr.theme-divider:before {
	width: 30px;
	left: 20px;
}

.widget:before, .widget:after {
	display: block;
	content: '';
	clear: both;
}

.widget ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.widget ul ul li {
	padding-left: 20px;
}

.widget ul.list2 {
	padding-left: 20px;
}

.widget ul.list2.checklist {
	padding-left: 30px;
}

.widget li {
	position: relative;
}

.widget .list-unstyled li:first-child {
	padding-top: 0;
}

.widget select {
	width: 100%;
	max-width: 100%;
}

.page_footer .row > .widget {
	max-width: 400px;
}

.widget_nav_menu li a,
.widget_archive li a,
.widget_categories li a,
.widget_meta li a,
.widget_pages li a {
	display: inline-block;
	padding-top: 5px;
	padding-bottom: 5px;
}

.widget_nav_menu ul > li:last-child a,
.widget_nav_menu ul > li:last-child li:last-child a,
.widget_archive ul > li:last-child a,
.widget_archive ul > li:last-child li:last-child a,
.widget_categories ul > li:last-child a,
.widget_categories ul > li:last-child li:last-child a,
.widget_meta ul > li:last-child a,
.widget_meta ul > li:last-child li:last-child a,
.widget_pages ul > li:last-child a,
.widget_pages ul > li:last-child li:last-child a {
	padding-bottom: 0;
}

/*
widget_search,
widget_mailchimp
*/
aside .inline-form .form-group-wrap {
	display: block;
}

.widget_mailchimp form + p {
	padding-top: 10px;
}

.widget_search .form-inline,
.widget_product_search form,
.widget_mailchimp .form-inline {
	position: relative;
}

.widget_search .form-inline .form-control,
.widget_product_search form .form-control,
.widget_mailchimp .form-inline .form-control {
	width: 100%;
	margin: 0;
}

.widget_search .form-inline label,
.widget_product_search form label,
.widget_mailchimp .form-inline label {
	display: block;
}

.widget_search .form-inline label[for],
.widget_product_search form label[for],
.widget_mailchimp .form-inline label[for] {
	display: none;
}

.widget_search .form-inline .form-group,
.widget_mailchimp .form-inline .form-group {
	display: inline-block;
	vertical-align: top;
	position: relative;
}

.widget_search [type="text"],
.widget_search [type="search"],
.widget_product_search [type="search"],
.widget_mailchimp [type="email"] {
	padding-right: 50px;
	width: 100%;
}

.with_background .widget_search [type="text"], .with_background
.widget_search [type="search"], .with_background
.widget_product_search [type="search"], .with_background
.widget_mailchimp [type="email"] {
	background-color: #f2f2f2;
	border-color: transparent !important;
}

.widget_search .form-group,
.widget_mailchimp .form-group {
	margin-bottom: 0;
}

.widget_search form,
.widget_mailchimp form {
	position: relative;
}

.widget_search .form-group + .theme_button,
.widget_product_search [type="submit"],
.widget_mailchimp .form-group + .theme_button,
.widget_mc4wp_form_widget .form-group + .theme_button {
	font-size: 0;
	letter-spacing: 0;
	padding: 0;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
	height: 60px;
	width: 60px;
	line-height: 60px;
	border: none;
	box-shadow: none;
	overflow: hidden;
	border-radius: 0 10px 10px 0;
}

.widget_search .form-group + .theme_button:before,
.widget_product_search [type="submit"]:before,
.widget_mailchimp .form-group + .theme_button:before,
.widget_mc4wp_form_widget .form-group + .theme_button:before {
	font-size: 14px;
	content: "\f002";
	font-weight: 300;
	font-family: FontAwesome;
}

.widget_product_search [type="submit"] {
	color: #F15931;
	background-color: transparent;
}

.widget_mailchimp .form-group + .theme_button:before,
.widget_mc4wp_form_widget .form-group + .theme_button:before {
	content: "\f040";
}

[class*="main_bg_color"] .widget_search,
[class*="main_bg_color"] .widget_mc4wp_form_widget {
	position: relative;
	z-index: 1;
}

[class*="main_bg_color"] .widget_search:before,
[class*="main_bg_color"] .widget_mc4wp_form_widget:before {
	content: "\f002";
	font-family: FontAwesome;
	font-size: 150px;
	line-height: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.1;
	z-index: -1;
}

[class*="main_bg_color"] .widget_mc4wp_form_widget:before {
	content: "\f040";
}

aside:not(.shortcode-widget-area) .widget_mc4wp_form_widget .col-sm-4 {
	width: 100%;
}

/*
widget_recent_entries
widget_recent_comments
widget_archive
widget_categories
widget_meta
widget_nav_menu
widget_pages
*/
.widget_recent_comments p,
.widget_recent_entries p {
	margin-bottom: 5px;
}

.widget_recent_comments li,
.widget_recent_entries li {
	padding: 11px 0;
}

.widget_recent_comments li + li,
.widget_recent_entries li + li {
	border-top: 1px solid #e4e4e4;
}

.widget_recent_posts li {
	line-height: 20px;
}

.widget_recent_posts .post-date {
	display: block;
	margin-top: 5px;
}

.widget_recent_posts p {
	margin-bottom: 5px;
}

.widget_recent_posts .list-unstyled > li + li {
	margin-top: 30px;
}

.widget_recent_entries a,
.widget_recent_posts a {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
}

.widget_recent_entries .small-text,
.widget_recent_posts .small-text {
	font-weight: 500;
}

.widget_recent_entries .media-left img,
.widget_recent_posts .media-left img {
	max-width: 70px;
}

.widget_recent_entries .categories-links,
.widget_recent_posts .categories-links {
	line-height: 1.4;
}

.widget_recent_entries h6 {
	max-width: 70%;
	line-height: 23px;
}

.ds .widget_recent_entries h6 a {
	color: rgba(255, 255, 255, 0.8);
}

.text-center .widget_recent_entries h6, .widget_recent_entries.text-center h6 {
	margin-right: auto;
	margin-left: auto;
}

.widget_recent_entries .lineheight_thin {
	line-height: 20px;
}

.widget_meta li,
.widget_pages li,
.widget_nav_menu li {
	border-top: 1px solid rgba(120, 120, 120, 0.2);
}

.widget_meta > ul > li:last-child,
.widget_nav_menu ul.menu > li:last-child,
.widget_pages > ul > li:last-child {
	border-bottom: 1px solid rgba(120, 120, 120, 0.2);
}

.widget_recent_posts > ul > li:last-child {
	padding-bottom: 0;
}

.widget_popular_entries > ul > li:first-child {
	margin-top: 0;
}

.widget_categories li.active a {
	color: #3a3d44;
}

.widget_categories a {
	display: inline-block;
	position: relative;
	padding-left: 25px;
}

.page_footer .widget_categories a {
	padding-left: 0;
}

.widget_archive li ul li:first-child a,
.widget_categories li ul li:first-child a,
.widget_meta li ul li:first-child a,
.widget_nav_menu li ul li:first-child a,
.widget_pages li ul li:first-child a {
	padding-top: 5px;
}

.widget_archive li,
.widget_categories li {
	clear: both;
}

.widget_archive li > span,
.widget_categories li > span {
	float: right;
	margin-top: 5px;
}

.widget_categories a:before {
	font-family: 'FontAwesome';
	font-size: 14px;
	content: "\f00c";
	color: #F15931;
	position: absolute;
	left: 0;
	top: 6px;
}

.page_footer .widget_categories a:before {
	display: none;
}

.widget_archive .active a {
	padding-left: 30px;
}

.widget_archive .active a:before {
	content: "\f085";
	font-family: FontAwesome;
	color: #F15931;
	position: absolute;
	left: 0;
}

.widget_nav_menu a i {
	padding-right: 7px;
}

@media (min-width: 768px) {
	.page_copyright .widget_nav_menu li, .page_topline .widget_nav_menu li {
		display: inline-block;
		position: relative;
		border: none;
		padding-right: 5px;
	}

	.page_copyright .widget_nav_menu li:before, .page_topline .widget_nav_menu li:before {
		content: '';
		position: absolute;
		padding: 0;
		left: -5px;
		top: 5px;
		bottom: 5px;
		width: 1px;
		background-color: rgba(120, 120, 120, 0.2);
		opacity: 0.3;
		display: block;
	}

	.page_copyright .widget_nav_menu li:first-child:before, .page_topline .widget_nav_menu li:first-child:before {
		display: none;
	}

	.page_copyright .widget_nav_menu a, .page_topline .widget_nav_menu a {
		padding: 0 10px;
	}

	.page_copyright .widget_nav_menu a:before, .page_topline .widget_nav_menu a:before {
		display: none;
	}
}

/* Popular Courses */
.widget_events .media-left img {
	max-width: 70px;
}

.widget_events .media h4 {
	font-size: 14px;
	line-height: 1.4;
	margin-bottom: 5px;
}

/* widget_tag_cloud */
.widget_tag_cloud a,
.tag-links a {
	padding: 0px 10px;
	margin: 0 6px 8px 0;
	font-size: 12px !important;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	color: #fff;
	background-color: #03a8cb;
	display: inline-block;
	border-radius: 10px;
	font-family: 'Ubuntu', sans-serif;
}

.widget_tag_cloud a:hover, .widget_tag_cloud a:focus, .widget_tag_cloud a:active,
.tag-links a:hover,
.tag-links a:focus,
.tag-links a:active {
	color: #3a3d44;
}

/* widget_popular_entries */
.widget_popular_entries h4 {
	margin-bottom: 0;
	font-size: 16px;
}

.widget_popular_entries p {
	margin-bottom: 0;
}

.widget_popular_entries .media-body {
	overflow: visible;
}

.widget_popular_entries .media-left {
	padding-right: 20px;
}

.widget_popular_entries a img,
.widget_popular_entries .media-object {
	max-width: 70px;
}

@media (min-width: 768px) and (max-width: 1199px) {
	.widget_popular_entries .media-object {
		max-width: 40px;
	}
}

/* recent posts widget */
.widget_recent_posts h5 {
	font-size: 16px;
	margin-top: 0;
}

.widget_recent_posts.widget_slider h5 {
	margin-bottom: 5px;
}

.widget_recent_posts.widget_slider .content-padding .item-content {
	padding-top: 30px;
	padding-bottom: 25px;
}

/* widget_calendar */
#calendar_wrap {
	width: 100%;
	margin-bottom: 0;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (min-width: 768px) and (max-width: 992px) {
	#calendar_wrap {
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.widget_calendar table {
	width: 100%;
	margin: 0;
	position: relative;
	text-align: center;
	background-color: transparent;
}

.widget_calendar caption {
	line-height: 1;
	font-weight: 400;
	font-size: 24px;
	font-family: 'Ubuntu', sans-serif;
	padding: 18px 0;
	text-align: center;
	color: #ffffff;
	background-color: #03a8cb;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.widget_calendar thead {
	background-color: #3a3d44;
	color: #ffffff;
}

.widget_calendar thead th {
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	padding: 23px 5px;
	font-family: 'Ubuntu', sans-serif;
}

.widget_calendar thead th:first-child {
	padding-left: 25px;
}

.widget_calendar thead th:last-child {
	padding-right: 25px;
}

.widget_calendar tbody {
	border-top: none;
	font-weight: 300;
	border: 1px solid #e4e4e4;
}

.widget_calendar tbody tr:first-child td {
	padding-top: 30px;
}

.widget_calendar tbody tr:last-child td {
	padding-bottom: 30px;
}

.widget_calendar tbody td {
	border: none;
	padding: 9px 4px;
	position: relative;
}

.widget_calendar tbody td:first-child {
	padding-left: 25px;
}

.widget_calendar tbody td:last-child {
	padding-right: 25px;
}

.widget_calendar tbody td a {
	font-weight: 700;
	color: #03a8cb;
}

.widget_calendar tbody td a:hover {
	color: #F15931;
}

.widget_calendar th {
	text-align: center;
	border: 0;
	color: inherit;
}

.widget_calendar td {
	border: 0;
}

.widget_calendar tfoot {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}

.widget_calendar tfoot td {
	position: absolute;
	top: 0;
}

.widget_calendar tfoot td.pad {
	display: none;
}

.widget_calendar tfoot td a {
	color: #ffffff;
	text-align: center;
	font-size: 0;
	width: 60px;
	height: 60px;
	line-height: 60px;
	display: inline-block;
}

.widget_calendar tfoot td a:hover {
	color: #3a3d44;
}

.widget_calendar tfoot td a:before, .widget_calendar tfoot td a:after {
	font-size: 14px;
	font-family: FontAwesome;
}

.widget_calendar tfoot #prev {
	left: 0;
	padding: 0;
}

.widget_calendar tfoot #prev a {
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #prev a:before {
	content: "\f104";
}

.widget_calendar tfoot #next {
	right: 0;
	padding: 0;
}

.widget_calendar tfoot #next a {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.widget_calendar tfoot #next a:after {
	content: "\f105";
}

/* widget_banner */
.widget_banner {
	overflow: hidden;
	line-height: 1;
}

.widget_banner .vertical-item.content-absolute .item-content {
	padding: 20px;
}

.widget_banner .item-content span {
	display: inline-block;
	text-transform: uppercase;
	font-size: 16px;
	padding: 4px 10px;
}

.widget_banner .item-content h3 {
	margin: 0;
	font-size: 28px;
	padding: 10px;
	background-color: #3a3d44;
}

.widget_banner .abs-link {
	z-index: 5;
}

/* widget_slider */
.widget_slider {
	position: relative;
}

.widget_slider .owl-carousel {
	margin-bottom: 0;
}

.widget_slider .owl-carousel .owl-nav {
	opacity: 1;
	top: -23px;
	right: 0;
}

.widget_slider .owl-carousel .owl-nav > div {
	width: 20px;
	height: 30px;
	text-align: right;
	line-height: 30px;
	right: 0;
	background-color: transparent;
	border: none;
}

.widget_slider .owl-carousel .owl-nav > div:first-child {
	left: auto;
	right: 22px;
	text-align: left;
}

.widget_slider .owl-carousel .owl-nav > div:after {
	font-size: 18px;
	color: inherit;
	color: #1f232b;
	line-height: 30px;
}

/* widget_flickr */
.widget_flickr ul {
	margin: -5px;
	font-size: 0;
}

.widget_flickr li {
	display: inline-block;
	padding: 5px;
	width: 33.333333333%;
	max-width: 180px;
}

/* widget_instagram */
.widget_instagram .instafeed {
	margin: -5px;
}

.widget_instagram .photo {
	width: 33.3333333333%;
	max-width: 180px;
	padding: 5px;
	float: left;
	position: relative;
}

.widget_instagram .photo a {
	position: relative;
	display: block;
}

/* widget_twitter */
.tweet_avatar {
	float: left;
	margin-top: 4px;
}

.tweet_right {
	overflow: hidden;
	zoom: 1;
	margin-left: 60px;
}

.tweet_list li + li {
	margin-top: 16px;
	clear: both;
}

.tweet_time,
.tweet_user {
	font-size: 14px;
}

.tweet_user {
	margin-bottom: 5px;
}

.tweet_time {
	padding-top: 5px;
}

.tweet_text a {
	text-decoration: underline;
}

.tweet_list li {
	position: relative;
	padding-left: 28px;
}

.tweet_list li > span {
	display: block;
}

.tweet_list li:before {
	position: absolute;
	left: 0;
	top: 0;
	content: "\f099";
	font-family: FontAwesome;
	text-transform: none;
	font-size: 18px;
	color: #24afcf;
}

/* widget_tabs widget_theme_post_tabs */
.widget_theme_post_tabs .vertical-item .item-content {
	padding-top: 10px;
}

.widget_theme_post_tabs .vertical-item + .vertical-item {
	margin-top: 30px;
}

.widget_theme_post_tabs .pull-right {
	margin-left: 10px;
}

.widget_rss li {
	margin-bottom: 15px;
}

.widget_rss li .rsswidget {
	font-size: 1.1em;
}

.widget_rss .rss-date {
	display: block;
	font-weight: 700;
}

.widget_rss cite {
	display: block;
	text-align: right;
	font-weight: 700;
}

/* access_press */
.apsc-each-profile {
	background-color: #fff;
	border: 1px solid #e4e4e4;
	border-radius: 10px;
	font-family: 'Ubuntu', sans-serif;
}

.apsc-facebook-icon .social-icon .fa {
	background-color: #3b5998;
}

.apsc-twitter-icon .social-icon .fa {
	background-color: #1dcaff;
}

.apsc-youtube-icon .social-icon .fa {
	background-color: #e52d27;
}

.apsc-soundcloud-icon .social-icon .fa {
	background-color: #ff8800;
}

.apsc-dribble-icon .social-icon .fa {
	background-color: #ea4c89;
}

.apsc-comment-icon .social-icon .fa {
	background-color: #464646;
}

.apsc-edit-icon .social-icon .fa {
	background-color: #837d7d;
}

.apsc-google-plus-icon .social-icon .fa {
	background-color: #dd4b39;
}

.apsc-instagram-icon .social-icon .fa {
	background-color: #3f729b;
}

.apsc-each-profile .social-icon span {
	color: #3a3d44;
}

.apsc-each-profile a:hover {
	color: white !important;
	text-decoration: none !important;
}

.apsc-theme-4 .apsc-each-profile {
	margin-bottom: 10px;
}

.apsc-theme-4 .apsc-each-profile:last-child {
	margin-bottom: 0;
}

.apsc-theme-4 .social-icon {
	display: inline-block;
	position: relative;
	float: left;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-each-profile a {
	line-height: 58px;
	padding: 0 10px 0 0;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	display: block;
	color: #fff;
	position: relative;
	text-align: right;
}

.apsc-theme-4 .apsc-each-profile a:hover {
	opacity: 0.8;
}

@media (min-width: 1200px) {
	.apsc-theme-4 .apsc-each-profile a {
		font-size: 12px;
		padding-right: 20px;
	}
}

.apsc-theme-4 .apsc-each-profile a .fa {
	width: 60px;
	line-height: 60px;
	height: 60px;
	margin: -1px;
	vertical-align: top;
	text-align: center;
	font-size: 16px;
	display: inline-block;
	border-radius: 10px 0 0 10px;
	transform: none;
	transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-each-profile a:hover .fa {
	transform: none;
	-webkit-transform: snone;
	-ms-transform: none;
}

.apsc-theme-4 .media-name {
	padding: 0 0 0 10px;
	display: inline-block;
	font-weight: 600;
}

@media (min-width: 1200px) {
	.apsc-theme-4 .media-name {
		padding-left: 20px;
	}
}

.apsc-theme-4 .apsc-count {
	border-radius: 0;
	color: #787878;
	display: inline-block;
	font-size: inherit;
	text-align: center;
	position: relative;
	font-weight: 400;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
}

.apsc-theme-4 .apsc-media-type {
	display: inline-block;
	padding: 0 4px;
	font-weight: 400;
	color: #787878;
}

@media screen and (max-width: 479px) {
	.apsc-theme-4 .apsc-each-profile, .apsc-theme-3 .apsc-each-profile {
		float: none;
	}
}

/*
** Shortcodes
*/
/*
** Teasers
*/
/* small teaser - media bootstrap component with icon */
.small-teaser .media-body {
	line-height: 20px;
}

.media.inline-block {
	margin-top: 10px;
}

.media.inline-block.small-teaser {
	margin-top: 10px;
}

.media.inline-block.teaser:first-child {
	margin-top: 10px;
}

@media (min-width: 992px) {
	.media.topmargin_40 {
		margin-top: 80px;
	}
}

.media-left i,
.meida-right i {
	display: block;
	width: 1.1em;
	line-height: 1.625;
}

.media-left i.fontsize_24,
.meida-right i.fontsize_24 {
	line-height: 1;
}

.inline-teasers-wrap {
	display: inline-block;
	margin-left: -15px;
	margin-right: -15px;
}

.inline-teasers-wrap > * {
	vertical-align: middle;
	margin: 10px 15px;
}

@media (min-width: 1200px) {
	.inline-teasers-wrap {
		margin-left: -20px;
		margin-right: -20px;
	}

	.inline-teasers-wrap > * {
		margin: 10px 20px;
	}
}

/* teaser */
.teaser {
	position: relative;
	overflow: visible;
	z-index: 1;
}

.teaser.sponsort {
	min-height: 227px;
}

.teaser.media h2, .teaser.media h3, .teaser.media h4, .teaser.media h5, .teaser.media h6 {
	line-height: 1em;
}

.teaser h2, .teaser h3, .teaser h4, .teaser h5, .teaser h6 {
	margin-bottom: 0.4em;
}

.teaser[class*="_bg_color"], .teaser[class*="with_"] {
	padding: 30px 20px;
}

@media (min-width: 400px) {
	.teaser[class*="_bg_color"], .teaser[class*="with_"] {
		padding: 40px;
	}
}

@media (min-width: 1600px) {
	.teaser[class*="_bg_color"].big-padding, .teaser[class*="with_"].big-padding {
		padding: 50px;
	}
}

.teaser i {
	text-align: center;
	vertical-align: middle;
	display: inline-block;
}

.teaser .media-body {
	line-height: 1.5em;
}

.teaser .media-body h3 {
	margin-bottom: 10px;
}

.teaser h3[class*="count"] {
	font-size: 48px;
	font-weight: 700;
	margin: 10px 0 0;
}

.teaser h3[class*="count"] + p {
	font-size: 20px;
	font-weight: 600;
}

.teaser .theme_button {
	margin: 22px 0 0;
}

.section_padding_bottom_0 .teaser {
	margin-bottom: 0;
}

.teaser .counter-background {
	position: absolute;
	right: 0;
	left: 0;
	top: 48%;
	font-size: 180px;
	font-weight: 700;
	line-height: 1;
	opacity: 0.1;
	transform: translateY(-50%);
}

.teaser .icon-background {
	position: absolute;
	opacity: 0.1;
	top: 50%;
	left: 50%;
	right: 0;
	font-size: 300px;
	transform: translate(-50%, -50%);
	z-index: -1;
	max-width: 100%;
	width: 100%;
}

.teaser .icon-background.small {
	font-size: 84px;
}

.teaser i.icon-background {
	margin-top: 0;
	margin-bottom: 0;
}

.teaser.font-icon-background-teaser {
	padding: 48px 25px 48px;
	overflow: hidden;
}

.teaser.counter-background-teaser {
	overflow: hidden;
	padding: 98px 25px 98px;
}

.teaser.counter-background-teaser .small-text {
	line-height: 1.4em;
}

.teaser.inner-border:before {
	content: '';
	position: absolute;
	top: 10px;
	right: 10px;
	bottom: 10px;
	left: 10px;
	border: 1px solid rgba(255, 255, 255, 0.5);
}

.icon-bg-teaser {
	z-index: 1;
	line-height: 1.5em;
	min-height: 80px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
}

.teaser_icon {
	display: inline-block;
	line-height: 1;
	text-align: center;
	transition: all 0.2s ease-in-out 0s;
}

.teaser_icon[class*="_bg_color"], .teaser_icon.with_background {
	width: 2em;
	height: 2em;
	text-align: center;
	vertical-align: bottom;
}

.teaser_icon[class*="_bg_color"] > *, .teaser_icon.with_background > * {
	line-height: 2em;
}

.teaser_icon[class*="_bg_color"].big_wrapper, .teaser_icon.with_background.big_wrapper {
	width: 2.6667em;
	height: 2.6667em;
}

.teaser_icon[class*="_bg_color"].big_wrapper > *, .teaser_icon.with_background.big_wrapper > * {
	line-height: 2.6667em;
}

.teaser_icon.border_icon {
	width: 2em;
	height: 2em;
	border-width: 2px;
	border-style: solid;
}

.teaser_icon.border_icon + h3,
.teaser_icon.border_icon + h4 {
	margin-top: 28px;
}

.teaser_icon.border_icon * {
	line-height: 2em;
	margin-top: -2px;
}

.teaser_icon[class*='label-'] {
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 32px;
	vertical-align: bottom;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	color: #fff;
}

.teaser_icon + h2 {
	margin-top: 40px;
}

.teaser_icon.big_wrapper + h4 {
	margin-top: 33px;
}

.teaser_icon + p {
	margin-top: 27px;
}

.teaser_icon.thick_border_icon {
	border: 10px solid rgba(31, 35, 43, 0.07);
	background-clip: content-box;
}

.teaser_icon.thick_border_icon[class*="_bg_color"], .teaser_icon.thick_border_icon.with_background {
	width: calc(2em + 20px);
	height: calc(2em + 20px);
}

.teaser_icon.thick_border_icon[class*="_bg_color"].big_wrapper, .teaser_icon.thick_border_icon.with_background.big_wrapper {
	width: calc(2.6667em + 20px);
	height: calc(2.6667em + 20px);
}

.cons-width {
	min-width: 1.2em;
	text-align: center;
}

.size_big {
	font-size: 64px;
}

.size_normal {
	font-size: 48px;
}

.size_small {
	font-size: 30px;
}

/* background teaser */
.bg_teaser {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	overflow: hidden;
}

.bg_teaser:after {
	position: absolute;
	width: 100%;
	height: 1000px;
}

.bg_teaser .highlight {
	color: #fff;
}

.bg_teaser h4 + h3 {
	margin-top: -14px;
	font-size: 50px;
}

.bg_teaser.rectangular .teaser_content {
	padding: 200px 30px 50px;
}

.bg_teaser.rectangular .teaser_content h3 {
	font-size: 40px;
}

.bg_teaser > img {
	display: none;
	visibility: hidden;
}

.bg_teaser .teaser_content {
	margin: 0;
	padding: 40px 40px 35px;
	z-index: 2;
}

.bg_teaser .teaser_content > .media-left {
	padding-right: 35px;
}

.bg_teaser .teaser_content.media {
	padding: 75px 40px 60px;
}

.darkgrey_bg .highlight,
.black_bg .highlight,
.darkblue_bg .highlight {
	color: #F15931;
}

.color_bg_1:after {
	background-color: rgba(232, 82, 66, 0.8);
}

.color_bg_2:after {
	background-color: rgba(243, 112, 43, 0.8);
}

[class*="color_bg"] {
	color: #ffffff;
}

.teaser.media.after_cover {
	padding: 25px 30px;
}

.teaser.media.after_cover i {
	opacity: 0.3;
}

.teaser.media.after_cover p {
	text-transform: uppercase;
}

.darkgrey_bg:after {
	background-color: rgba(58, 61, 68, 0.8);
}

.darkgrey_bg.ms:after {
	background-color: rgba(31, 35, 43, 0.6);
}

.darkgrey_bg.black:after {
	background-color: rgba(0, 0, 0, 0.7);
}

.darkblue_bg:after {
	background-color: rgba(0, 32, 43, 0.7);
}

.teasers-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	margin: 0 -15px;
}

.teasers-row > div {
	padding: 0 15px;
}

/* 
** Testimonials 
*/
@media (max-width: 600px) {
	.blockquote-big {
		font-size: 18px;
		max-width: 100%;
		padding-left: 40px;
		padding-right: 40px;
	}
}

/* testimonials-carousel */
.testimonials-carousel {
	padding: 0 0 20px;
}

.testimonials-carousel .item > p {
	font-style: italic;
	padding-left: 40px;
	margin-bottom: 40px;
	position: relative;
}

.testimonials-carousel .item > p:before {
	font-family: 'PT Sans', sans-serif;
	font-size: 100px;
	font-style: normal;
	content: '\201c';
	position: absolute;
	left: -3px;
	top: 30px;
	z-index: 10;
}

.testimonials-carousel h4 {
	margin: 0;
}

.testimonials-carousel h4 + p {
	margin-bottom: 0;
}

.testimonials-carousel .carousel-indicators {
	bottom: -16px;
	display: none;
}

.testimonials-carousel .carousel-control {
	display: none;
}

.testimonials-carousel .media + p {
	position: relative;
	padding: 10px 16px;
	background-color: #f2f2f2;
}

.testimonials-carousel .media:after {
	content: '';
	display: block;
	clear: both;
}

.testimonials-carousel .media + p:before,
.testimonials-carousel .media + p:after {
	content: '';
	position: absolute;
	left: 0;
	top: auto;
	bottom: -18px;
	border-style: solid;
	border-width: 18px 0 0 18px;
	border-color: rgba(120, 120, 120, 0.2) transparent transparent transparent;
}

.testimonials-carousel .media + p:after {
	left: auto;
	right: 0;
	border-style: solid;
	border-width: 18px 18px 0 0;
}

.testimonials-carousel .media-object {
	max-width: 50px;
	border-radius: 50%;
	margin-bottom: 10px;
}

.testimonials-carousel .carousel-indicators li {
	border-color: #F15931;
}

.testimonials-carousel .carousel-indicators li.active {
	background-color: #F15931;
}

.testimonials-control {
	font-size: 22px;
}

/* thumbnail */
.thumbnail {
	position: relative;
	text-align: center;
	border-radius: 0;
	padding: 0;
	border: none;
	overflow: hidden;
	background-color: transparent;
}

.isotope-item .thumbnail {
	margin-bottom: 30px;
}

.thumbnail h3 {
	font-size: 20px;
	margin-bottom: 2px;
}

.thumbnail h3 a {
	color: inherit;
}

.thumbnail .caption {
	border: 1px solid #e4e4e4;
	border-top-width: 0;
	color: #787878;
	background-color: #ffffff;
	padding: 26px 20px 26px;
}

/* price tables */
.price-table,
.price-table ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}

.price-table {
	margin: 0 auto;
	max-width: 500px;
}

.plan-name {
	margin: -1px -1px 0;
	padding: 25px 20px;
}

.plan-name h4 {
	margin: 0;
	font-size: 24px;
}

.plan-name[class*="main_bg_color"] h4,
.plan-name h4 {
	color: #3a3d44;
}

.plan-price {
	padding: 54px 0 0;
	position: relative;
	font-size: 24px;
	font-weight: 500;
	line-height: 1;
}

.plan-price span.big {
	font-size: 40px;
	font-weight: 900;
}

.price-description {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.1em;
	margin-top: 12px;
}

.features-list {
	padding: 38px 55px;
}

.features-list li {
	padding: 10px 0 11px;
}

.features-list li {
	border-top: 1px solid #e4e4e4;
	position: relative;
}

.features-list li:last-child {
	border-bottom: 1px solid #e4e4e4;
}

.features-list .enabled:before,
.features-list .disabled:before {
	font-family: 'rt-icons-2';
	font-size: 24px;
	line-height: 1;
	position: relative;
	top: 5px;
	right: 4px;
	content: "\e116";
	padding-right: 8px;
	color: #F15931;
}

.features-list .disabled:before {
	color: #3a3d44;
	content: "\e117";
}

.call-to-action {
	padding: 0 20px 50px;
}

.fw-package.price-table hr {
	margin-left: 30px;
	margin-right: 30px;
	opacity: 0.15;
}

.fw-switch-row {
	display: inline-block;
	font-size: 24px;
	position: relative;
	top: 4px;
	margin-right: 4px;
}

.fw-switch-row + .fw-default-row {
	display: inline-block;
}

.desc-col .fw-default-row {
	margin: 20px 0;
}

/* contact forms */
.contact-form {
	position: relative;
	z-index: 3;
}

.contact-form label[for] {
	display: none;
}

@media (min-width: 768px) {
	.contact-form-message textarea {
		min-height: 200px;
	}
}

.contact-form.parallax {
	padding: 15px 20px 5px;
	background-image: url(/static/img/parallax/map.jpg);
}

@media (min-width: 400px) {
	.contact-form.parallax {
		padding: 35px 40px 25px;
	}
}

@media (min-width: 1200px) {
	.contact-form.parallax {
		padding: 55px 60px 45px;
	}
}

.contact-form-respond {
	display: block;
	font-weight: 800;
}

.contact-form-respond:before {
	font-family: 'rt-icons-2';
	content: "\e71a";
	padding-right: 10px;
}

.contact-form p.form-group {
	margin-bottom: 15px;
}

.contact-form [class*="col-"] .form-group {
	margin: 0;
}

.contact-form [class*="col-"] .form-group + .form-group {
	margin-top: 10px;
}

.contact-form.transparent-background .form-control {
	background-color: transparent;
}

.contact-email {
	font-size: 40px;
	text-transform: uppercase;
}

@media (max-width: 767px) {
	.contact-email {
		font-size: 20px;
		margin-bottom: 6px;
	}

	.contact-form.parallax {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 40px;
	}
}

/* Form in tab with absolute button */
.with_abs_button.form-wrapper {
	position: relative;
}

.with_abs_button .form-submit {
	margin-top: 0;
}

.with_abs_button .form-submit [class*='col-'] {
	position: static;
	margin: 0 !important;
}

.wrap-forms .form-group {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.wrap-forms .form-group {
		margin-bottom: 0;
	}
}

.wrap-forms .form-group p {
	margin: 10px 0 0;
}

.wrap-forms .form-group textarea {
	min-height: 150px;
}

.has-placeholder label {
	display: none;
}

#messages_modal .fw-messages-wrap {
	position: absolute;
	left: 50%;
	top: 10%;
	margin-left: -150px;
	width: 300px;
}

#messages_modal ul {
	margin-bottom: 0;
}

/* partners carousel */
.owl-carousel.partners-carousel a,
.partner-link {
	display: block;
	text-align: center;
}

.owl-carousel.partners-carousel a img,
.partner-link img {
	opacity: 0.35;
}

.owl-carousel.partners-carousel a:hover img,
.partner-link:hover img {
	opacity: 1;
}

.owl-carousel.partners-carousel .center a img,
.center .partner-link img {
	opacity: 1;
}

/* Gallery Owl Carousel */
@media (min-width: 1200px) {
	.owl-center-scale.gallery-carousel.owl-center .owl-nav > div {
		width: 12.5%;
		top: 30px;
		bottom: 30px;
		height: auto;
		transform: none;
		font-size: 30px;
		text-transform: uppercase;
		color: #ffffff;
		font-family: 'Playfair Display', serif;
		font-weight: 700;
		line-height: 100%;
	}

	.owl-center-scale.gallery-carousel.owl-center .owl-nav > div:after {
		display: none;
	}

	.owl-center-scale.gallery-carousel.owl-center .owl-nav > div span {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
	}

	.owl-center-scale.gallery-carousel.owl-center .owl-nav > div:hover {
		color: #F15931;
	}
}

/*Stars*/
.star-rating, p.stars a {
	font-weight: 400;
	height: 1em;
}

.star-rating:before,
.star-rating span:before {
	content: "\f006\f006\f006\f006\f006";
	top: 0;
	left: 0;
	letter-spacing: 0.2em;
}

.star-rating span:before {
	content: "\f005\f005\f005\f005\f005";
}

.star-rating {
	overflow: hidden;
	position: relative;
	line-height: 1em;
	width: 5.55em;
	font-size: 14px;
	font-family: FontAwesome;
	display: inline-block;
	vertical-align: middle;
}

.star-rating:before {
	float: left;
	position: absolute;
}

.star-rating span {
	overflow: hidden;
	float: left;
	top: 0;
	left: 0;
	right: 0;
	position: absolute;
}

.star-rating span:before {
	display: block;
	white-space: nowrap;
}

.star-rating span:before {
	color: #F15931;
}

.star-rating:before {
	position: absolute;
	color: #F15931;
}

p.stars a:before,
p.stars a:hover ~ a:before {
	color: #F15931;
	content: "\f006";
}

p.stars {
	display: inline-block;
	margin: 0 0 10px;
	line-height: 1;
}

p.stars a {
	display: inline-block;
	position: relative;
	width: 1em;
	text-indent: -999em;
	margin-right: 1px;
}

p.stars a:before {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 1em;
	height: 1em;
	line-height: 1;
	font-family: FontAwesome;
	text-indent: 0;
}

p.stars.selected a.active:before, p.stars:hover a:before {
	content: "\f005";
	color: #F15931;
	opacity: 1;
}

p.stars.selected a.active ~ a:before {
	content: "\f005";
	color: #787878;
	opacity: .75;
}

p.stars.selected a:not(.active):before {
	content: "\f005";
	color: #F15931;
	opacity: 1;
}

.comment-form-rating {
	margin-top: 20px;
	margin-bottom: 10px;
}

.comment-form-rating .select-group {
	display: none;
}

/*
** Social Icons
*/
/* layout */
a[class*='socicon-']:before {
	font-family: 'socicon';
	font-size: 14px;
	line-height: 1;
}

a.social-icon {
	font-size: 0;
	line-height: 0;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
}

a.social-icon span {
	opacity: 0.7;
	display: block;
	font-size: 14px;
	text-transform: uppercase;
}

a.social-icon span:first-child {
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon [class*="rt-icon"] + span {
	font-size: 24px;
	margin-top: 8px;
	font-weight: bold;
}

a.social-icon + a.social-icon,
.tooltip + a.social-icon {
	margin-left: 25px;
}

a.social-icon.bg-icon + a.social-icon.bg-icon,
.tooltip + a.social-icon.bg-icon,
a.social-icon.color-bg-icon + a.social-icon.color-bg-icon,
.tooltip + a.social-icon.color-bg-icon,
a.social-icon.border-icon + a.social-icon.border-icon,
.tooltip + a.social-icon.border-icon {
	margin-left: 6px;
}

a.social-icon.block-icon {
	width: 100%;
}

a[class*='socicon-'].rounded-icon {
	border-radius: 50%;
}

a.big-icon {
	width: 112px;
	height: 112px;
	padding-top: 20px;
	padding-bottom: 20px;
	overflow: hidden;
}

a.big-icon[class*='socicon-']:before {
	font-size: 32px;
}

a.big-icon [class*='rt-icon']:before {
	font-size: 32px;
}

/* corporate colors */
a.color-icon.socicon-twitter {
	color: #1da1f2;
}

a.color-icon.socicon-facebook {
	color: #3b5998;
}

a.color-icon.socicon-google {
	color: #4285f4;
}

a.color-icon.socicon-googleplus {
	color: #db4437;
}

a.color-icon.socicon-pinterest {
	color: #bd081c;
}

a.color-icon.socicon-foursquare {
	color: #f94877;
}

a.color-icon.socicon-yahoo {
	color: #430297;
}

a.color-icon.socicon-skype {
	color: #00aff0;
}

a.color-icon.socicon-yelp {
	color: #c83218;
}

a.color-icon.socicon-feedburner {
	color: #ffcc00;
}

a.color-icon.socicon-linkedin {
	color: #007bb5;
}

a.color-icon.socicon-viadeo {
	color: #e4a000;
}

a.color-icon.socicon-xing {
	color: #005a60;
}

a.color-icon.socicon-myspace {
	color: #323232;
}

a.color-icon.socicon-soundcloud {
	color: #ff5500;
}

a.color-icon.socicon-spotify {
	color: #1ed760;
}

a.color-icon.socicon-grooveshark {
	color: #f67e03;
}

a.color-icon.socicon-lastfm {
	color: #d41316;
}

a.color-icon.socicon-youtube {
	color: #ff0000;
}

a.color-icon.socicon-vimeo {
	color: #1ab7ea;
}

a.color-icon.socicon-dailymotion {
	color: #004e72;
}

a.color-icon.socicon-vine {
	color: #00b489;
}

a.color-icon.socicon-flickr {
	color: #f40083;
}

a.color-icon.socicon-500px {
	color: #0099e5;
}

a.color-icon.socicon-instagram {
	color: #c32aa3;
}

a.color-icon.socicon-wordpress {
	color: #464646;
}

a.color-icon.socicon-tumblr {
	color: #35465d;
}

a.color-icon.socicon-blogger {
	color: #ec661c;
}

a.color-icon.socicon-technorati {
	color: #5cb030;
}

a.color-icon.socicon-reddit {
	color: #ff4500;
}

a.color-icon.socicon-dribbble {
	color: #ea4c89;
}

a.color-icon.socicon-stumbleupon {
	color: #e64011;
}

a.color-icon.socicon-digg {
	color: #1d1d1b;
}

a.color-icon.socicon-envato {
	color: #597c3a;
}

a.color-icon.socicon-behance {
	color: #00bf8f;
}

a.color-icon.socicon-delicious {
	color: #31a9ff;
}

a.color-icon.socicon-deviantart {
	color: #05cc47;
}

a.color-icon.socicon-forrst {
	color: #5b9a68;
}

a.color-icon.socicon-play {
	color: #5acdbd;
}

a.color-icon.socicon-zerply {
	color: #9dbc7a;
}

a.color-icon.socicon-wikipedia {
	color: #000000;
}

a.color-icon.socicon-apple {
	color: #a6b1b7;
}

a.color-icon.socicon-flattr {
	color: #f67c1a;
}

a.color-icon.socicon-github {
	color: #00bf8f;
}

a.color-icon.socicon-chimein {
	color: #888688;
}

a.color-icon.socicon-friendfeed {
	color: #2f72c4;
}

a.color-icon.socicon-newsvine {
	color: #00b489;
}

a.color-icon.socicon-identica {
	color: #000000;
}

a.color-icon.socicon-bebo {
	color: #ef1011;
}

a.color-icon.socicon-zynga {
	color: #dc0606;
}

a.color-icon.socicon-steam {
	color: #171A21;
}

a.color-icon.socicon-xbox {
	color: #92c83e;
}

a.color-icon.socicon-windows {
	color: #00bdf6;
}

a.color-icon.socicon-outlook {
	color: #0072c6;
}

a.color-icon.socicon-coderwall {
	color: #3e8dcc;
}

a.color-icon.socicon-tripadvisor {
	color: #000000;
}

a.color-icon.socicon-appnet {
	color: #494949;
}

a.color-icon.socicon-goodreads {
	color: #1abc9c;
}

a.color-icon.socicon-tripit {
	color: #1982c3;
}

a.color-icon.socicon-lanyrd {
	color: #3c80c9;
}

a.color-icon.socicon-slideshare {
	color: #e68523;
}

a.color-icon.socicon-buffer {
	color: #000000;
}

a.color-icon.socicon-rss {
	color: #f26109;
}

a.color-icon.socicon-vkontakte {
	color: #4a76a8;
}

a.color-icon.socicon-disqus {
	color: #2e9fff;
}

a.color-icon.socicon-houzz {
	color: #7cc04b;
}

a.color-icon.socicon-mail {
	color: #000000;
}

a.color-icon.socicon-patreon {
	color: #e44727;
}

a.color-icon.socicon-paypal {
	color: #009cde;
}

a.color-icon.socicon-playstation {
	color: #000000;
}

a.color-icon.socicon-smugmug {
	color: #acfd32;
}

a.color-icon.socicon-swarm {
	color: #ffa633;
}

a.color-icon.socicon-triplej {
	color: #e53531;
}

a.color-icon.socicon-yammer {
	color: #1175c4;
}

a.color-icon.socicon-stackoverflow {
	color: #fd9827;
}

a.color-icon.socicon-drupal {
	color: #00598e;
}

a.color-icon.socicon-odnoklassniki {
	color: #f48420;
}

a.color-icon.socicon-android {
	color: #a4c639;
}

a.color-icon.socicon-meetup {
	color: #e2373c;
}

a.color-icon.socicon-persona {
	color: #e6753d;
}

a.color-icon.socicon-amazon {
	color: #ff9900;
}

a.color-icon.socicon-ello {
	color: #000000;
}

a.color-icon.socicon-mixcloud {
	color: #314359;
}

a.color-icon.socicon-8tracks {
	color: #122c4b;
}

a.color-icon.socicon-twitch {
	color: #6441a5;
}

a.color-icon.socicon-airbnb {
	color: #ff5a5f;
}

a.color-icon.socicon-pocket {
	color: #ee4056;
}

a[class*="socicon-"].socicon-twitter:hover {
	color: #1da1f2;
	border-color: #1da1f2;
}

a[class*="socicon-"].socicon-facebook:hover {
	color: #3b5998;
	border-color: #3b5998;
}

a[class*="socicon-"].socicon-google:hover {
	color: #4285f4;
	border-color: #4285f4;
}

a[class*="socicon-"].socicon-googleplus:hover {
	color: #db4437;
	border-color: #db4437;
}

a[class*="socicon-"].socicon-pinterest:hover {
	color: #bd081c;
	border-color: #bd081c;
}

a[class*="socicon-"].socicon-foursquare:hover {
	color: #f94877;
	border-color: #f94877;
}

a[class*="socicon-"].socicon-yahoo:hover {
	color: #430297;
	border-color: #430297;
}

a[class*="socicon-"].socicon-skype:hover {
	color: #00aff0;
	border-color: #00aff0;
}

a[class*="socicon-"].socicon-yelp:hover {
	color: #c83218;
	border-color: #c83218;
}

a[class*="socicon-"].socicon-feedburner:hover {
	color: #ffcc00;
	border-color: #ffcc00;
}

a[class*="socicon-"].socicon-linkedin:hover {
	color: #007bb5;
	border-color: #007bb5;
}

a[class*="socicon-"].socicon-viadeo:hover {
	color: #e4a000;
	border-color: #e4a000;
}

a[class*="socicon-"].socicon-xing:hover {
	color: #005a60;
	border-color: #005a60;
}

a[class*="socicon-"].socicon-myspace:hover {
	color: #323232;
	border-color: #323232;
}

a[class*="socicon-"].socicon-soundcloud:hover {
	color: #ff5500;
	border-color: #ff5500;
}

a[class*="socicon-"].socicon-spotify:hover {
	color: #1ed760;
	border-color: #1ed760;
}

a[class*="socicon-"].socicon-grooveshark:hover {
	color: #f67e03;
	border-color: #f67e03;
}

a[class*="socicon-"].socicon-lastfm:hover {
	color: #d41316;
	border-color: #d41316;
}

a[class*="socicon-"].socicon-youtube:hover {
	color: #ff0000;
	border-color: #ff0000;
}

a[class*="socicon-"].socicon-vimeo:hover {
	color: #1ab7ea;
	border-color: #1ab7ea;
}

a[class*="socicon-"].socicon-dailymotion:hover {
	color: #004e72;
	border-color: #004e72;
}

a[class*="socicon-"].socicon-vine:hover {
	color: #00b489;
	border-color: #00b489;
}

a[class*="socicon-"].socicon-flickr:hover {
	color: #f40083;
	border-color: #f40083;
}

a[class*="socicon-"].socicon-500px:hover {
	color: #0099e5;
	border-color: #0099e5;
}

a[class*="socicon-"].socicon-instagram:hover {
	color: #c32aa3;
	border-color: #c32aa3;
}

a[class*="socicon-"].socicon-wordpress:hover {
	color: #464646;
	border-color: #464646;
}

a[class*="socicon-"].socicon-tumblr:hover {
	color: #35465d;
	border-color: #35465d;
}

a[class*="socicon-"].socicon-blogger:hover {
	color: #ec661c;
	border-color: #ec661c;
}

a[class*="socicon-"].socicon-technorati:hover {
	color: #5cb030;
	border-color: #5cb030;
}

a[class*="socicon-"].socicon-reddit:hover {
	color: #ff4500;
	border-color: #ff4500;
}

a[class*="socicon-"].socicon-dribbble:hover {
	color: #ea4c89;
	border-color: #ea4c89;
}

a[class*="socicon-"].socicon-stumbleupon:hover {
	color: #e64011;
	border-color: #e64011;
}

a[class*="socicon-"].socicon-digg:hover {
	color: #1d1d1b;
	border-color: #1d1d1b;
}

a[class*="socicon-"].socicon-envato:hover {
	color: #597c3a;
	border-color: #597c3a;
}

a[class*="socicon-"].socicon-behance:hover {
	color: #00bf8f;
	border-color: #00bf8f;
}

a[class*="socicon-"].socicon-delicious:hover {
	color: #31a9ff;
	border-color: #31a9ff;
}

a[class*="socicon-"].socicon-deviantart:hover {
	color: #05cc47;
	border-color: #05cc47;
}

a[class*="socicon-"].socicon-forrst:hover {
	color: #5b9a68;
	border-color: #5b9a68;
}

a[class*="socicon-"].socicon-play:hover {
	color: #5acdbd;
	border-color: #5acdbd;
}

a[class*="socicon-"].socicon-zerply:hover {
	color: #9dbc7a;
	border-color: #9dbc7a;
}

a[class*="socicon-"].socicon-wikipedia:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-apple:hover {
	color: #a6b1b7;
	border-color: #a6b1b7;
}

a[class*="socicon-"].socicon-flattr:hover {
	color: #f67c1a;
	border-color: #f67c1a;
}

a[class*="socicon-"].socicon-github:hover {
	color: #00bf8f;
	border-color: #00bf8f;
}

a[class*="socicon-"].socicon-chimein:hover {
	color: #888688;
	border-color: #888688;
}

a[class*="socicon-"].socicon-friendfeed:hover {
	color: #2f72c4;
	border-color: #2f72c4;
}

a[class*="socicon-"].socicon-newsvine:hover {
	color: #00b489;
	border-color: #00b489;
}

a[class*="socicon-"].socicon-identica:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-bebo:hover {
	color: #ef1011;
	border-color: #ef1011;
}

a[class*="socicon-"].socicon-zynga:hover {
	color: #dc0606;
	border-color: #dc0606;
}

a[class*="socicon-"].socicon-steam:hover {
	color: #171A21;
	border-color: #171A21;
}

a[class*="socicon-"].socicon-xbox:hover {
	color: #92c83e;
	border-color: #92c83e;
}

a[class*="socicon-"].socicon-windows:hover {
	color: #00bdf6;
	border-color: #00bdf6;
}

a[class*="socicon-"].socicon-outlook:hover {
	color: #0072c6;
	border-color: #0072c6;
}

a[class*="socicon-"].socicon-coderwall:hover {
	color: #3e8dcc;
	border-color: #3e8dcc;
}

a[class*="socicon-"].socicon-tripadvisor:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-appnet:hover {
	color: #494949;
	border-color: #494949;
}

a[class*="socicon-"].socicon-goodreads:hover {
	color: #1abc9c;
	border-color: #1abc9c;
}

a[class*="socicon-"].socicon-tripit:hover {
	color: #1982c3;
	border-color: #1982c3;
}

a[class*="socicon-"].socicon-lanyrd:hover {
	color: #3c80c9;
	border-color: #3c80c9;
}

a[class*="socicon-"].socicon-slideshare:hover {
	color: #e68523;
	border-color: #e68523;
}

a[class*="socicon-"].socicon-buffer:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-rss:hover {
	color: #f26109;
	border-color: #f26109;
}

a[class*="socicon-"].socicon-vkontakte:hover {
	color: #4a76a8;
	border-color: #4a76a8;
}

a[class*="socicon-"].socicon-disqus:hover {
	color: #2e9fff;
	border-color: #2e9fff;
}

a[class*="socicon-"].socicon-houzz:hover {
	color: #7cc04b;
	border-color: #7cc04b;
}

a[class*="socicon-"].socicon-mail:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-patreon:hover {
	color: #e44727;
	border-color: #e44727;
}

a[class*="socicon-"].socicon-paypal:hover {
	color: #009cde;
	border-color: #009cde;
}

a[class*="socicon-"].socicon-playstation:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-smugmug:hover {
	color: #acfd32;
	border-color: #acfd32;
}

a[class*="socicon-"].socicon-swarm:hover {
	color: #ffa633;
	border-color: #ffa633;
}

a[class*="socicon-"].socicon-triplej:hover {
	color: #e53531;
	border-color: #e53531;
}

a[class*="socicon-"].socicon-yammer:hover {
	color: #1175c4;
	border-color: #1175c4;
}

a[class*="socicon-"].socicon-stackoverflow:hover {
	color: #fd9827;
	border-color: #fd9827;
}

a[class*="socicon-"].socicon-drupal:hover {
	color: #00598e;
	border-color: #00598e;
}

a[class*="socicon-"].socicon-odnoklassniki:hover {
	color: #f48420;
	border-color: #f48420;
}

a[class*="socicon-"].socicon-android:hover {
	color: #a4c639;
	border-color: #a4c639;
}

a[class*="socicon-"].socicon-meetup:hover {
	color: #e2373c;
	border-color: #e2373c;
}

a[class*="socicon-"].socicon-persona:hover {
	color: #e6753d;
	border-color: #e6753d;
}

a[class*="socicon-"].socicon-amazon:hover {
	color: #ff9900;
	border-color: #ff9900;
}

a[class*="socicon-"].socicon-ello:hover {
	color: #000000;
	border-color: #000000;
}

a[class*="socicon-"].socicon-mixcloud:hover {
	color: #314359;
	border-color: #314359;
}

a[class*="socicon-"].socicon-8tracks:hover {
	color: #122c4b;
	border-color: #122c4b;
}

a[class*="socicon-"].socicon-twitch:hover {
	color: #6441a5;
	border-color: #6441a5;
}

a[class*="socicon-"].socicon-airbnb:hover {
	color: #ff5a5f;
	border-color: #ff5a5f;
}

a[class*="socicon-"].socicon-pocket:hover {
	color: #ee4056;
	border-color: #ee4056;
}

/*corporate background colors*/
.color-bg-icon {
	color: #ffffff;
}

.color-bg-icon.socicon-twitter, a.socicon-twitter.bg-icon:hover, .socicon-twitter.color-icon.border-icon:hover {
	background-color: #1da1f2;
}

.color-bg-icon.socicon-facebook, a.socicon-facebook.bg-icon:hover, .socicon-facebook.color-icon.border-icon:hover {
	background-color: #3b5998;
}

.color-bg-icon.socicon-google, a.socicon-google.bg-icon:hover, .socicon-google.color-icon.border-icon:hover {
	background-color: #4285f4;
}

.color-bg-icon.socicon-googleplus, a.socicon-googleplus.bg-icon:hover, .socicon-googleplus.color-icon.border-icon:hover {
	background-color: #db4437;
}

.color-bg-icon.socicon-pinterest, a.socicon-pinterest.bg-icon:hover, .socicon-pinterest.color-icon.border-icon:hover {
	background-color: #bd081c;
}

.color-bg-icon.socicon-foursquare, a.socicon-foursquare.bg-icon:hover, .socicon-foursquare.color-icon.border-icon:hover {
	background-color: #f94877;
}

.color-bg-icon.socicon-yahoo, a.socicon-yahoo.bg-icon:hover, .socicon-yahoo.color-icon.border-icon:hover {
	background-color: #430297;
}

.color-bg-icon.socicon-skype, a.socicon-skype.bg-icon:hover, .socicon-skype.color-icon.border-icon:hover {
	background-color: #00aff0;
}

.color-bg-icon.socicon-yelp, a.socicon-yelp.bg-icon:hover, .socicon-yelp.color-icon.border-icon:hover {
	background-color: #c83218;
}

.color-bg-icon.socicon-feedburner, a.socicon-feedburner.bg-icon:hover, .socicon-feedburner.color-icon.border-icon:hover {
	background-color: #ffcc00;
}

.color-bg-icon.socicon-linkedin, a.socicon-linkedin.bg-icon:hover, .socicon-linkedin.color-icon.border-icon:hover {
	background-color: #007bb5;
}

.color-bg-icon.socicon-viadeo, a.socicon-viadeo.bg-icon:hover, .socicon-viadeo.color-icon.border-icon:hover {
	background-color: #e4a000;
}

.color-bg-icon.socicon-xing, a.socicon-xing.bg-icon:hover, .socicon-xing.color-icon.border-icon:hover {
	background-color: #005a60;
}

.color-bg-icon.socicon-myspace, a.socicon-myspace.bg-icon:hover, .socicon-myspace.color-icon.border-icon:hover {
	background-color: #323232;
}

.color-bg-icon.socicon-soundcloud, a.socicon-soundcloud.bg-icon:hover, .socicon-soundcloud.color-icon.border-icon:hover {
	background-color: #ff5500;
}

.color-bg-icon.socicon-spotify, a.socicon-spotify.bg-icon:hover, .socicon-spotify.color-icon.border-icon:hover {
	background-color: #1ed760;
}

.color-bg-icon.socicon-grooveshark, a.socicon-grooveshark.bg-icon:hover, .socicon-grooveshark.color-icon.border-icon:hover {
	background-color: #f67e03;
}

.color-bg-icon.socicon-lastfm, a.socicon-lastfm.bg-icon:hover, .socicon-lastfm.color-icon.border-icon:hover {
	background-color: #d41316;
}

.color-bg-icon.socicon-youtube, a.socicon-youtube.bg-icon:hover, .socicon-youtube.color-icon.border-icon:hover {
	background-color: #ff0000;
}

.color-bg-icon.socicon-vimeo, a.socicon-vimeo.bg-icon:hover, .socicon-vimeo.color-icon.border-icon:hover {
	background-color: #1ab7ea;
}

.color-bg-icon.socicon-dailymotion, a.socicon-dailymotion.bg-icon:hover, .socicon-dailymotion.color-icon.border-icon:hover {
	background-color: #004e72;
}

.color-bg-icon.socicon-vine, a.socicon-vine.bg-icon:hover, .socicon-vine.color-icon.border-icon:hover {
	background-color: #00b489;
}

.color-bg-icon.socicon-flickr, a.socicon-flickr.bg-icon:hover, .socicon-flickr.color-icon.border-icon:hover {
	background-color: #f40083;
}

.color-bg-icon.socicon-500px, a.socicon-500px.bg-icon:hover, .socicon-500px.color-icon.border-icon:hover {
	background-color: #0099e5;
}

.color-bg-icon.socicon-instagram, a.socicon-instagram.bg-icon:hover, .socicon-instagram.color-icon.border-icon:hover {
	background-color: #c32aa3;
}

.color-bg-icon.socicon-wordpress, a.socicon-wordpress.bg-icon:hover, .socicon-wordpress.color-icon.border-icon:hover {
	background-color: #464646;
}

.color-bg-icon.socicon-tumblr, a.socicon-tumblr.bg-icon:hover, .socicon-tumblr.color-icon.border-icon:hover {
	background-color: #35465d;
}

.color-bg-icon.socicon-blogger, a.socicon-blogger.bg-icon:hover, .socicon-blogger.color-icon.border-icon:hover {
	background-color: #ec661c;
}

.color-bg-icon.socicon-technorati, a.socicon-technorati.bg-icon:hover, .socicon-technorati.color-icon.border-icon:hover {
	background-color: #5cb030;
}

.color-bg-icon.socicon-reddit, a.socicon-reddit.bg-icon:hover, .socicon-reddit.color-icon.border-icon:hover {
	background-color: #ff4500;
}

.color-bg-icon.socicon-dribbble, a.socicon-dribbble.bg-icon:hover, .socicon-dribbble.color-icon.border-icon:hover {
	background-color: #ea4c89;
}

.color-bg-icon.socicon-stumbleupon, a.socicon-stumbleupon.bg-icon:hover, .socicon-stumbleupon.color-icon.border-icon:hover {
	background-color: #e64011;
}

.color-bg-icon.socicon-digg, a.socicon-digg.bg-icon:hover, .socicon-digg.color-icon.border-icon:hover {
	background-color: #1d1d1b;
}

.color-bg-icon.socicon-envato, a.socicon-envato.bg-icon:hover, .socicon-envato.color-icon.border-icon:hover {
	background-color: #597c3a;
}

.color-bg-icon.socicon-behance, a.socicon-behance.bg-icon:hover, .socicon-behance.color-icon.border-icon:hover {
	background-color: #00bf8f;
}

.color-bg-icon.socicon-delicious, a.socicon-delicious.bg-icon:hover, .socicon-delicious.color-icon.border-icon:hover {
	background-color: #31a9ff;
}

.color-bg-icon.socicon-deviantart, a.socicon-deviantart.bg-icon:hover, .socicon-deviantart.color-icon.border-icon:hover {
	background-color: #05cc47;
}

.color-bg-icon.socicon-forrst, a.socicon-forrst.bg-icon:hover, .socicon-forrst.color-icon.border-icon:hover {
	background-color: #5b9a68;
}

.color-bg-icon.socicon-play, a.socicon-play.bg-icon:hover, .socicon-play.color-icon.border-icon:hover {
	background-color: #5acdbd;
}

.color-bg-icon.socicon-zerply, a.socicon-zerply.bg-icon:hover, .socicon-zerply.color-icon.border-icon:hover {
	background-color: #9dbc7a;
}

.color-bg-icon.socicon-wikipedia, a.socicon-wikipedia.bg-icon:hover, .socicon-wikipedia.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-apple, a.socicon-apple.bg-icon:hover, .socicon-apple.color-icon.border-icon:hover {
	background-color: #a6b1b7;
}

.color-bg-icon.socicon-flattr, a.socicon-flattr.bg-icon:hover, .socicon-flattr.color-icon.border-icon:hover {
	background-color: #f67c1a;
}

.color-bg-icon.socicon-github, a.socicon-github.bg-icon:hover, .socicon-github.color-icon.border-icon:hover {
	background-color: #00bf8f;
}

.color-bg-icon.socicon-chimein, a.socicon-chimein.bg-icon:hover, .socicon-chimein.color-icon.border-icon:hover {
	background-color: #888688;
}

.color-bg-icon.socicon-friendfeed, a.socicon-friendfeed.bg-icon:hover, .socicon-friendfeed.color-icon.border-icon:hover {
	background-color: #2f72c4;
}

.color-bg-icon.socicon-newsvine, a.socicon-newsvine.bg-icon:hover, .socicon-newsvine.color-icon.border-icon:hover {
	background-color: #00b489;
}

.color-bg-icon.socicon-identica, a.socicon-identica.bg-icon:hover, .socicon-identica.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-bebo, a.socicon-bebo.bg-icon:hover, .socicon-bebo.color-icon.border-icon:hover {
	background-color: #ef1011;
}

.color-bg-icon.socicon-zynga, a.socicon-zynga.bg-icon:hover, .socicon-zynga.color-icon.border-icon:hover {
	background-color: #dc0606;
}

.color-bg-icon.socicon-steam, a.socicon-steam.bg-icon:hover, .socicon-steam.color-icon.border-icon:hover {
	background-color: #171A21;
}

.color-bg-icon.socicon-xbox, a.socicon-xbox.bg-icon:hover, .socicon-xbox.color-icon.border-icon:hover {
	background-color: #92c83e;
}

.color-bg-icon.socicon-windows, a.socicon-windows.bg-icon:hover, .socicon-windows.color-icon.border-icon:hover {
	background-color: #00bdf6;
}

.color-bg-icon.socicon-outlook, a.socicon-outlook.bg-icon:hover, .socicon-outlook.color-icon.border-icon:hover {
	background-color: #0072c6;
}

.color-bg-icon.socicon-coderwall, a.socicon-coderwall.bg-icon:hover, .socicon-coderwall.color-icon.border-icon:hover {
	background-color: #3e8dcc;
}

.color-bg-icon.socicon-tripadvisor, a.socicon-tripadvisor.bg-icon:hover, .socicon-tripadvisor.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-appnet, a.socicon-appnet.bg-icon:hover, .socicon-appnet.color-icon.border-icon:hover {
	background-color: #494949;
}

.color-bg-icon.socicon-goodreads, a.socicon-goodreads.bg-icon:hover, .socicon-goodreads.color-icon.border-icon:hover {
	background-color: #1abc9c;
}

.color-bg-icon.socicon-tripit, a.socicon-tripit.bg-icon:hover, .socicon-tripit.color-icon.border-icon:hover {
	background-color: #1982c3;
}

.color-bg-icon.socicon-lanyrd, a.socicon-lanyrd.bg-icon:hover, .socicon-lanyrd.color-icon.border-icon:hover {
	background-color: #3c80c9;
}

.color-bg-icon.socicon-slideshare, a.socicon-slideshare.bg-icon:hover, .socicon-slideshare.color-icon.border-icon:hover {
	background-color: #e68523;
}

.color-bg-icon.socicon-buffer, a.socicon-buffer.bg-icon:hover, .socicon-buffer.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-rss, a.socicon-rss.bg-icon:hover, .socicon-rss.color-icon.border-icon:hover {
	background-color: #f26109;
}

.color-bg-icon.socicon-vkontakte, a.socicon-vkontakte.bg-icon:hover, .socicon-vkontakte.color-icon.border-icon:hover {
	background-color: #4a76a8;
}

.color-bg-icon.socicon-disqus, a.socicon-disqus.bg-icon:hover, .socicon-disqus.color-icon.border-icon:hover {
	background-color: #2e9fff;
}

.color-bg-icon.socicon-houzz, a.socicon-houzz.bg-icon:hover, .socicon-houzz.color-icon.border-icon:hover {
	background-color: #7cc04b;
}

.color-bg-icon.socicon-mail, a.socicon-mail.bg-icon:hover, .socicon-mail.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-patreon, a.socicon-patreon.bg-icon:hover, .socicon-patreon.color-icon.border-icon:hover {
	background-color: #e44727;
}

.color-bg-icon.socicon-paypal, a.socicon-paypal.bg-icon:hover, .socicon-paypal.color-icon.border-icon:hover {
	background-color: #009cde;
}

.color-bg-icon.socicon-playstation, a.socicon-playstation.bg-icon:hover, .socicon-playstation.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-smugmug, a.socicon-smugmug.bg-icon:hover, .socicon-smugmug.color-icon.border-icon:hover {
	background-color: #acfd32;
}

.color-bg-icon.socicon-swarm, a.socicon-swarm.bg-icon:hover, .socicon-swarm.color-icon.border-icon:hover {
	background-color: #ffa633;
}

.color-bg-icon.socicon-triplej, a.socicon-triplej.bg-icon:hover, .socicon-triplej.color-icon.border-icon:hover {
	background-color: #e53531;
}

.color-bg-icon.socicon-yammer, a.socicon-yammer.bg-icon:hover, .socicon-yammer.color-icon.border-icon:hover {
	background-color: #1175c4;
}

.color-bg-icon.socicon-stackoverflow, a.socicon-stackoverflow.bg-icon:hover, .socicon-stackoverflow.color-icon.border-icon:hover {
	background-color: #fd9827;
}

.color-bg-icon.socicon-drupal, a.socicon-drupal.bg-icon:hover, .socicon-drupal.color-icon.border-icon:hover {
	background-color: #00598e;
}

.color-bg-icon.socicon-odnoklassniki, a.socicon-odnoklassniki.bg-icon:hover, .socicon-odnoklassniki.color-icon.border-icon:hover {
	background-color: #f48420;
}

.color-bg-icon.socicon-android, a.socicon-android.bg-icon:hover, .socicon-android.color-icon.border-icon:hover {
	background-color: #a4c639;
}

.color-bg-icon.socicon-meetup, a.socicon-meetup.bg-icon:hover, .socicon-meetup.color-icon.border-icon:hover {
	background-color: #e2373c;
}

.color-bg-icon.socicon-persona, a.socicon-persona.bg-icon:hover, .socicon-persona.color-icon.border-icon:hover {
	background-color: #e6753d;
}

.color-bg-icon.socicon-amazon, a.socicon-amazon.bg-icon:hover, .socicon-amazon.color-icon.border-icon:hover {
	background-color: #ff9900;
}

.color-bg-icon.socicon-ello, a.socicon-ello.bg-icon:hover, .socicon-ello.color-icon.border-icon:hover {
	background-color: #000000;
}

.color-bg-icon.socicon-mixcloud, a.socicon-mixcloud.bg-icon:hover, .socicon-mixcloud.color-icon.border-icon:hover {
	background-color: #314359;
}

.color-bg-icon.socicon-8tracks, a.socicon-8tracks.bg-icon:hover, .socicon-8tracks.color-icon.border-icon:hover {
	background-color: #122c4b;
}

.color-bg-icon.socicon-twitch, a.socicon-twitch.bg-icon:hover, .socicon-twitch.color-icon.border-icon:hover {
	background-color: #6441a5;
}

.color-bg-icon.socicon-airbnb, a.socicon-airbnb.bg-icon:hover, .socicon-airbnb.color-icon.border-icon:hover {
	background-color: #ff5a5f;
}

.color-bg-icon.socicon-pocket, a.socicon-pocket.bg-icon:hover, .socicon-pocket.color-icon.border-icon:hover {
	background-color: #ee4056;
}

a.social-icon {
	color: #b2b2b2;
}

a.border-icon {
	border: 1px solid #e4e4e4;
	padding: 11px 12px;
	margin-bottom: 4px;
	margin-top: 4px;
	border-radius: 0;
}

a.border-icon:before {
	line-height: 16px;
}

a.bg-icon {
	color: #ffffff;
	background-color: #e4e4e4;
	padding: 12px 14px;
	margin-bottom: 4px;
}

a.bg-icon:before {
	font-size: 12px;
	line-height: 16px;
}

.ds a.bg-icon {
	background-color: rgba(255, 255, 255, 0.05);
}

a.social-icon.color-icon:hover {
	color: #3a3d44;
}

a.social-icon.bg-icon:hover {
	color: #ffffff;
}

a.color-bg-icon {
	color: #ffffff;
	background-color: #3a3d44;
	margin-bottom: 4px;
	padding: 12px 13px;
}

a.color-bg-icon:before {
	font-size: 14px;
	line-height: 16px;
}

a.social-icon.color-bg-icon:hover {
	background-color: rgba(120, 120, 120, 0.2);
}

a.color-icon:hover {
	color: #3a3d44;
}

a.color-icon.border-icon:hover {
	color: #ffffff;
	background-color: #F15931;
	border-color: transparent;
}

.big-icons a.border-icon {
	padding: 15px 14px 16px;
}

.big-icons a.border-icon:before {
	font-size: 20px;
}

.demo-icon {
	display: inline-block;
	text-align: center;
	border-bottom: 1px solid rgba(120, 120, 120, 0.2);
	padding: 15px 20px 30px;
	margin-bottom: 30px;
}

.demo-icon span {
	display: block;
}

.demo-icon .name {
	font-size: 1.01em;
	font-weight: 700;
}

.demo-icon a[class*="socicon-"] {
	margin: 10px 0 0;
}

.demo-icon a[class*="socicon-"]:before {
	font-size: 24px;
}

[class*="socicon-"].text-icon {
	font-family: 'Open Sans', sans-serif !important;
	font-weight: 600;
}

[class*="socicon-"].text-icon:before {
	display: none;
}

.text-icon {
	text-transform: uppercase;
}

.share_buttons > span {
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	margin-right: 10px;
}



.col-md-5th-1{
	width: 20%!important;
}


.kachel-p,
.kachel{
    position: relative;
    color: white;
}

.kachel:hover{
    color: #e8e8e8;
}

.kachel-img{
    position: relative;
    top: 0;
}

.kachel-text-lyer{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0f0f0f;
    opacity: 0.4;
}

.kachel-text{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.kachel-text h2{
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 90%;
}

.kachel-text h2 span{
    display: block;
    margin-top: 15px;
}

.loipenstatus{
    position: relative;
    display: block;
    border-radius: 50%;
    background: red;
    width: 15px;
    float: left;
    margin: 7px 7px 0 0;
    height: 15px;
}

.loipenstatus.active{
    background: darkgreen;
}

.loipenborder{
    border-bottom: 1px solid green;
}

@media only screen and (max-width: 1200px) {
	.col-md-5th-1{
		width: 100%!important;
	}
}




