/*
**shop
*/
input[disabled] {
	opacity: .4;
	cursor: not-allowed;
}

/* sort panel */
.shop-sorting {
	line-height: 1;
	margin-top: -10px;
}

.shop-sorting label {
	padding-right: 10px;
}

.shop-sorting .selectize-input {
	min-width: 270px;
}

.woocommerce-ordering {
	display: inline-block;
}

.woocommerce-result-count {
	line-height: 40px;
	margin-bottom: 0;
}

#toggle_shop_view:before,
#toggle_shop_view:after {
	font-family: 'FontAwesome';
	display: inline-block;
	color: #787878;
	width: 36px;
	height: 36px;
	line-height: 36px;
	text-align: center;
	font-size: 28px;
	border-radius: 1px;
	transition: all 0.2s ease-in-out 0s;
}

#toggle_shop_view:before {
	content: "\f009";
	margin-right: 5px;
	color: #F15931;
}

#toggle_shop_view:after {
	content: "\f0c9";
}

#toggle_shop_view.grid-view:before {
	color: #787878;
}

#toggle_shop_view.grid-view:after {
	color: #F15931;
}

#sort_view {
	color: #3a3d44;
	display: inline-block;
	padding: 12px 16px 13px;
	font-size: 14px;
	background-color: #ffffff;
}

.columns-1 .product {
	width: 100%;
	margin-right: 0;
	float: none;
}

@media (min-width: 992px) {
	.columns-3 .product {
		width: 33.33333333%;
		float: left;
	}

	.columns-4 .product {
		width: 25%;
		float: left;
	}

	.col-sm-7 .columns-3 .product,
  .col-sm-8 .columns-3 .product {
		width: 50%;
		float: left;
	}

	.col-sm-7 .columns-4 .product,
  .col-sm-8 .columns-4 .product {
		width: 50%;
		float: left;
	}

	.columns-2 .product {
		width: 50%;
		float: left;
	}

	.columns-2 .product:nth-child(2n + 1) {
		clear: both;
	}
}

@media (min-width: 1200px) {
	.col-sm-7 .columns-3 .product,
  .col-sm-8 .columns-3 .product {
		width: 33.33333333%;
	}

	.col-sm-7 .columns-4 .product,
  .col-sm-8 .columns-4 .product {
		width: 25%;
	}
}

.product .images,
.product .thumbnails a,
.product .item-media {
	overflow: hidden;
	display: block;
}

.product .images img,
.product .thumbnails a img,
.product .item-media img {
	transition: all 0.2s ease-in-out 0s;
}

.product .images:hover img,
.product .thumbnails a:hover img,
.product .item-media:hover img {
	opacity: 0.8;
	transform: scale(1.1);
}

.products {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 30px;
	margin-left: -15px;
	margin-right: -15px;
}

.products:before, .products:after {
	content: '';
	clear: both;
	display: block;
}

.upsells .products,
.related .products {
	margin-left: 0;
	margin-right: 0;
}

li.product {
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 30px;
}

.owl-carousel li.product {
	margin-bottom: 0;
}

.product .item-media .price {
	position: absolute;
	top: 10px;
	left: 10px;
	padding: 7px 10px;
	border-radius: 10px;
	font-weight: 600;
	z-index: 1;
	color: #fff;
	background: #F15931;
}

.product .item-media .price ins {
	color: #fff;
}

.product .item-media .price del {
	color: #3a3d44;
}

.owl-item .product {
	padding: 0;
}

.product .row {
	position: relative;
}

.product > [class*="col-"] {
	position: static;
	margin-top: 0;
	margin-bottom: 30px;
}

.product > [class*="col-"].images a {
	display: block;
	position: relative;
}

.product h2,
.product h2:first-child {
	font-size: 20px;
	margin-top: 5px;
	margin-bottom: 20px;
}

.product .rating-title {
	font-size: 1.2em;
}

.product h3.entry-title {
	font-size: 24px;
}

.product .item-meta + .entry-title {
	margin-top: 5px;
}

.product .product-media {
	position: relative;
}

.product .images {
	position: relative;
}

.product .newproduct,
.product .onsale {
	position: absolute;
	left: 10px;
	bottom: 10px;
	background-color: #F15931;
	border-radius: 10px;
	color: #ffffff;
	padding: 5px 18px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	z-index: 1;
}

.product .newproduct {
	top: 10px;
	right: 10px;
	left: auto;
	bottom: auto;
}

.related,
.upsells {
	margin-top: 80px;
	padding-right: 15px;
	padding-left: 15px;
}

.related > h2:first-child,
.upsells > h2:first-child {
	font-size: 30px;
	text-align: center;
	margin: auto;
}

.related .products {
	margin-top: 55px;
}

.upsells + .related {
	margin-top: 30px;
}

.price {
	color: #F15931;
}

.price del {
	color: #787878;
	margin-right: 10px;
}

.price ins {
	text-decoration: none;
}

.woocommerce-loop-product__link + .price {
	display: block;
	margin-top: 5px;
	margin-bottom: 20px;
}

.price[class*="bg_color"] {
	font-weight: 600;
	padding: 7px 10px;
	color: #fff;
	border-radius: 10px;
}

.widget .price {
	line-height: 1;
}

.woocommerce-loop-product__link:hover, .woocommerce-loop-product__link:active, .woocommerce-loop-product__link:focus {
	color: #F15931;
}

/* single-product */
.thumbnails-wrap {
	margin-top: 9px;
}

.summary h1 {
	margin-top: 0;
	font-size: 24px;
}

@media (min-width: 1200px) {
	.summary h1 {
		font-size: 30px;
	}
}

.summary .product-rating,
.summary .woocommerce-product-rating {
	margin-bottom: 25px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.summary .price {
	position: static;
	display: block;
	font-size: 14px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	color: #03a8cb;
}

.summary .woocommerce-variation-price .price {
	padding-top: 0;
}

.summary .star-rating span:before,
.summary .star-rating:before {
	color: #03a8cb;
}

.summary .stock {
	margin-bottom: 0;
}

.summary .email-to {
	margin-bottom: 10px;
}

.summary .product-option-name {
	display: inline-block;
	margin: 10px 0;
}

.summary .theme_button {
	margin-top: 0;
}

.summary .theme_button.wc-variation-selection-needed {
	opacity: .4;
	cursor: not-allowed;
}

.summary .posted_in {
	margin: 10px 0;
}

.summary .posted_in .small_button {
	margin-left: 5px;
	padding-left: 6px;
	padding-right: 6px;
}

.summary .product_meta {
	margin-top: 20px;
	padding-bottom: 30px;
	border-bottom: 1px solid rgba(120, 120, 120, 0.2);
	font-size: 0;
}

.summary .product_meta > * {
	font-size: 13px;
}

.summary .product_meta > span {
	display: inline-block;
	min-width: 50%;
}

.summary .product_meta > span.sku_wrapper {
	display: block;
}

.summary .product_meta a {
	margin: 0 0 0 6px;
}

.summary [itemprop="description"] {
	margin: 30px 0;
}

.summary ul.list1 li {
	padding-top: 15px;
	padding-bottom: 20px;
}

.summary .reset_variations {
	color: transparent;
	font-size: 0;
	padding: 0 5px;
}

.summary .reset_variations:before {
	font-family: 'rt-icons-2';
	content: "\e117";
	font-size: 18px;
	color: #3a3d44;
}

.summary label {
	margin: 0;
}

.summary select {
	padding-left: 20px;
	padding-right: 20px;
}

.summary [class*='add-to-cart'] {
	margin: 10px 0;
}

.summary [class*='add-to-cart'] .theme_button {
	vertical-align: middle;
}

.summary .share_buttons {
	background-color: transparent;
	padding: 0 0 30px;
	margin: 0 0 40px;
	border-bottom: 1px solid rgba(120, 120, 120, 0.2);
}

.summary .variations + .share_buttons {
	border-top: 1px solid rgba(120, 120, 120, 0.2);
	padding-top: 30px;
	margin-top: 10px;
}

.quantity {
	position: relative;
	display: inline-block;
}

.quantity [type="button"] {
	position: absolute;
	right: 20px;
	top: 16px;
	line-height: 1;
	color: transparent;
	border: none;
	width: 22px;
	height: 10px;
	background-color: transparent;
	z-index: 1;
}

.quantity [type="button"] + i {
	position: absolute;
	top: 16px;
	right: 20px;
	font-size: 16px;
	width: 22px;
	text-align: center;
	line-height: 10px;
	color: #F15931;
	display: inline-block;
}

.quantity [type="button"]:hover + i {
	color: #3a3d44;
}

.quantity [type="button"].minus {
	top: auto;
	bottom: 16px;
}

.quantity [type="button"].minus + i {
	top: auto;
	bottom: 16px;
}

.quantity [type="number"] {
	padding-right: 50px;
	padding-left: 30px;
	max-width: 115px;
	min-width: 70px;
}

.summary .quantity {
	margin-right: 16px;
	margin-bottom: 10px;
}

.variations td {
	display: block;
	border: 0;
	padding: 3px 0;
}

.variations td.value {
	margin-bottom: 10px;
}

.variations label {
	text-transform: uppercase;
	font-size: 24px;
	font-weight: 900;
}

.product_meta_bottom {
	margin: 0 0 20px;
}

.owl-carousel.products {
	margin-bottom: 0;
}

.owl-carousel.thumbnails-wrap {
	margin: 10px 0 0;
}

.owl-carousel.thumbnails-wrap .owl-dots {
	margin: 10px 0 5px;
}

.owl-carousel.thumbnails-wrap .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	border-width: 1px;
}

.product-icons .teaser [class*="_bg_color"] {
	padding: 10px;
}

.product + .nav-tabs,
.woocommerce-tabs {
	margin-top: 50px;
}

/*shop comments*/
.woocommerce-Reviews .woocommerce-Reviews-title:first-child,
.woocommerce-Reviews .comment-reply-title {
	font-size: 24px;
	font-weight: 600;
	color: #3a3d44;
}

.commentlist {
	list-style: none;
	margin: 0;
	padding: 0;
}

.commentlist li {
	margin-bottom: 40px;
}

.commentlist li + li {
	position: relative;
}

.commentlist li + li:before {
	content: "";
	position: absolute;
	top: -20px;
	left: 0;
	right: 0;
	height: 1px;
	background-color: rgba(120, 120, 120, 0.2);
}

.commentlist li img {
	display: block;
	float: left;
	overflow: hidden;
	border-radius: 50%;
	border: 5px solid #fff;
	min-width: 70px;
}

.commentlist .comment-text {
	overflow: hidden;
	position: relative;
	padding: 5px 0 0 20px;
}

.commentlist p.meta {
	font-size: 0;
	margin: 10px 0 35px;
}

.commentlist p.meta strong {
	color: #3a3d44;
	font-weight: 600;
	font-size: 16px;
	line-height: 1;
	display: block;
}

.commentlist p.meta .woocommerce-review__dash {
	display: none;
}

.commentlist p.meta time {
	display: block;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 20px;
	color: #F15931;
}

.commentlist .star-rating {
	position: absolute;
	top: 70px;
}

.woocommerce-tabs .tab-content .comment-respond {
	margin: 60px 0 0;
}

.woocommerce-tabs .tab-content .comment-respond h3 {
	font-size: 28px;
}

.woocommerce-tabs .tab-content .comment-respond h4 {
	margin-bottom: 0;
	font-size: 28px;
}

/*comment respond form*/
#review_form textarea,
#review_form [type*='text'],
#review_form [type*='email'] {
	width: 100%;
	display: block;
	margin-bottom: 10px;
}

@media (min-width: 992px) {
	#review_form .comment-form-author {
		float: left;
		width: 50%;
		padding-right: 5px;
	}

	#review_form .comment-form-email {
		float: left;
		width: 50%;
		padding-left: 5px;
	}

	#review_form .form-submit {
		clear: both;
	}
}

.comment-form-rating label[for] {
	display: block;
}

/* cart */
.shop_table ul {
	list-style: none;
	padding: 0;
}

.shop-adds {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.4;
}

.shop-adds .fa {
	padding: 0 10px;
}

.shop-adds.teaser {
	margin-bottom: 0;
}

.shop-adds.teaser [class*="_bg_color"] {
	padding: 12px;
}

table.cart {
	margin-top: 0;
	margin-bottom: 30px;
}

table.cart img {
	max-width: 100px;
}

table.cart h4 {
	font-size: 16px;
	margin-bottom: 4px;
}

table.cart td, table.cart th {
	vertical-align: middle;
	padding: 18px 20px;
}

table.cart .coupon {
	display: inline-block;
}

table.cart a {
	color: #3a3d44;
}

table.cart td.product-name {
	font-size: 18px;
}

table.cart .product-remove {
	text-align: center;
}

table.cart .attachment-shop_thumbnail {
	min-width: 100px;
}

table.cart .product-info {
	min-width: 200px;
}

.coupon {
	margin-bottom: 4px;
}

.coupon .button {
	vertical-align: top;
	color: #ffffff;
	background-color: #F15931;
}

.coupon .button:hover {
	color: #3a3d44;
}

.coupon + .button {
	vertical-align: top;
}

form.cart {
	margin-top: 30px;
}

form.cart .theme_button {
	margin: 0;
}

form.cart .quantity + .theme_button {
	vertical-align: top;
}

.cart_totals h4:first-child {
	margin: 60px 0 30px;
}

.cart_totals table {
	margin: 0 0 50px;
}

.cart_totals table th,
.cart_totals table td {
	padding: 20px 20px;
}

.cart_totals table th {
	width: 140px;
}

/* chekcout */
.shop-info {
	margin-bottom: 15px;
}

#createaccount {
	margin-top: 0;
}

#ship-to-different-address label {
	display: inline-block;
}

#ship-to-different-address label + input {
	margin: 0 12px;
}

.checkout [class*='col-'], .shop-register [class*='col-'] {
	margin-top: 0;
	margin-bottom: 0;
}

.woocommerce form input[type="radio"] {
	margin-right: 6px;
}

.woocommerce form input[type="checkbox"] {
	margin-right: 6px;
	position: relative;
	top: 2px;
}

.woocommerce form p:last-child {
	margin-bottom: 10px;
}

.form-row {
	margin: 0 0 15px;
}

.form-row:before, .form-row:after {
	content: '';
	display: block;
	clear: both;
}

.form-row .checkbox {
	margin: -5px 0 0;
}

.form-row .lost_password {
	margin: 15px 0 -15px;
}

.form-row label {
	display: block;
	margin-top: 10px;
}

.form-row [type="email"],
.form-row [type="number"],
.form-row [type="password"],
.form-row [type="text"],
.form-row select,
.form-row textarea {
	display: block;
	width: 100%;
}

@media (min-width: 768px) {
	.form-row label {
		padding-right: 25px;
		width: 30%;
		float: left;
		text-align: right;
	}

	.form-row label + *,
  .form-row.address-field input {
		float: right;
		width: 70% !important;
	}
}

.woocommerce form.login label,
.woocommerce form.login input {
	float: none;
	text-align: left;
	width: auto !important;
}

.woocommerce form.login [type="submit"] {
	margin-top: 10px;
	text-align: center;
}

@media (min-width: 992px) {
	.col2-set {
		width: 70%;
		float: left;
		padding-right: 50px;
	}

	.col2-set.addresses {
		width: 100%;
		float: none;
		padding: 0;
		margin: 0 -15px;
	}

	.col2-set.addresses:before, .col2-set.addresses:after {
		display: block;
		content: '';
		clear: both;
	}

	.col2-set.addresses .col-1,
  .col2-set.addresses .col-2 {
		float: left;
		width: 50%;
		padding: 0 15px;
	}

	#order_review_heading,
  #order_review {
		float: left;
	}
}

/* shop single*/
.cart-button {
	margin: 50px 0 55px;
}

.share-title {
	font-size: 20px;
	margin: 56px 0 18px;
}

/* shop */
.woocommerce-product-gallery__image {
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	z-index: 1;
}

.shop-item .item-content {
	padding: 30px 40px;
}

.shop-item .item-price {
	margin: 9px 0 16px;
	font-size: 30px;
	line-height: 1;
	font-weight: 800;
}

.shop-item .item-price span {
	font-size: 40px;
}

.shop-item .cart-button {
	margin: 50px 0 28px;
}

.related-item .item-content {
	padding: 14px 25px;
}

.related-item h4 {
	font-size: 20px;
	line-height: 24px;
}

.related-item .cart-button {
	margin: 14px 0 14px;
}

.select2-container .select2-choice {
	border: none;
	padding: 10px 20px;
	border-radius: 0;
}

.select2-drop-active {
	border-color: #e4e4e4;
}

.woocommerce-checkout-payment {
	margin-top: 40px;
}

.woocommerce-checkout-payment .radio {
	margin: 2px 0;
}

.woocommerce-checkout-payment ul {
	list-style: none;
	padding: 0;
}

.woocommerce-checkout-payment li {
	padding: 11px 0 6px;
	border-bottom: 1px solid #e4e4e4;
}

.woocommerce-checkout-payment li:first-child {
	border-top: 1px solid #e4e4e4;
}

.woocommerce-checkout-payment p {
	margin: 0;
}

.woocommerce-checkout-payment img {
	display: none;
}

.woocommerce-checkout-payment [type="submit"] {
	margin-top: 20px;
}

/*messages*/
.wc-forward {
	float: right;
}

.row > .alert {
	margin-left: 25px;
	margin-right: 25px;
}

/*my account*/
.woocommerce-MyAccount-navigation {
	margin-bottom: 40px;
}

.woocommerce-MyAccount-navigation ul {
	padding: 0;
}

.woocommerce-MyAccount-navigation li {
	display: inline-block;
	margin-right: 20px;
}

.woocommerce-MyAccount-navigation .is-active a {
	color: #F15931 !important;
}

.woocommerce-MyAccount-content h2 {
	margin: 40px 0;
}

.woocommerce-Message {
	background: #ffffff;
	padding: 30px;
}

.woocommerce-Message .woocommerce-Button {
	float: right;
}

.order-actions {
	text-align: center;
}

.customer_details {
	margin-bottom: 60px;
}

.addresses address {
	margin-bottom: 0;
}

/* shop widgets */
/* cart widget */
.widget_shopping_cart h4 {
	margin-top: 0;
	margin-right: 35px;
	font-size: 16px;
}

.widget_shopping_cart .star-rating {
	display: block;
	margin: 5px 0 10px;
}

.widget_shopping_cart .cart_list {
	padding-bottom: 4px;
}

.widget_shopping_cart .media-body {
	position: relative;
}

.widget_shopping_cart .product-quantity {
	display: block;
	line-height: 1;
}

.widget_shopping_cart .product-quantity .price {
	color: #03a8cb;
}

.widget_shopping_cart .remove {
	font-size: 0;
	line-height: 1;
	color: #03a8cb;
}

.widget_shopping_cart .remove:before {
	font-family: FontAwesome;
	font-size: 16px;
	line-height: 1em;
	content: "\f00d";
}

.widget_shopping_cart .remove:hover {
	color: #F15931;
}

.widget_shopping_cart.darklinks .remove {
	color: #F15931;
}

.widget_shopping_cart.darklinks .remove:hover {
	color: #3a3d44;
}

.widget_shopping_cart li a + a {
	padding-top: 50px;
	font-weight: 600;
}

.widget_shopping_cart .wc-forward {
	float: none;
	margin-right: 10px;
}

.widget_shopping_cart .total {
	margin: 10px 0 20px;
}

.widget_shopping_cart .total .price {
	color: #03a8cb;
}

.widget_shopping_cart .quantity {
	display: block;
	font-weight: 700;
}

.widget_shopping_cart .quantity .woocommerce-Price-amount {
	display: inline;
}

.widget_shopping_cart .buttons .theme_button {
	vertical-align: top;
}

.widget .woocommerce-Price-amount {
	line-height: 1em;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.woocommerce-mini-cart__total strong {
	color: #3a3d44;
}

.woocommerce-mini-cart__total .amount {
	color: #F15931;
}

/* price filter */
.widget_price_filter input[readonly] {
	padding-right: 3px;
	padding-left: 3px;
	max-width: 80px;
	margin-bottom: 4px;
	display: inline-block;
}

.widget_price_filter .ui-slider-range {
	background: #fcb315;
}

.widget_price_filter .ui-slider-horizontal {
	margin-bottom: 10px;
}

.widget_price_filter label[for] {
	display: inline-block;
	padding-right: 4px;
}

.widget_price_filter .form-group {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}

.widget_price_filter .price_slider_amount {
	position: relative;
	padding-bottom: 70px;
}

.widget_price_filter input[type="text"] + .theme_button {
	padding: 18px 25px;
	width: auto;
	height: auto;
	line-height: 1em;
	border-radius: 10px;
	top: 50px;
	left: 0;
	right: 0;
	margin: 0;
}

/*! jQuery UI - v1.11.1 - 2014-10-03
* http://jqueryui.com
* Includes: core.css, slider.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ui-slider {
	position: relative;
	text-align: left;
	background-color: #e4e4e4;
	border-radius: 2px;
}

.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 14px;
	height: 14px;
	cursor: pointer;
	-ms-touch-action: none;
	touch-action: none;
	background-color: #fcb315;
	border-radius: 50%;
}

.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	-webkit-filter: inherit;
	filter: inherit;
}

.ui-slider-horizontal {
	height: 5px;
}

.ui-slider-horizontal .ui-slider-handle {
	top: -5px;
	margin-left: -6px;
}

.ui-slider-horizontal .ui-slider-range {
	top: 0px;
	bottom: 0px;
}

.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: .8em;
	height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
	left: -.3em;
	margin-left: 0;
	margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}

/* color layered nav */
.widget_layered_nav .color-filters li {
	display: inline-block;
	margin: 0 29px 14px 0;
}

.widget_layered_nav .color-filters a {
	display: inline-block;
	width: 23px;
	height: 23px;
	font-size: 0;
	background-color: #ccc;
	color: transparent;
	position: relative;
}

.widget_layered_nav .color-filters a:before {
	content: '';
	display: block;
	position: absolute;
	border: 1px solid rgba(120, 120, 120, 0.2);
	top: -2px;
	left: -2px;
	bottom: -2px;
	right: -2px;
}

.widget_layered_nav .color-filters span {
	position: relative;
	top: 2px;
	left: 5px;
}

/* widget_products */
.widget_products img {
	max-width: 80px;
}

.widget_products .media h3 {
	font-size: 16px;
	line-height: 1.4;
	font-weight: normal;
	margin: 3px 0 10px;
}

.widget_products .star-rating {
	margin-bottom: 10px;
}

.widget_products .price {
	position: static;
	background-color: transparent;
	padding: 0;
	line-height: 1;
}

/*widget_top_rated_products*/
.widget .product-title {
	display: block;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
}

.widget .star-rating {
	display: block;
	margin: 7px 0;
}

.widget_top_rated_products .price > .amount,
.widget_top_rated_products .price ins {
	color: #F15931;
}

.widget_recent_reviews a {
	font-weight: 600;
}

.product_list_widget li {
	margin-bottom: 20px;
}

.product_list_widget li:before, .product_list_widget li:after {
	content: '';
	display: block;
	clear: both;
}

.product_list_widget img {
	max-width: 70px;
}

.product_list_widget h4 {
	font-size: 16px;
	margin-bottom: 5px;
}

.product_list_widget .product-quantity,
.product_list_widget .price {
	font-size: 14px;
}

.product_list_widget .remove {
	position: absolute;
	top: 0;
	right: 0;
	font-weight: 400;
}

/*widget_rating_filter*/
.widget_rating_filter li {
	text-align: right;
	clear: both;
	padding: 12px 0;
}

.widget_rating_filter .star-rating {
	margin: 6px 0;
	float: left;
}

.widget_layered_nav_filters li {
	padding: 12px 0;
}

@media (max-width: 991px) {
	table.cart {
		display: block;
		overflow-x: auto;
	}
}

@media (min-width: 992px) {
	#order_review_heading,
  #order_review {
		float: left;
	}

	#order_review_heading > table,
  #order_review > table {
		margin-top: 0;
	}
}

.cart-button i {
	position: relative;
}

.cart-button i:after {
	content: "";
	width: 10px;
	height: 10px;
	position: absolute;
	top: -3px;
	right: -2px;
	border: 2px solid #fff;
	background-color: #F15931;
	border-radius: 50%;
}

.cart-button i.empty:after {
	display: none;
}

.cart-button .total-price {
	color: #F15931;
	padding-left: 5px;
}

.uws-products.carousel-col-3 {
	margin: 0;
}

.products.owl-carousel {
	margin: 0;
}

.products.owl-carousel li.product {
	width: 100%;
}
